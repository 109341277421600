<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">
        {{ isEditMode ? "Edit printer" : "Label/Receipt printers" }}
      </h3>
      <div
        v-if="!isAddLabel && labelPrintersList.length"
        @click="addNewLabel"
        class="icon-cont"
      >
        <span class="mdi mdi-plus neutral--text" />
      </div>
    </div>
    <div class="P-modal-component-body P-label-block">
      <manage-label-printers ref="manageLabelPrinters" v-if="isAddLabel || !labelPrintersList.length" />
      <label-printers-list v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ManageLabelPrinters from "./manage-label-printers";
import LabelPrintersList from "./label-printers-list";
export default {
  name: "LabelPrinters",
  components: { LabelPrintersList, ManageLabelPrinters },
  computed: {
    ...mapState({
      isEditMode: state => state.labelPrinters.isEditMode,
      isAddLabel: state => state.labelPrinters.isAddLabel,
      labelPrintersList: state => state.labelPrinters.labelPrintersList,
      addProviderModal: state => state.addProviderModal
    })
  },

  async mounted() {
    if(this.addProviderModal.providerId){
      await this.getLabelPrinterList(this.addProviderModal.providerId)
      await this.getCompanyAddresses(this.addProviderModal.providerId);
    }

    this.showSaveBtnFunc();
  },

  updated() {
    this.showSaveBtnFunc();
  },
  
  methods: {
    ...mapActions({
      getLabelPrinterList: 'labelPrinters/getLabelPrinterList',
      getCompanyAddresses: "companyAddressesAdmin/getCompanyAddresses",
    }),

    ...mapMutations({
      addNewList: 'labelPrinters/addNewList'
    }),

    addNewLabel() {
      this.addNewList();
    },

    showSaveBtnFunc() {
      if (this.labelPrintersList.length && !this.isAddLabel ) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })    
      }else if(!this.labelPrintersList.length) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true })    
      }else {
        this.$emit('showSaveBtnFunc', { showSaveButton: false })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-label-block {
  margin-top: 15px;
}
</style>
