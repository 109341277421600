<template>
  <div class="P-main-content">
    <div class="P-balance">
      <balance-manage ref="balanceManage" v-if="!balanceManage.balance===0 || !balanceManage.isSave || balanceManage.isEdit"/>
      <balance v-else/>
    </div>
    <div class="P-credit">
      <credit @stopCredit="showWarningModal = true" ref="creditForm" v-if="!credit.isStart || credit.isEdit"/>
      <credit-data v-else/>
    </div>

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="stopCredit()"      
      btnText="Stop Credit"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import BalanceManage from "@/components/addProviderModal/balanceCredit/components/balanceManage/BalanceManage";
  import Credit from "@/components/addProviderModal/balanceCredit/components/credit/Credit";
  import Balance from "@/components/addProviderModal/balanceCredit/components/balanceManage/Balance";
  import CreditData from "@/components/addProviderModal/balanceCredit/components/credit/CreditData";
  import WarningModal from "@/components/WarningModal";

  export default {
    name: "BalanceCredit",
    components: { CreditData, Balance, Credit, BalanceManage, WarningModal },
    computed: {
      ...mapState({
        balanceManage: state => state.balanceCredit.balanceManage,
        credit: state => state.balanceCredit.credit,
        addProviderModal: state => state.addProviderModal,
      }),

      warningModalHeading() {
        return `Stop provider's credit?`
      },

      warningModalText() {
        return `You're about to <ins>stop</ins> provider's credit. If you're not sure, You can cancel this operation.`
      },
    },

    data() {
      return {
        showWarningModal: false,
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
      }
    },

    async created() {
      if (this.addProviderModal.providerId) {
        await this.getBalanceCreditData(this.addProviderModal.providerId)
      }

      this.showSaveBtnFunc()
    },

    updated() {    
      this.showSaveBtnFunc()
    },

    methods: {
      ...mapActions({
        getBalanceCreditData: 'balanceCredit/getBalanceCreditData'
      }),

      async stopCredit() {
        this.warningModalIsLoading = true;
        await this.$refs.creditForm.stopCredit().finally(() => {
          this.warningModalIsLoading = false;
        });
        this.showWarningModal = false;
      },

      showSaveBtnFunc() {
        if (this.balanceManage.isSave && !this.balanceManage.isEdit) {
          this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })    
        }else if(!this.balanceManage.isSave && !this.balanceManage.isEdit) {
          this.$emit('showSaveBtnFunc', { showSaveButton: true })    
        }else if (this.balanceManage.isEdit && this.balanceManage.isSave ) {
          this.$emit('showSaveBtnFunc', { showSaveButton: false })
        }else {
          this.$emit('showSaveBtnFunc', { showSaveButton: false })
        }
      }
    },
  };
</script>

<style scoped lang="scss">
  .P-balance {
    margin-bottom: 40px;
    margin-right: 24px;
  }

  .P-credit {
    margin-right: 24px;
  }
  .P-main-content {
    max-height: 715px;
    overflow-y: auto;
  }
</style>
