<template>
  <div class="P-manage-addresses">
    <v-radio-group v-model="companyAddresses.addressType">
      <div class="P-full-line G-flex">
        <div class="P-radio-box ">
          <ottRadio
            v-model="companyAddresses.addressTypeList.company"
            label="Company"
          />
        </div>
        <div class="P-radio-box">
          <ottRadio
            v-model="companyAddresses.addressTypeList.personal"
            label="Personal"
          />
        </div>
      </div>
    </v-radio-group>
    <div
      v-if="
        companyAddresses.addressType ===
          companyAddresses.addressTypeList.company
      "
      class="P-full-line G-flex-wrap"
    >
      <div class="P-padding-50">
        <ottInput
          label="Company name*"
          v-model="companyName"
          :error="$v.formData.companyName.$error"
          @emitFunc="updateCountryData"
        />
      </div>
    </div>
    <div v-else class="P-full-line G-flex-wrap">
      <div class="P-padding-50">
        <ottInput
          v-model="firstname"
          :error="$v.formData.firstname.$error"
          label="First Name*"
        />
      </div>
      <div class="P-padding-50">
        <ottInput
          v-model="lastname"
          :error="$v.formData.lastname.$error"
          label="Last Name*"
        />
      </div>
    </div>
    <div class="P-full-line G-flex-wrap">
      <div class="P-padding-50">
        <ottPhoneNumber
          v-model="formData.phoneNumber"
          :error="validationPhoneNumber || isValidateNumber|| $v.formData.phoneNumber.$error"
          @onInput="validatePhoneNumber"
          placeholder=""
          label="Phone Number*"
        />
      </div>
      <div class="P-padding-50">
        <ottInput
          v-model="formData.officeName"
          :error="$v.formData.officeName.$error"
          label="Office name*"
        />
      </div>
    </div>
    <div class="P-full-line">
      <div class="P-padding-100">
        <ottUsAutocomplate
          :addressData="formData.address"
          :error-address="$v.formData.address.$error"
          ref="usAddress"
          @updateAddress="updateAddress"
          @saveAddress="saveAddress"
          isRequiredField
        />
      </div>
    </div>
    <div class="P-full-line G-flex-wrap">
      <div class="P-padding-33">
        <!--        <vue-google-autocomplete-->
        <!--          id="city"-->
        <!--          class="P-autocomplete"-->
        <!--          classname="form-control"-->
        <!--          label="Country"-->
        <!--          v-on:placechanged="getAddressData"-->
        <!--        >-->
        <!--        </vue-google-autocomplete>-->
        <ott-select-autocomplete
          v-model="country"
          outlined
          :items="countryList"
          :height="38"
          :error="$v.formData.country.$error"
          :clear="true"
          selected-text="name"
          label="Country*"
        />
      </div>
      <div class="P-padding-33">
        <ottInput
          v-model="formData.city"
          :error="$v.formData.city.$error"
          label="City*"
        />
      </div>
      <div class="P-padding-33">
        <ottInput label="State/province" v-model="formData.state" />
      </div>
      <div class="P-padding-33">
        <ottInput label="Suite/unit" v-model="formData.unit" />
      </div>
      <div class="P-padding-33">
        <ottInput
          v-model="formData.zip"
          :error="$v.formData.zip.$error"
          label="Zip code*"
        />
      </div>
    </div>
    <div class="P-full-line G-flex">
      <div class="P-checkbox">
<!--        :disabled="formData.inMain || !addressesList.length" v-model="formData.inUse"-->
        <ott-checkbox label="Disable" v-model="formData.inUse" />
      </div>
      <div class="P-checkbox">
<!--        :disabled="formData.inUse ||isMainCheck" v-model="formData.isMain"-->
<!--        :disabled="addressesList.length===1"-->
        <ott-checkbox label="Is main"  v-model="formData.isMain"  />
      </div>

      <div v-if="isEditMode || (!isEditMode && !isExistShippingAddress)" class="P-checkbox">
        <ott-checkbox
          v-model="formData.isForShipping"
          label="Is for shipping"
        />
      </div>
      <div v-if="isEditMode || (!isEditMode && !isExistBillingAddress)" class="P-checkbox">
        <ott-checkbox label="Is billing" v-model="formData.isBilling" />
      </div>

      <div class="P-checkbox">
        <ott-checkbox label="For invoice" v-model="formData.forInvoice" />
      </div>
    </div>
    <div class="P-full-line">
      <div class="P-checkbox">
        <ottSwitch label="Is Warehouse" v-model="formData.isWarehouse" />
      </div>
    </div>
    <div
      v-if="formData.isWarehouse"
      class="P-full-line P-warehouse G-flex-column G-align-start"
    >
      <div class="P-checkbox">
        <ottCheckbox
          v-model="formData.acceptSelfPickup"
          label="Accept self-pickup"
        />
      </div>
      <div class="P-checkbox">
        <ottCheckbox
          v-model="formData.acceptCurrierPickup"
          label="Accept Courier Pickup"
        />
      </div>
    </div>
 
    <save-reset-buttons
      v-if="addressesList.length"
      :right-label="companyAddresses.isEditMode ? 'Save' : 'Add'"
      :is-loading="isLoading"
      :right-click-func="() => addAddress()"
      :left-click-func="() => closeAddAddress()"
    />

    <div class="G-flex mt-2">
      <ott-button-loader
        v-if="isEditProvider && !addressesList.length"
        :is-loading="isLoading"        
        @onClick="addAddress()"
        button-text="Add"
        class-name="primary-sm-btn"
      />
    </div>

    <ErrorMessageContainer :message="companyAddresses.errorMessage" v-if="companyAddresses.error" />

  </div>
</template>
<script>
import { mapMutations } from "vuex";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import OttUsAutocomplate from "@/components/vuetifyComponents/OttUsAutocomplate";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttButton from "@/components/vuetifyComponents/OttButton";
import companyAddressesMixin from "./companyAddressesMixin";
import VueGoogleAutocomplete from "@/components/vuetifyComponents/OttGoogleAutocomplete";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "ManageAddress",
  mixins: [companyAddressesMixin],
  components: {
    OttSelectAutocomplete,
    OttButtonLoader,
    ErrorMessageContainer,
    VueGoogleAutocomplete,
    OttButton,
    OttSwitch,
    OttCheckbox,
    OttUsAutocomplate,
    OttPhoneNumber,
    OttInput,
    OttRadio,
    SaveResetButtons
  },

  methods: {
    ...mapMutations({
      closeAddAddress: 'companyAddresses/closeAddAddress'
    })
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";

.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-radio-box {
  padding: 10px;

  &:first-child {
    margin-left: -5px;
  }
}

.P-checkbox {
  padding: 10px;
  display: inline-block;
}

.P-full-line {
  margin: 0 -10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-padding-33 {
  width: 33.333%;
  padding: 10px;
}

.P-manage-addresses::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}

.P-warehouse {
  .P-checkbox {
    padding: 5px 10px;
  }
}

.P-cancel {
  font-size: $txt16;
  font-weight: $semiBold;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $neutral-color25;
  }
}

.theme--dark {
  .P-cancel {
    &:hover {
      background-color: $darkBg-color12MD;
    }
  }
}

.P-buttons {
  margin-top: 20px;
}
</style>
