<template>
  <div class="contact-parent">
    <contact-form
      ref="contactForm"
      v-if="
        (contactModule.isSave &&
          !contactModule.isEditEmail &&
          !contactModule.isEditPhone) && (!contactModule.emails.length && !contactModule.phones.length)
      "
    />
    <div
      v-else-if="!contactModule.isEditEmail && !contactModule.isEditPhone && (contactModule.emails.length || contactModule.phones.length)"
      class="all-tables"
    >
      <title-contact
        :is-add-icon="true"
        :click="addEmail"
        text="Email Address"
      />
      <table-content
        v-if="contactModule.emails.length"
        :tableData="contactModule.emails"
        :header="headerEmail"
        name="email"
      />

      <div class="P-table-phone">
        <title-contact
          :is-add-icon="true"
          :click="addPhone"
          text="Phone number"
        />
        <table-content
          v-if="contactModule.phones.length"
          :tableData="contactModule.phones"
          :header="headerPhone"
          name="phone"
        />
      </div>
    </div>
    <div v-if="contactModule.isEditEmail">
      <title-contact
        :text="
          contactModule.isAddEmail ? 'Add Email Address' : 'Edit Email Address'
        "
      />
      <email-content ref="emailForm" />
    </div>
    <div v-if="contactModule.isEditPhone">
      <title-contact
        :text="
          contactModule.isAddPhone ? 'Add Phone Number' : 'Edit Phone Number'
        "
      />
      <phone-content ref="phoneNumberForm" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import TableContent from "@/components/addProviderModal/contact/TableContent";
import EmailContent from "@/components/addProviderModal/contact/email/EmailContent";
import ContactForm from "@/components/addProviderModal/contact/contactForm/ContactForm";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import PhoneContent from "@/components/addProviderModal/contact/phone/PhoneContent";
export default {
  name: "ContactSection",
  components: {
    PhoneContent,
    TitleContact,
    ContactForm,
    EmailContent,
    TableContent
  },

  data() {
    return {
      headerEmail: [
        "Email Addresses",
        "Disable",
        "Main",
        "Info",
        "Support",
        "For invoice"
      ],
      headerPhone: [
        "Phone Numbers",
        "Disable",
        "Main",
        "Mobile",
        "Support",
        "For invoice"
      ],
    };
  },

  computed: {
    ...mapState({
      contactModule: state => state.contactModule,
      validateEmail: state => state.contactModule.validateEmailm,
    }),   
  },

  mounted() {
    if ((this.contactModule.emails.length || this.contactModule.phones.length) && !this.contactModule.isEditEmail && !this.contactModule.isEditPhone) {
      this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })    
    }else if(!this.contactModule.emails.length && !this.contactModule.phones.length)  {
      this.$emit('showSaveBtnFunc', { showSaveButton: true })    
    }else {
      this.$emit('showSaveBtnFunc', { showSaveButton: false })
    }
  },
  
  updated() {    
    if ((this.contactModule.emails.length || this.contactModule.phones.length) && !this.contactModule.isEditEmail && !this.contactModule.isEditPhone) {
      this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })
    } else {
      this.$emit('showSaveBtnFunc', { showSaveButton: false })
    }
  },

  methods: {
    ...mapMutations({
      setError: 'contactModule/setError',
      addEmailMutation: 'contactModule/addEmail',
      addPhoneMutation: 'contactModule/addPhone',
    }),

    addEmail() {
      this.setError({
        value: false,
        message: ""
      });
      this.addEmailMutation();
    },
    addPhone() {
      this.addPhoneMutation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-table-phone {
  margin-top: 25px;
}
</style>
