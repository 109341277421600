<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3
        v-if="!paymentList.length || (addMode && !editMode)"
        class="secondary--text"
      >
        Payment methods
      </h3>
      <h3
        v-if="paymentList.length && !addMode && !editMode"
        class="secondary--text"
      >
        Saved payments
      </h3>
      <h3
        v-if="editMode && selectedPaymentType=== paymentType.creditCard"
        class="secondary--text"
      >
        Edit credit card
      </h3>
      <h3
        v-if="editMode && selectedPaymentType=== paymentType.bankAccount"
        class="secondary--text"
      >
        Edit bank account
      </h3>
    </div>

    <div class="P-modal-component-body">
      <ManagePayments ref="managePayments" v-if="!paymentList.length || addMode || editMode" />
     
      <div v-else>
        <!-- Credit Card Fee -->
          <div v-if="showCardCustomFee">
            <ott-switch
              v-model="cardsCollectFee"
              @emitFunction="updateCustomFees"
              label="Cards Collect Fee From provider"
            />  

            <transition name="slide">
              <CustomFee 
                v-if="cardsCollectFee"
                :switchVal="isCardsCustom"
                :fixedVal="cardsFixed"
                :percentVal="cardsPercent"
                @switchChange="isCardsCustom = $event"
                @fixedInput="cardsFixedInput"
                @percentInput="cardsPercentInput"
                switchLabel="Custom Fee" 
                fixedLabel="Fixed" 
                percentLabel="Percent" 
              />
            </transition>
          </div>

        <hr class="my-4" v-if="showCardCustomFee && showBankCustomFee" />
        <!-- Bank Account Fee -->
          <div v-if="showBankCustomFee" class="mb-4">
            <ott-switch
              v-model="bankColelctFee"
              @emitFunction="updateCustomFees"
              label="Bank Collect Fee From provider"
            />     

            <transition name="slide">
              <CustomFee
                v-if="bankColelctFee"
                :switchVal="isBankCustom"
                :fixedVal="bankFixed"
                :percentVal="bankPercent"
                @switchChange="isBankCustom = $event"
                @fixedInput="bankFixedInput"
                @percentInput="bankPercentInput"    
                switchLabel="Custrom Fee" 
                fixedLabel="Fixed" 
                percentLabel="Percent" 
              />
            </transition>
          </div>
        <div class="paymentList-wrapper">
          <PaymentList />
          <div
            v-if="paymentList.length && !addMode  && !editMode"
            @click="addNewPayment"
            class="icon-cont"
          >
            <span class="mdi mdi-plus neutral--text" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import ManagePayments from "./ManagePayments";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import CustomFee from "@/components/CustomFee";
import PaymentList from "./PaymentList";

export default {
  name: "PaymentMethods",
  components: { PaymentList, OttSwitch, CustomFee, ManagePayments },
  computed: {
    ...mapState({
      addProviderModal: state => state.addProviderModal,
      cardsFee: state => state.paymentMethods.cardsFee,
      bankFee: state => state.paymentMethods.bankFee,      
      paymentList: state => state.paymentMethods.paymentList,
      addMode: state => state.paymentMethods.addMode,
      editMode: state => state.paymentMethods.editMode,
      paymentType: state => state.paymentMethods.paymentType,
      selectedPaymentType: state => state.paymentMethods.selectedPaymentType,
    }),

    cardsCollectFee: {
      get() {
        return this.cardsFee.cardsCollectFeeProvider
      },
      set(val) {
        this.setDataSecondLevels({
          cardsFee: { cardsCollectFeeProvider : val !== null ? true : false}
        })
      }
    },

    bankColelctFee: {
      get() {
        return this.bankFee.bankCollectFeeProvider
      },
      set(val) {
        this.setDataSecondLevels({
          bankFee: { bankCollectFeeProvider : val !== null ? true : false }
        })
      }
    },
    
    isCardsCustom: {
      get() {
        return this.cardsFee.enabled;
      },
      set(val) {
        this.setDataSecondLevels({
          cardsFee: { enabled: val !== null ? true : false }
        })
      }
    },

    cardsFixed: {
      get() {
        return this.cardsFee.fixed;
      },
      set(fixed) {
        this.setDataSecondLevels({
          cardsFee: { fixed }
        })
      }
    },

    cardsPercent: {
      get() {
        return this.cardsFee.percent;
      },
      set(percent) {
        this.setDataSecondLevels({
          cardsFee: { percent }
        })
      }
    },

    isBankCustom: {
      get() {
        return this.bankFee.enabled
      },
      set(val) {
        this.setDataSecondLevels({
          bankFee: { enabled: val !== null ? true : false }
        })
      }
    },

    bankFixed: {
      get() {
        return this.bankFee.fixed
      },
      set(fixed) {
        this.setDataSecondLevels({
          bankFee: { fixed }
        })
      }
    },

    bankPercent: {
      get() {
        return this.bankFee.percent
      },
      set(percent) {
        this.setDataSecondLevels({
          bankFee: { percent }
        })
      }
    },

    showCardCustomFee() {
      let show = false;
      this.paymentList.map(item => {
        if (item.cardNumber) {
          show = true
        }         
      })
      return show
    },

    showBankCustomFee() {
      let show = false;
      this.paymentList.map(item => {
        if (!item.cardNumber) {
          show = true
        }         
      })
      return show
    },
  },
  async created() {
    if(this.addProviderModal.providerId){
      await this.getPaymentDataList(this.addProviderModal.providerId)
    }

    await this.getCustomFees()
  },
  mounted() {
    this.showSaveBtnFunc()
  },

  updated() {    
    this.showSaveBtnFunc()
  },

  destroyed() {
    this.resetState()
  },

  methods: {
    ...mapMutations({
      addNewPaymentMethod: 'paymentMethods/addNewPaymentMethod',
      setDataSecondLevels: 'paymentMethods/setDataSecondLevels',
      resetState: 'paymentMethods/resetState'
    }),

    ...mapActions({
      getPaymentDataList: 'paymentMethods/getPaymentDataList',
      getCustomFees: 'paymentMethods/getCustomFees',
      updateCustomFees: 'paymentMethods/updateCustomFees'
    }),

    async cardsFixedInput(e) {
      this.cardsFixed = e;
      await this.updateCustomFees();
    },
    
    async cardsPercentInput(e) {
      this.cardsPercent = e;
      await this.updateCustomFees();
    },

    async bankFixedInput(e) {
      this.bankFixed = e;
      await this.updateCustomFees();
    },
    
    async bankPercentInput(e) {
      this.bankPercent = e;
      await this.updateCustomFees();
    },

    addNewPayment() {
      this.addNewPaymentMethod();
    },

    showSaveBtnFunc() {
      if (this.paymentList.length && !this.addMode && !this.editMode) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })    
      }else if(!this.paymentList.length) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true })    
      }else {
        this.$emit('showSaveBtnFunc', { showSaveButton: false })
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/variables";

.paymentList-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
  }

  .v-messages, .v-text-field__details {
    display: none;
  }
 
}
</style>
