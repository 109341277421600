<template>
  <div class="P-personal-info">
    <h4 class="title-cont secondary--text">Admin Personal Info</h4>
    <div style="width: 50%">
      <ott-input
        v-model="companyAddressesForm.firstName"
        :error="$v.companyAddressesForm.firstName.$error"
        @emitKeypress="onlyLetters($event)"
        label="First name*"
      />
      <ott-input
        v-model="companyAddressesForm.lastName"
        :error="$v.companyAddressesForm.lastName.$error"
        @emitKeypress="onlyLetters($event)"
        label="Last name*"
      />
      <ott-input
        v-model="companyAddressesForm.email"
        :error="isValidateEmail || $v.companyAddressesForm.email.$error"
        @emitFunc="validateEmail"
        label="Email Address*"
      />
      <ott-phone-number
        v-model="companyAddressesForm.phone"
        :error="(validPhoneNumber|| isValidateNumber || isValidatePhone || $v.companyAddressesForm.phone.$error) && showPhoneError"
        @onInput="validationPhoneNumber"
        @onChange="showPhoneError = true"
      />
      <password-input
        v-model="companyAddressesForm.password"
        :error="$v.companyAddressesForm.password.$error"
        label="Password*"
      />
      <div class="P-input-box P-confirm-password">
        <PasswordStrength
          :inputValue='companyAddressesForm.confirmPassword'
          :error="$v.companyAddressesForm.confirmPassword.$error"
          @emitFunc='changePassword'
          label="Confirm Password*"
        />
      </div>
      <p class="neutral--text P-password-text">
        The password must contain at least 8 characters, 1 letter, 1 number and 1 symbol
      </p>
      <div v-if="isValidateEmail || isValidatePhone" class="mt-2">
        <ErrorMessageContainer
          v-if="isValidateEmail"
          :message="errorMessage"
        />
        <ErrorMessageContainer
          v-if="isValidatePhone"
          message='The user with the current Phone is already created.'
        />
      </div>
    </div>
    <div class="G-flex">
      <ott-button-loader
        v-if="isEditProvider"
        @onClick="updateProviderFunc"
        button-text="Save"
        class-name="primary-sm-btn"
        :is-loading="isLoading"
      />
    </div>
  </div>
</template>

<script>
  import { mapMutations, mapActions, mapState } from "vuex";
  import {
    helpers,
    minLength,
    required,
    sameAs,
    email, requiredIf
  } from "vuelidate/lib/validators";

  import allCountry from "@/constants/all-countries";
  // components
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
  import PasswordInput from "@/components/PasswordInput";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import PasswordStrength from "@/components/PasswordStrength";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader"; 

  const letterNumSymbol = helpers.regex(
    "letterNumSymbol",
   /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
  );
  export default {
    name: "AdminPersonalInfo",
    components: {
      PasswordStrength,
      ErrorMessageContainer,
      PasswordInput,
      OttPhoneNumber,
      OttInput,
      OttButtonLoader
    },

    props: {
      updateProvider: {
        type: Function,
        default: () => null
      }
    },

    validations: {
      companyAddressesForm: {
        firstName: {required},
        lastName: {required},
        email: {required, email},
        phone: {required},
        password: {
          required: requiredIf(function () {
            return !this.addProviderModal.providerId;
          }),
          minLength: minLength(8),
          letterNumSymbol
        },
        confirmPassword: {
          required: requiredIf(function () {
            return !this.addProviderModal.providerId;
          }), sameAsPassword: sameAs("password")
        }
      }
    },

    data() {
      return {
        showPhoneError : false,
        companyAddressesForm: {},
        validPhoneNumber: false,
        isValidateEmail: false,
        isValidatePhone: false,
        isValidateNumber: false,
        errorMessage: '',
        email: null,
        passwordErrorMessage: 'The password must contain at least 8 characters, 1 letter and 1 number'
      };
    },

    computed: {
      ...mapState({
        companyAddresses: state => state.adminPersonalInfoModule,
        userData: state => state.auth.user,
        contactModule: state => state.contactModule,
        flagForEmail: state => state.adminPersonalInfoModule.flagForEmail,
        addProviderModal: state => state.addProviderModal,
        flagForPhone: state => state.adminPersonalInfoModule.flagForPhone,
        isEditProvider: state => state.providers.isEditProvider,
        isLoading: state => state.appModule.isLoading
      })
    },

    mounted() {
      this.companyAddressesForm = this.companyAddresses.companyAddressesForm;
      if (!this.companyAddressesForm.phone) {
        allCountry.forEach(item => {
          if (item.iso2 === this.userData?.geoInfo?.countryCode?.toUpperCase()) {
            this.companyAddressesForm.phone = "+" + item.dialCode;
          }
        });
      }
      if (this.contactModule.phones.length && !this.flagForPhone && !this.addProviderModal.providerId) {
        this.companyAddressesForm.phone = this.contactModule.phones[0].phone;
      }
      if (this.contactModule.emails.length && !this.flagForEmail && !this.addProviderModal.providerId) {
        this.companyAddressesForm.email = this.contactModule.emails[0].emailValue;
      }
      this.email = JSON.parse(JSON.stringify(this.companyAddresses.companyAddressesForm.email))
    },

    methods: {
      ...mapActions({
        validatePhone: 'addProviderModal/validatePhone',
        validateEmailUser: 'registration/validateEmailUser'
      }),

      ...mapMutations({
        validateEmailMutation: 'adminPersonalInfoModule/validateEmail',
        setFlagEmail: 'adminPersonalInfoModule/setFlagEmail',
        setCountryCode: 'adminPersonalInfoModule/setCountryCode',
        setFlagPhone: 'adminPersonalInfoModule/setFlagPhone',
        setConfirmPassword: 'adminPersonalInfoModule/setConfirmPassword',
      }),

      async validationForm() {
        this.$v.companyAddressesForm.$touch();
        const body = {
          email: this.companyAddressesForm.email
        };
        const bodyPhone = {}
        bodyPhone.phone = this.companyAddressesForm.phone;
        if (this.addProviderModal.providerId) {
          bodyPhone.providerId = this.addProviderModal.providerId;
        }
        if (!this.$v.companyAddressesForm.$error && !this.validPhoneNumber && !this.isValidateNumber) {
          return await this.validatePhone(bodyPhone).then(async data => {            
            if (!data) { 
              if (!this.addProviderModal.providerId || this.email !== this.companyAddressesForm.email) {               
                return await this.validateEmailUser(body)
                  .then(data => {
                    if (!data.result) {
                      this.isValidateEmail = false;
                      this.validateEmailMutation(
                        !this.$v.companyAddressesForm.$error && !this.validPhoneNumber && !this.isValidateNumber
                      );
                      return Promise.resolve(true)
                    } else {
                      this.isValidateEmail = true;
                      this.errorMessage = 'The user with the current E-mail is already created.'
                      this.validateEmailMutation(false);
                      return Promise.resolve(false)
                    }
                  }, error => {
                    this.isValidateEmail = true;
                    this.errorMessage = 'Incorrect Email Address'
                    this.validateEmailMutation(false);
                    return Promise.resolve(false)
                  });                  
              } else {
                this.validateEmailMutation(
                  !this.$v.companyAddressesForm.$error && !this.validPhoneNumber
                );
                this.isValidatePhone = false;
                return Promise.resolve(false)
              }              
            } else {
              this.isValidatePhone = true;
              this.validateEmailMutation(false);
              return Promise.resolve(false)

            }
          }, error => {
            this.isValidatePhone = true;
            this.validateEmailMutation(false);
            return Promise.resolve(false)
          })
        } else {
          return Promise.resolve(false)
        }

        // return !this.$v.companyAddressesForm.$error && !this.validPhoneNumber;
      },
      
      async updateProviderFunc() {
        this.showPhoneError = true;
        this.$v.companyAddressesForm.firstName.$touch()
        this.$v.companyAddressesForm.lastName.$touch()
        this.$v.companyAddressesForm.phone.$touch()
        this.validateEmail();
      
        !this.$v.companyAddressesForm.$error && 
        !this.$v.companyAddressesForm.lastName.$error &&
        !this.$v.companyAddressesForm.phone.$error && 
        !this.$v.companyAddressesForm.email.$error 
          ? await this.updateProvider()
          : ''
      },

      validateEmail() {
        this.$v.companyAddressesForm.email.$touch();
        this.setFlagEmail();
        this.validateEmailMutation(false);
        this.isValidateEmail = false
      },
      validationPhoneNumber() {
        const [phone, obj] = arguments;
        this.validPhoneNumber = !obj.isValid;
        this.$v.companyAddressesForm["phone"].$touch();
        this.setCountryCode(obj.regionCode);

        this.isValidatePhone = false
        let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
        this.isValidateNumber = !Number(str)
        this.setFlagPhone();
      },
      changePassword() {
        const [value] = arguments
        this.setConfirmPassword(value.trim())
      },
      
      /**
       * Only letters and spaces - regex
       * **/
      onlyLetters(event) {
        let char = String.fromCharCode(event.keyCode);
        if (/^[0-9*#+-/=~․ժ։;`!@$%^&()_?><:"}{|\\]+$/.test(char)) {
          return event.preventDefault();
        } else {
          return true;
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "./src/assets/scss/variables";

  .title-cont {
    color         : $secondary-color;
    margin-bottom : 20px;
    font-size     : $txt16;
    font-weight   : $semiBold;
  }

  .P-personal-info::v-deep {
    .v-text-field__details {
      margin-bottom : 0;
    }

    .ott-input-cont,
    .P-phone-number  {
      padding-bottom: 20px;
    }
  }

  ::v-deep {
    .Password.password-range {
      max-width: 430px;
    }
  }

  .P-password-text {
    font-size: $txt12;
  }
</style>
