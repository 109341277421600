<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <g id="Group_3210" data-name="Group 3210" transform="translate(-17 -7)">
      <g
        id="Group_3209"
        data-name="Group 3209"
        transform="translate(-667 -487)"
      >
        <rect
          id="Rectangle_3983"
          data-name="Rectangle 3983"
          width="27"
          height="27"
          rx="13.5"
          transform="translate(684 494)"
          fill="#4e5a64"
        />
        <g
          id="Group_3209-2"
          data-name="Group 3209"
          transform="translate(687.921 502.627)"
        >
          <rect
            id="Rectangle_3985"
            data-name="Rectangle 3985"
            width="14"
            height="7"
            rx="2"
            transform="translate(4.078 0.373)"
            fill="#bed6e4"
          />
          <rect
            id="Rectangle_3984"
            data-name="Rectangle 3984"
            width="15"
            height="7"
            rx="2"
            transform="translate(0.078 2.373)"
            fill="#86adcb"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "CheckeeperSVG"
};
</script>

<style scoped></style>
