<template>
  <div class="ui-and-access">
    <title-contact text="Use custom domain" :is-add-icon="false" />
    <div class="w-50 P-inputs">
      <div class="P-padding-100">
        <ott-input label="Domain" v-model="domain" />
      </div>
      <div class="P-padding-100 P-copy-input">
        <ott-input
          v-model="dns"
          :focus="dnsFocus"
          :blur="dnsBlur"
          readonly
          label="DNS C- name"
          ref="dnsRef"
        />
        <v-tooltip
          top
          left
          class="ott-tooltip P-copy-icon"
          color="tooltipBgColor"
          :attach="true"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="P-copy-icon" v-bind="attrs" v-on="on" v-if="dns">
              <span
                @click="dnsAppendClick"
                :class="{
                  ' mdi-check-circle': isCopied,
                  'mdi-content-copy': !isCopied,
                  'white--text': $vuetify.theme.dark,
                  'neutral--text': !$vuetify.theme.dark
                }"
                class="mdi P-icon"
              />
            </div>
          </template>
          <span>Copy to buffer</span>
        </v-tooltip>
      </div>
    </div>
    <div class="G-flex mt-4">
      <ott-button-loader
        v-if="isEditProvider"
        :is-loading="isLoading"
        @onClick="updateData"
        button-text="Save"
        class-name="primary-sm-btn"        
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "UiAndAccess",
  components: { OttInput, TitleContact, OttButtonLoader },
  
  data() {
    return {
      isFocus: false,
      isCopied: false
    };
  },

  computed: {
    ...mapState({ 
      uiAndAccess: state => state.uiAndAccess,
      addProviderModal: state => state.addProviderModal,
      isEditProvider: state => state.providers.isEditProvider,
      isLoading: state => state.appModule.isLoading
    }),      

    domain: {
      get() {
        return this.uiAndAccess.domain;
      },
      set(value) {
        this.setDomain(value);
      }
    },

    dns: {
      get() {
        return this.uiAndAccess.dns;
      },
      set(value) {
        this.setDncName(value);
      }
    }
  },

  async mounted() {
    if (this.addProviderModal.providerId) {
      await this.getUiAndAccess(this.addProviderModal.providerId)
    }
  },

  methods: {
    ...mapActions({
      getUiAndAccess: 'uiAndAccess/getUiAndAccess',
      updateUiAndAccess: 'uiAndAccess/updateUiAndAccess'
    }),

    ...mapMutations({
      setDomain: 'uiAndAccess/setDomain',
      setDncName: 'uiAndAccess/setDncName'
    }),

    async updateData(){
      await this.updateUiAndAccess(this.addProviderModal.providerId)
    },
    dnsFocus() {
      this.isFocus = !this.isFocus;
    },
    dnsBlur() {
      this.isFocus = !this.isFocus;
      this.isCopied = false;
    },
    dnsAppendClick() {
      this.isCopied = !this.isCopied;

      const refElem = this.$refs.dnsRef.$el.querySelector("input");
      refElem.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style lang="scss" scoped>
.ui-and-access {
  display: flex;
  flex-direction: column;
  & > :nth-child(n + 2) {
    width: 50%;
  }
}
</style>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
.w-50 {
  width: 50%;
}
.P-inputs {
  margin: 0 -10px
}
.ui-and-access::v-deep {
  & .v-input__icon--append .v-icon {
    font-size: $txt12;
  }
}

.P-copy-input {
  position: relative;
}
.P-copy-input {
  .v-tooltip__content {
    top: -37px !important;
    left: -116px !important;
    span {
      white-space: nowrap;
    }
  }
}
.P-copy-icon {
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
  .P-icon {
    font-size: $txt22;
    transition: $transition;
  }
  &:hover {
    .P-icon {
      color: $primary-color !important;
    }
  }
  &:hover {
    .P-icon.mdi-check-circle {
      color: $green !important;
    }
  }
}
.P-copy-input::v-deep {
  .mdi-check-circle {
    color: $green !important;
  }
}
</style>
<!--left: 352.5px;-->
<!--opacity: 0;-->
<!--top: -37px;-->
<!--z-index: 204;-->
<!--display: none;-->
