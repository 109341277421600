<template>
  <div class="credit">
    <title-contact text="Credit" :is-add-icon="false" />
    <div class="credit-fields">
      <div>
        <ott-switch
          style="font-size: 14px"
          className="secondaryColor"
          label="Start Now"
          v-model="isStartNow"
          :disable="!!balanceCredit.creditData"
          class="P-start-now"
        />
        <ott-input
          :error="$v.credit.creditAmount.$error"
          label="Credit Amount*"
          type="number"
          v-model="creditAmount"
          :is-percent="true"
          :customInputClass="'P-smpt-port'"
          :disabled="!!balanceCredit.creditData"
        />
      </div>
      <div v-if="!isStartNow" class="P-date-picker G-flex G-align-end">
        <DatePicker
          :masks="{ input: ottDateFormat }"
          v-model="setDate"
          :popover="{ visibility: !!balanceCredit.creditData ? '' : 'click' }"
          :min-date="newDateWithMainUserTZ"
          :disabled="!!balanceCredit.creditData"
          :is-dark="$vuetify.theme.dark"
          locale="en"
          class="ott-date-picker"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="ott-date-picker-cont">
              <v-text-field
                v-model="inputValue"
                v-on="inputEvents"
                :disabled="!!balanceCredit.creditData"
                :readonly="true"
                outlined
                dense
                height="38px"
                label="Credit Start Date*"
                :error="$v.setDate.$error"
                class=""
                append-icon="mdi mdi-calendar-blank-outline neutral--text"
              />
            </div>
          </template>
        </DatePicker>
      </div>
      <div class="P-credit-term G-flex-column">
        <v-radio-group
          v-model="setRadio"
          :error="$v.credit.radioValue.$error"
          :disabled="!!balanceCredit.creditData"
          class="P-radio-group"
          row
        >
          <ott-radio
            v-model="credit.radio[0]"
            :disabled="!!balanceCredit.creditData"
            :label="credit.radio[0]"
          />
          <ott-radio
            v-model="credit.radio[1]"
            :disabled="!!balanceCredit.creditData"
            :label="credit.radio[1]"
            style="margin-left: 10px"
          />
        </v-radio-group>
        <ott-select
          v-model="selectedCreditTerm"
          :disabled="credit.radioValue === null || !!balanceCredit.creditData"
          :error="$v.credit.selectedCreditTerm.$error"
          :items="credit.radioValue === 'Day' ? daysList : monthsList"
          selected-text="value"
          label="Credit Term*"
        />
      </div>
      <div class="P-credits-pause">
        <ott-select
          v-model="selectedCreditsPauseAfterDays"
          :items="clientsPauseAfterDays"
          :disabled="!!balanceCredit.creditData"
          selected-text="value"
          label="Credits Pause After Days"
          style="align-self: flex-end"
        />
      </div>
      <ott-textarea
        v-model="comment"
        :rows="4"
        :disabled="!!balanceCredit.creditData"
        label="Comment"
      />
    </div>
    <div class="autoextend">
      <ott-switch
        v-model="creditAutoextend"
        :disable="!!balanceCredit.creditData"
        style="font-size: 14px"
        class-name="secondaryColor"
        label="Credit Autoextend"
      />
    </div>
    <div class="credit-buttons">
      <ott-button-loader
        v-if="!balanceCredit.isEdit && !balanceCredit.creditData"
        :is-loading="isLoading"
        button-text="Start Credit"
        class-name="primary-sm-btn"
        @onClick="startCredit"
      />
      <div v-else class="G-flex">
        <ott-button
          :click="cancelManage"
          text="Cancel"
          custom-class="neutral-sm-btn P-cancel-btn"
        />
        <ott-button
          :click="openStopCreditModal"
          text="Stop Credit"
          custom-class="red-transparent-sm-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import DateFormatMixin from "@/mixins/DateFormatMixin"
import DaysMonthMixin from "@/mixins/DaysMonthMixin";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import DatePicker from "v-calendar/src/components/DatePicker";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "Credit",
  components: {
    OttTextarea,
    OttButton,
    OttSelect,
    OttInput,
    OttRadio,
    OttSwitch,
    TitleContact,
    DatePicker,
    OttButtonLoader,
  },
  mixins: [DateFormatMixin, DaysMonthMixin, ConvertTimeZoneMixin],
  data() {
    return {
      menu2: false,
      date: null,
    };
  },

  computed: {
    ...mapState({
      credit: (state) => state.balanceCredit.credit,
      addProviderModal: (state) => state.addProviderModal,
      balanceCredit: (state) => state.balanceCredit,
      isLoading: (state) => state.appModule.isLoading
    }),

    clientsPauseAfterDays: {
      get() {
        return this.credit.clientsPauseAfterDays;
      },
    },
    setRadio: {
      get() {
        return this.credit.radioValue;
      },
      set(value) {
        this.selectedCreditTermMutation(null);

        this.setRadioMutation(value);
      },
    },
    comment: {
      get() {
        return this.credit.comment;
      },
      set(value) {
        this.setCreditComment(value);
      },
    },
    selectedCreditsPauseAfterDays: {
      get() {
        return this.credit.selectedCreditsPauseAfterDays;
      },
      set(value) {
        this.selectedCreditsPauseAfterDaysMutation(value);
      },
    },
    setDate: {
      get() {
        return this.credit.date;
      },
      set(value) {
        this.setDateMutation(value);
      },
    },
    creditAmount: {
      get() {
        return this.credit.creditAmount;
      },
      set(value) {
        this.setCreditAmount(value);
      },
    },
    creditAutoextend: {
      get() {
        return this.credit.creditAutoextend;
      },
      set(value) {
        this.setCreditAutoextend(value);
      },
    },
    selectedCreditTerm: {
      get() {
        return this.credit.selectedCreditTerm;
      },
      set(value) {
        this.selectedCreditTermMutation(value);
      },
    },
    isStartNow: {
      get() {
        return this.credit.isStartNow;
      },
      set(value) {
        this.setIsStartNow(value);
      },
    },
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 2,
          rows: 2,
          isExpanded: false,
        },
      });
    },
  },
  validations: {
    credit: {
      creditAmount: { required },
      selectedCreditTerm: { required },
      radioValue: { required },
    },
    setDate: { required },
  },
  methods: {
    ...mapActions({
      stopBalanceCredit: "balanceCredit/stopCredit",
      getBalanceCreditData: "balanceCredit/getBalanceCreditData",
      addCredit: "balanceCredit/addCredit",
    }),

    ...mapMutations({
      selectedCreditTermMutation: 'balanceCredit/selectedCreditTerm',
      setRadioMutation: 'balanceCredit/setRadio',
      setCreditComment: 'balanceCredit/setCreditComment',
      selectedCreditsPauseAfterDaysMutation: 'balanceCredit/selectedCreditsPauseAfterDays',
      setDateMutation: 'balanceCredit/setDate',
      setCreditAmount: 'balanceCredit/setCreditAmount',
      setCreditAutoextend: 'balanceCredit/setCreditAutoextend',
      setIsStartNow: 'balanceCredit/setIsStartNow',
      setStartCredit: 'balanceCredit/setStartCredit',
      cancelEditCredit: 'balanceCredit/cancelEditCredit',
      resetDataCredit: 'balanceCredit/resetDataCredit',
    }),

    openCalendar() {
      this.menu2 = !this.menu2;
    },

    async stopCredit() {
      const body = {
        providerId: this.addProviderModal.providerId,
        data: this.credit,
      };
      this.setStartCredit(body);
      await this.stopBalanceCredit(this.addProviderModal.providerId).then(
        async () => {
          await this.getBalanceCreditData(this.addProviderModal.providerId);
        }
      );
    },

    openStopCreditModal() {
      this.$emit("stopCredit");
    },

    cancelManage() {
      if (this.balanceCredit.creditData) {
        this.cancelEditCredit();
      } else {
        this.resetDataCredit();
      }
    },
    async startCredit() {
      this.$v.credit.$touch();
      if (!this.credit.isStartNow) {
        this.$v.setDate.$touch();
        if (!this.$v.setDate.$error && !this.$v.credit.$error) {
          const body = {
            providerId: this.addProviderModal.providerId,
            data: this.credit,
          };
          this.setStartCredit(body);
          await this.addCredit().then(async () => {
            await this.getBalanceCreditData(this.addProviderModal.providerId);
          });
        }
      } else {
        if (!this.$v.credit.$error) {
          const body = {
            providerId: this.addProviderModal.providerId,
            data: this.credit,
          };
          this.setStartCredit(body);
          await this.addCredit().then(async () => {
            await this.getBalanceCreditData(this.addProviderModal.providerId);
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";
@import "./src/assets/scss/datePicker";

.credit {
  display: flex;
  flex-direction: column;

  & .credit-fields {
    & > :nth-child(1) {
      margin-top: 2px;
    }

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & > :nth-child(-n + 4) {
      width: 31.6%;
    }

    & > :not(:first-child, :last-child) {
      display: flex;
    }
  }

  & > .credit-buttons {
    display: flex;
    margin-top: 35px;

    & > :nth-child(2) {
      margin-left: 15px;
    }
  }
}

.P-date-picker {
  position: relative;
  margin-bottom: 22px;

  .ott-date-picker {
    width: 100%;
  }
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  .v-text-field__details {
    display: none;
  }
  .v-input--dense > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
  .v-messages {
    display: none;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
}

.P-credits-pause {
  margin: 0 0 22px;
  .Ott-select-cont {
    width: 100%;
  }
}

.autoextend {
  margin-top: 16px;
}

.P-start-now,
.autoextend {
  margin-left: 2px;
}

.P-credit-term {
  margin-bottom: 22px;
}

.P-start-now,
.P-radio-group {
  margin-bottom: 16px;
}

.P-cancel-btn {
  margin-right: 10px;
}
</style>
