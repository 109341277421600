<template>
  <div class="balance-manage">
    <title-component :title-text="`<p class='mb-1'>Balance Manage</p> <p>Total: ${totalBalance}</p>`" />
    <div class="G-flex G-flex-wrap P-inputs">
      <div class="P-padding-33">
        <ott-select
          v-model="paymentMethodValue"
          :items="paymentMethod"
          :error="$v.balanceManage.paymentMethodValue.$error"
          selected-text="name"
          label="Payment Method*"
        />
      </div>
      <div class="P-padding-33" v-if="paymentMethodValue === 1 || paymentMethodValue === 0">
        <ott-input
          v-model="routingNumber"
          :error="$v.balanceManage.routingNumber.$error"
          :label="`${
            paymentMethodValue === 0 ? 'Money order' : 'Check'
          } Number*`"
          :is-percent="true"
          :customInputClass="'P-order-money'"
        />
      </div>
      <div class="P-padding-33">
        <ott-input
          v-model="balance"
          :error="isValidateBalance || $v.balanceManage.balance.$error"
          :is-percent="true"
          @emitFunc="validateBalance"
          label="Balance*"
          type="number"
        />
      </div>
      <div class="P-padding-100">
        <ott-textarea label="Comment" :rows="4" v-model="comment" />
      </div>
    </div>
    <div class="buttons G-flex">
      <save-reset-buttons
        v-if="balanceManage.isEdit"
        :is-loading="isLoading"
        :left-click-func="() => reset()"
        :right-click-func="() => save()"
        right-label="Add"
      />
      <div class="G-flex">
        <ott-button-loader
          v-if="isEditProvider && !mainBalance"
          :is-loading="isLoading"
          @onClick="save()"
          button-text="Add"
          class-name="primary-sm-btn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import TitleComponent from "@/components/TitleComponent";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "BalanceManage",
  components: {
    OttTextarea,
    OttInput,
    OttSelect,
    TitleComponent,
    SaveResetButtons,
    OttButtonLoader
  },
  validations: {
    balanceManage: {
      paymentMethodValue: { required },
      balance: { required },
      routingNumber: {
        required: requiredIf(function () {
          return this.paymentMethodValue === 0 || this.paymentMethodValue === 1;
        }),
      },
    },
  },
  data() {
    return {
      isValidateBalance: false,
    };
  },

  computed: {
    ...mapState({
      balanceManage: (state) => state.balanceCredit.balanceManage,
      addProviderModal: (state) => state.addProviderModal,
      isEditProvider: (state) => state.providers.isEditProvider,
      mainBalance: (state) => state.balanceCredit.mainBalance,
      isLoading: (state) => state.appModule.isLoading
    }),

    totalBalance() {
      return typeof this.mainBalance === 'number'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(this.mainBalance)
        : '$0.00'
    },
  
    paymentMethod: {
      get() {
        return this.balanceManage.paymentMethod;
      },
    },
    paymentMethodValue: {
      get() {
        return this.balanceManage.paymentMethodValue;
      },
      set(value) {
        this.setPaymentMethodValue(value);
      },
    },
    routingNumber: {
      get() {
        return this.balanceManage.routingNumber;
      },
      set(value) {
        this.setRoutingNumber(value);
      },
    },
    balance: {
      get() {
        return this.balanceManage.balance;
      },
      set(value) {
        this.setBalance(value);
      },
    },
    comment: {
      get() {
        return this.balanceManage.comment;
      },
      set(value) {
        this.setComment(value);
      },
    },
  },

  methods: {
    ...mapActions({
      addBalance: 'balanceCredit/addBalance',
      getBalanceCreditData: 'balanceCredit/getBalanceCreditData'
    }),

    ...mapMutations({
      setPaymentMethodValue: 'balanceCredit/setPaymentMethodValue',
      setRoutingNumber: 'balanceCredit/setRoutingNumber',
      setBalance: 'balanceCredit/setBalance',
      setComment: 'balanceCredit/setComment',
      saveBalanceManage: 'balanceCredit/saveBalanceManage',
      resetBalanceManage: 'balanceCredit/resetBalanceManage',
    }),

    validateBalance() {
      this.isValidateBalance = +this.balanceManage.balance.split("")[0] === 0;
    },
    async save() {
      this.$v.balanceManage.$touch();
      if (!this.$v.balanceManage.$error && !this.isValidateBalance) {
        const body = {
          providerId: this.addProviderModal.providerId,
          data: this.balanceManage,
        };
        this.saveBalanceManage(body);
        await this.addBalance().then(async (data) => {
          await this.getBalanceCreditData(this.addProviderModal.providerId);
        });
      }
    },
    reset() {
      this.resetBalanceManage();
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-manage {
  .P-inputs {
    margin: 0 -10px
  }
}
</style>
