<template>
  <div>
    <title-contact
      :is-add-icon="true"
      :click="edit"
      text="Credit"
      icon-class="mdi mdi-pencil-outline neutral--text"
    />

    <div class="content-second">
      <title-contact :text="`Total Credit: ${
        typeof credit.creditAmount === 'number'
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(credit.creditAmount)
          : '$0.00'
      }`" />
      <div class="content">
        <div class="P-content-box">
          <span class="P-title neutral--text">Credit Start Date </span>
          <span class="secondary--text">{{ credit.date ? credit.date : '' }}</span>
        </div>
        <div class="P-content-box">
          <span class="P-title neutral--text">Credit Payment Remain Days</span>
          <span class="secondary--text">
            {{ credit.selectedCreditTerm }}
            {{ credit.radioValue==='Day' ? " Day" : " Month" }}
          </span>
        </div>
        <div class="P-content-box" v-if="credit.selectedCreditsPauseAfterDays">
          <span class="P-title neutral--text">Clients Pause After Days</span>
          <span class="secondary--text">
            {{ credit.selectedCreditsPauseAfterDays }} Day
          </span>
        </div>
      </div>
      <hr style="margin:15px 0;opacity: 0.45" />
      <p class="secondary--text P-comment">
        {{ credit.comment }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  name: "CreditData",
  components: { TitleContact },
  mixins: [DateFormatMixin],
  computed: {
    ...mapState({ credit: state => state.balanceCredit.credit }),
  },
  methods: {
    ...mapMutations({
      setCreditEdit: "balanceCredit/setCreditEdit"
    }),
    edit() {
      this.setCreditEdit();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.content-second {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000012;
  border-radius: 4px;
  padding: 20px;
  margin-top: -10px;
}

.content {
  span {
    font-size: $txt12;
    &:first-child {
      min-width: 200px;
      max-width: 200px;
      display: inline-block;
    }
    &:last-child {
      font-size: $txt14;
      padding-left: 10px;
    }
  }
}

.P-comment {
  font-size: $txt12;
  margin-bottom: 0;
}
.theme--dark{
    .content-second{
      background-color: $dark_light;
    }
  }

</style>
