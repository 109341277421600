<template>
  <div class="content-phone-section">
    <div class="pb-2">
      <ott-phone-number
        v-model="phone"
        :error="
          (validatePhone ||
            isValidateNumber ||
            validPhoneNumber ||
            $v.phone.$error) &&
          showPhoneError
        "
        @onInput="validate"
        @onChange="showPhoneError = true"
      />
    </div>
    <div class="content-email-checkboxes">
      <ott-checkbox
        v-model="inUse"
        :disabled="inMain || !contactModule.phones.length"
        label="Disable"
      />
      <ott-checkbox
        v-model="inMain"
        :disabled="inUse || isCheckPhone"
        label="Is main"
      />
      <ott-checkbox label="Is Mobile" v-model="isMobile" />
      <ott-checkbox label="Is support" v-model="isSupport" />

      <ott-checkbox label="For Invoice" v-model="forInvoice" />
    </div>
    <ErrorMessageContainer
      v-if="contactModule.isErrorPhone || contactModule.isCheckErrorPhone"
      :message="contactModule.errorMessagePhone"
      :styles="{ width: 'max-content' }"
    />
    <save-reset-buttons
      v-if="contactModule.isAddPhone || contactModule.isEditPhone"
      :right-label="!contactModule.isAddEmail ? 'Save' : 'Add'"
      :is-loading="isLoading"
      :left-click-func="() => cancel()"
      :right-click-func="() => save()"
    />
    
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import { ContactInfoModel } from "@/models/providers/contactInfoModel";
import { AddProviderModel } from "@/models/providers/addProviderModel";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import allCountry from "@/constants/all-countries";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "PhoneContent",
  components: {
    SaveResetButtons,
    ErrorMessageContainer,
    OttPhoneNumber,
    OttButton,
    OttCheckbox,
  },
  
  validations: {
    phone: { required },
  },

  data() {
    return {
      showPhoneError: false,
      validPhoneNumber: false,
      validatePhone: false,
      isCheckPhone: false,
      isValidateNumber: false,
    };
  },

  computed: {
    ...mapState({
      contactModule: (state) => state.contactModule,
      companyInfo: (state) => state.companyInfo,
      adminPersonalInfoModule: (state) => state.adminPersonalInfoModule,
      userData: (state) => state.auth.user,
      addProviderModal: (state) => state.addProviderModal,
      isLoading: (state) => state.appModule.isLoading
    }),

    editablePhoneDataIndex() {
      return this.contactModule.editablePhoneDataIndex
    },
    phone: {
      get() {
        return this.contactModule.phoneForm.phone;
      },
      set(value) {
        this.updatePhoneFormPhone(value);
      },
    },
    inUse: {
      get() {
        return this.contactModule.phoneForm.inUse;
      },
      set(value) {
        this.updatePhoneFormInUse(value);
      },
    },
    inMain: {
      get() {
        return this.contactModule.phoneForm.inMain;
      },
      set(value) {
        this.updatePhoneFormInMain(value);
      },
    },
    isMobile: {
      get() {
        return this.contactModule.phoneForm.isMobile;
      },
      set(value) {
        this.updatePhoneFormIsInfo(value);
      },
    },
    isSupport: {
      get() {
        return this.contactModule.phoneForm.isSupport;
      },
      set(value) {
        this.updatePhoneFormIsSupport(value);
      },
    },

    forInvoice: {
      get() {
        return this.contactModule.phoneForm.forInvoice;
      },
      set(value) {
        this.updatePhoneFormForInvoice(value);
      },
    },
  },

  mounted() {
    if (!this.phone) {
      allCountry.forEach((item) => {
        if (item.iso2 === this.userData?.geoInfo?.countryCode?.toUpperCase()) {
          this.updatePhoneFormPhone("+" + item.dialCode);
        }
      });
    }
    this.isCheckPhone = this.getCurrentData();
  },

  methods: {
    ...mapActions({
      managePhone: 'contactModule/managePhone',
      addPhone: 'contactModule/addPhone',
      editProvider: 'addProviderModal/editProvider',
      getProvider: 'addProviderModal/getProvider',
    }),

    ...mapMutations({
      updatePhoneFormPhone: 'contactModule/updatePhoneFormPhone',
      updatePhoneFormInUse: 'contactModule/updatePhoneFormInUse',
      updatePhoneFormInMain: 'contactModule/updatePhoneFormInMain',
      updatePhoneFormIsInfo: 'contactModule/updatePhoneFormIsInfo',
      updatePhoneFormIsSupport: 'contactModule/updatePhoneFormIsSupport',
      updatePhoneFormForInvoice: 'contactModule/updatePhoneFormForInvoice',
      setErrorPhone: 'contactModule/setErrorPhone',
      setCheckValidatePhone: 'contactModule/setCheckValidatePhone',
      checkIsMainPhone: 'contactModule/checkIsMainPhone',
      saveEditPhoneForm: 'contactModule/saveEditPhoneForm',
      addPhoneSave: 'contactModule/addPhoneSave',
      cancelEditPhoneForm: 'contactModule/cancelEditPhoneForm',
      addPhoneCancel: 'contactModule/addPhoneCancel',
      setProviderId: 'addProviderModal/setProviderId',
      updateContactData: 'contactModule/updateContactData',
      updateCompanyInfo: 'companyInfo/updateCompanyInfo',
      updatePersonalInfo: 'adminPersonalInfoModule/updatePersonalInfo',
    }),

    validate() {
      const [phone, obj] = arguments;
      this.validPhoneNumber = !obj.isValid;
      this.$v.phone.$touch();
      this.validatePhone = false;
      let str = obj.number.input.replace(/[^A-Z0-9]/gi, "");
      this.isValidateNumber = !Number(str);
      this.setErrorPhone({
        value: false,
        message: "",
      });
    },
    validationPhoneNumber() {
      this.$v.phone.$touch();
      let isValidate = false;
      this.validationCheckList();
      isValidate = !this.$v.phone.$error;
      this.setCheckValidatePhone(isValidate);
    },
    validationCheckList() {
      if (!this.contactModule.phones.length) {
        this.updatePhoneFormInMain(true);
      }
    },

    async save() {
      this.showPhoneError = true;
      let isDuplicatedPhoneNumber = false;

      if (this.contactModule.isEditPhone) {
        isDuplicatedPhoneNumber = this.contactModule.phones.some((item, index) => item.phone === this.phone && index !== this.editablePhoneDataIndex);
      } else {
        isDuplicatedPhoneNumber = this.contactModule.phones.some((item) => item.phone === this.phone);
      }

      this.validationPhoneNumber()
      this.checkIsMainPhone(this.contactModule.phoneForm);
      if (this.contactModule.validatePhone) {
        if (isDuplicatedPhoneNumber) {
          this.setErrorPhone({
            value: true,
            message: "Duplicate Phone Number",
          });
          this.validatePhone = true;
        } else {
          this.validatePhone = false;

          if (!this.contactModule.isAddPhone) {
            if (this.addProviderModal.providerId) {
              const phone = new ContactInfoModel();
              let x = phone.managePhone(
                this.contactModule.phoneForm,
                this.addProviderModal.providerId,
                this.contactModule.isAddPhone
              );
              const body = {
                id: this.contactModule.phoneForm.id,
                data: x,
              };
              await this.managePhone(body).then(
                () => {
                  this.saveEditPhoneForm();
                });
            } else {
              this.saveEditPhoneForm();
            }
          } else {
            if (this.addProviderModal.providerId) {
              const phone = new ContactInfoModel();

              await this.addPhone(
                  phone.managePhone(
                    this.contactModule.phoneForm,
                    this.addProviderModal.providerId,
                    this.contactModule.isAddPhone
                  )
                )
                .then(
                  async (data) => {
                    this.addPhoneSave(phone.fetchPhoneNumber(data));
                    await this.updateProviderData();
                  });
            } else {
              this.addPhoneSave();
            }
          }
        }
      }

      const response = await this.getProvider()
      if (response) {
        this.updateContactData(response);
      }
    },
    async cancel() {
      if (!this.contactModule.isAddPhone) {
        this.cancelEditPhoneForm();
      } else {
        this.addPhoneCancel();
      }

      const response = await this.getProvider()
      if (response) {
        this.updateContactData(response);
      }
    },

    getCurrentData() {
      let currentIndex = false;
      if (!this.contactModule.isAddPhone && this.contactModule.phones) {
        this.contactModule.phones.forEach((item, index) => {
          if (item.phone === this.phone) {
            currentIndex = item.inMain;
          }
        });
      }
      return currentIndex;
    },
    async updateProviderData() {
      const provider = new AddProviderModel();
      const data = provider.updateData(
        this.companyInfo.companyInfo,
        this.contactModule,
        this.adminPersonalInfoModule.companyAddressesForm,
        this.addProviderModal.providerId
      );
      await this.editProvider(data)
        .then((data) => {
          if (data) {
            this.updateData(data);
          }
        });
    },
    updateData(data) {
      this.setProviderId(data.id);
      this.updateContactData(data);
      this.updateCompanyInfo(data);
      this.updatePersonalInfo(data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.content-phone-section {
  & > :nth-child(1) {
    width: 47%;
  }
}

.title-cont {
  color: $secondary-color;
  margin-bottom: 20px;
}

.content-email-checkboxes {
  display: flex;
  justify-content: space-between;
  width: 63%;
}

.content-phone-section::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }
}
</style>
