<template>
  <div class="content-email-save-reset">
    <OttButtonLoader
      @onClick="leftClickFunc"
      :is-loading="isLoading"
      :button-text="leftLabel"
      className="neutral-sm-btn"
    />

    <ott-button
      :text="rightLabel"
      :click="rightClickFunc"
      custom-class="transparent-sm-btn"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
export default {
  name: "SaveResetButtons",
  components: {OttButtonLoader, OttButton },
  props: {
    leftLabel: {
      type: String,
      default: null
    },
    rightLabel: {
      type: String,
      default: null
    },
    leftClickFunc: {
      type: Function,
      default: () => null
    },
    rightClickFunc: {
      type: Function,
      default: () => null
    },
    isLoading:{
      type:Boolean,
      default:false,
    }
  },
  computed: {
    ...mapState({ contactModule: state => state.contactModule })
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.content-email-save-reset {
  display: flex;
  margin-top: 13px;
  & > :nth-child(2) {
    margin-left: 10px;
  }
}
.P-cancel {
  font-size: $txt16;
  font-weight: $semiBold;
  margin-left: 25px;
  padding: 7px 15px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: $neutral-color25;
  }
}
.theme--dark {
  .P-cancel {
    &:hover {
      background-color: $darkBg-color12MD;
    }
  }
}
</style>
