<template>
  <div class="G-container">
    <div class="G-flex-wrap P-inputs">
      <div class="P-padding-33">
        <ott-select
          v-model="dateFormat"
          :items="invoice.dateFormat"
          :error="$v.settingsData.dateFormat.$error"
          selected-text="name"
          label="Date format*"
        />
      </div>
      <div class="P-padding-33 G-justify-between align-center">
        <div class="G-col_5 pe-2">
          <ott-select-autocomplete
            v-model="timezone"
            :items="timeZoneList"
            :error="$v.settingsData.timezone.$error"
            selected-text="name"
            label="Timezone*"
          />
        </div>

        <div class="G-col_3 ps-2">
          <ott-select
            v-model="currency"
            :items="invoice.currency"
            :error="$v.settingsData.currency.$error"
            selected-text="name"
            label="Currency*"
          />
        </div>  
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="invoiceGenerateDay"
          :items="invoice.invoiceGenerateDay"
          :error="$v.settingsData.invoiceGenerateDay.$error"
          selected-text="name"
          label="Invoice generate day*"
        />
     </div>
      <div class="P-padding-33">
        <ott-select
          v-model="language"
          :items="invoice.language"
          :error="$v.settingsData.language.$error"
          selected-text="name"
          label="Language*"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="paperFormat"
          :items="invoice.paperFormat"
          :error="$v.settingsData.paperFormat.$error"
          selected-text="name"
          label="Paper Format*"
        />
      </div>
      
      <div class="P-padding-33">
        <ott-select
          v-model="postalMethod"
          :items="postalMethods"
          :error="$v.settingsData.postalMethod.$error"
          selected-text="name"
          label="Postal Method*"
        />
      </div>

      <div class="P-padding-33">
        <ott-select
          v-model="phone"
          :items="phoneList"
          :error="$v.settingsData.phone.$error"
          selected-text="phone"
          label="Phone number*"
        />
      </div>
      <div class="P-padding-33">
        <ott-select
          v-model="email"
          :items="emailList"
          :error="$v.settingsData.email.$error"
          selected-text="emailValue"
          label="Email address*"
        />
      </div>

      <div class="P-padding-33 G-justify-between align-center">
         <div class="G-col_4 pe-2">
          <transition name="fade">
            <ott-select
              v-if="postalMethod === 'color' || postalMethod === 'bw'"
              v-model="autosend"
              :items="autosends"
              selected-text="name"
              label="Auto Send"
            />
          </transition>
        </div>  

        <div class="G-col_5 ps-1">
          <transition name="fade">
            <ott-checkbox v-if="postalMethod === 'color' || postalMethod === 'bw'" v-model="returnEnvelope" label="Return Envelope" />
          </transition>
        </div>
      </div>

      <div class="P-padding-33">
        <ott-input
          v-model="website"
          :error="$v.settingsData.website.$error"
          @emitFunc="updateWebsite"
          label="website*"
        />
      </div>
      <div class="P-padding-66">
        <ott-select
          v-model="address"
          :items="invoiceAddressesList"
          :error="$v.settingsData.address.$error"
          selected-text="name"
          label="Address*"
        />
      </div>
      <div class="P-padding-100">
        <quill-editor 
          v-model="comment" 
          :options="{ placeholder: 'Comment' }"
          :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
        />
      </div>
      <div class="P-padding-100 G-flex mt-5">
        <ott-button-loader
          :button-text="invoice.isEditSettings ? 'Save' : 'Add'"
          :is-loading="isLoading"
          @onClick="saveSettings"
          class-name="primary-sm-btn"
        />
      </div>
    </div>
  </div>
</template>
<script>
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader"
import invoiceSettingsMixin from "./invoiceSettingsMixin";
export default {
  name: "InvoiceSettings",
  components: { OttInput, OttSelect, OttSelectAutocomplete, OttCheckbox, OttButtonLoader },
  mixins: [invoiceSettingsMixin]
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/formStyles";
.P-inputs {
  margin: 0 -10px;
}
</style>
