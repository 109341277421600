var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-contact',{attrs:{"is-add-icon":true,"click":_vm.edit,"text":"Balance","icon-class":"mdi mdi-plus neutral--text"}}),_c('div',{staticClass:"balance-content"},[_c('title-contact',{attrs:{"text":`Total Balance: ${
      typeof _vm.balanceCredit.mainBalance === 'number'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(_vm.balanceCredit.mainBalance)
        : '$0.00'
    }`}}),_c('div',{staticClass:"dept"},[_c('span',[_vm._v("Dept: "+_vm._s(typeof _vm.balanceCredit.dept === 'number' ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2, }).format(_vm.balanceCredit.dept) : '$0.00'))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }