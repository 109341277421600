<template>
  <div>
    <title-contact :click="addEmail" text="Email Address" />
    <email-content ref="emailForm" />
    <div class="P-phone-block">
      <title-contact :click="addEmail" text="Phone Number" />
      <phone-content ref="phoneNumberForm" />
    </div>
    <!-- <save-reset-buttons
      left-label="Add"
      right-label="Reset"
      :isLoading="false"
      :left-click-func="()=>save(false)"
      :right-click-func="reset"
    /> -->
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import EmailContent from "@/components/addProviderModal/contact/email/EmailContent";
import PhoneContent from "@/components/addProviderModal/contact/phone/PhoneContent";
import SaveResetButtons from "@/components/addProviderModal/contact/components/SaveResetButtons";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import OttButton from "@/components/vuetifyComponents/OttButton";

export default {
  name: "ContactForm",
  components: {
    OttButton,
    TitleContact,
    SaveResetButtons,
    PhoneContent,
    EmailContent
  },
  computed: {
    ...mapState({
      contactModule: state => state.contactModule,
      validateEmail: state => state.contactModule.validateEmail,
      validatePhone: state => state.contactModule.validatePhone
    })
  },

  methods: {
    ...mapMutations({
      showForm: 'contactModule/showForm',
      saveMutation: 'contactModule/save',
      resetContactForm: 'contactModule/resetContactForm',
    }),

    addEmail() {
      this.showForm("email");
    },
    save(isFirst) {
      if(!isFirst){
        this.$refs["emailForm"].validationEmail();
        this.$refs["phoneNumberForm"].validationPhoneNumber();
      }
        if (
        this.validateEmail &&
       this.validatePhone
      ) {
        this.saveMutation();
      }
    },
    reset() {
      this.resetContactForm();
    },
  },

};
</script>

<style scoped lang="scss">
.P-phone-block {
  margin-top: 20px;
}
</style>
