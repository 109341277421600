<template>
  <div class="contact-info-for-client">
    <title-contact :is-add-icon="false" text="info for clients apps"/>
    <div class="G-flex G-flex-wrap P-inputs">
      <div class="P-padding-50">
        <ott-select
          v-model="phone"
          :items="phoneList"
          :clear="true"
          label="Phone number"
          selected-text="phone"
        />
      </div>
      <div class="P-padding-50">
        <ott-select
          v-model="email"
          :items="emailList"
          :clear="true"
          label="Email Address"
          selected-text="emailValue"
        />
      </div>
      <div class="P-padding-100">
        <ott-select
          v-model="address"
          :items="addresses"
          :clear="true"
          label="Address"
          selected-text="name"
        />
      </div>
      <div class="P-padding-100">
        <quill-editor 
          v-model="comment" 
          :options="{ placeholder: 'Comment' }"
          :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
        />
      </div>
    </div>

    <div class="G-flex">
      <ott-button-loader
        v-if="isEditProvider"
        :is-loading="isLoading"
        @onClick="updateContactData()"
        button-text="Save"
        class-name="primary-sm-btn mt-20"        
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

  export default {
    name: "ContactInfoForClientsApps",
    components: { OttSelect, TitleContact, OttButtonLoader },
    computed: {
      ...mapState({
        contactInfoForClientsApps: state => state.contactInfoForClientsApps,
        addressList: state => state.companyAddresses.addressesList,
        contactModule: state => state.contactModule,
        addProviderModal: state => state.addProviderModal,
        isEditProvider: state => state.providers.isEditProvider,
        isLoading: state => state.appModule.isLoading
      }),
      email: {
        get() {
          return this.contactInfoForClientsApps.formData.email
        },
        set(value) {
          this.setEmail(value)
        }
      },
      phone: {
        get() {
          return this.contactInfoForClientsApps.formData.phone
        },
        set(value) {
          this.setPhone(value)
        }
      },
      address: {
        get() {
          return this.contactInfoForClientsApps.formData.address
        },
        set(value) {
          this.setAddress(value)
        }
      },
      comment: {
        get() {
          return this.contactInfoForClientsApps.formData.comment
        },
        set(value) {
          this.setComment(value)
        }
      }
    },

    data() {
      return {
        emailList: [],
        phoneList: [],
        isAllListPhone: false,
        addresses: []
      };
    },

    async mounted() {
      if (this.addProviderModal.providerId) {
        await this.getInfoForClients(this.addProviderModal.providerId)
      }
      if (this.contactModule.emails.length) {
        this.emailList = this.contactModule.emails.filter(x => !x.inUse);
      }
      if (this.contactModule.phones.length) {
        if (this.contactModule.phones.filter(x => x.isSupport).length) {
          this.phoneList = this.contactModule.phones.filter(x => x.isSupport && !x.inUse);
        }
      }
      if (this.addressList.length) {
        this.addresses = this.addressList.filter(x => !x.inUse);
        this.addresses.map(item => item.name = item.name.replace('<br />', ' '))
      }
    },

    methods: {
      ...mapActions({
        getInfoForClients: 'contactInfoForClientsApps/getInfoForClients',
        updateInfoForClients: 'contactInfoForClientsApps/updateInfoForClients'
      }),
      
      ...mapMutations({
        setEmail: 'contactInfoForClientsApps/setEmail',
        setPhone: 'contactInfoForClientsApps/setPhone',
        setAddress: 'contactInfoForClientsApps/setAddress',
        setComment: 'contactInfoForClientsApps/setComment',
        saveData: 'contactInfoForClientsApps/saveData',
      }),

      async updateContactData() {
        this.saveData()
        await this.updateInfoForClients(this.addProviderModal.providerId)
      }
    }
  };
</script>

<style lang="scss" scoped>
.P-inputs {
  margin: 0 -10px
}
.mt-20 {
  margin-top: 20px;
}
</style>
