<template>
  <div class="P-invoice-design G-flex ">
    <invoice-design-manage ref="invoiceDesignManage" />
    <invoice-design-img />
  </div>
</template>
<script>
import InvoiceDesignManage from "./InvoiceDesignManage";
import InvoiceDesignImg from "./InvoiceDesignImg";
export default {
  name: "InvoiceDesign",
  components: {
    InvoiceDesignManage,
    InvoiceDesignImg,
  }
};
</script>
