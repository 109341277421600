import { mapState, mapActions, mapMutations } from "vuex";
import { minLength, required, requiredIf} from "vuelidate/lib/validators";
import allCountry from "@/constants/all-countries";

export default {

  data() {
    return {
      addressList: [],
      validateExpiryDate: true,
      isMainCheck: false,
      error: false,
      errorMessage: '',
      countryList: allCountry,
      cardBrand: null
    }
  },
  validations: {
    creditCard: {
      cardNumber: {required},
      cardholderName: {required},
      expireDate: {required},
      cvc: {required,  minLength: minLength(3)},
      phone: {required: requiredIf(function () {
        return (
          !this.paymentMethod.isExistingAddress
        );
      })},
      country: {
        required: requiredIf(function () {
          return (
            !this.paymentMethod.isExistingAddress
          );
        })
      },
      address: {
        required: requiredIf(function () {
          return (
            !this.paymentMethod.isExistingAddress
          );
        })
      },
      city: {
        required: requiredIf(function () {
          return (
            !this.paymentMethod.isExistingAddress
          );
        })
      },
      zip: {
        required: requiredIf(function () {
          return (
            !this.paymentMethod.isExistingAddress
          );
        })
      },
      existingAddress: {
        required: requiredIf(function () {
          return (
            this.paymentMethod.isExistingAddress
          );
        })
      },
    },
  },

  watch: {
    'paymentMethod.creditCard.brand'(cardBrand) {
      const cardNumber = document.querySelector('.card-number input');
      cardNumber.className += `identified, ${cardBrand}`;
      this.cardBrand = cardBrand
    },
  },

  computed: {
    ...mapState({
      paymentMethod: state => state.paymentMethods,
      creditCard: state => state.paymentMethods.creditCard,
      addProviderModal: state => state.addProviderModal,
      addressesList: state => state.companyAddresses.addressesList,
      billingAddresses: state => state.companyAddresses.billingAddresses,
      phoneList: state => state.contactModule.phones,
      paymentList: state => state.paymentMethods.paymentList,
      isEditProvider: state => state.providers.isEditProvider,
      isLoading: state => state.appModule.isLoading
    }),
    cardNumber: {
      get() {
        return this.paymentMethod.creditCard.cardNumber
      },
      set(value) {
        this.setCardNumber(value)
      }
    },
    cardholderName: {
      get() {
        return this.paymentMethod.creditCard.cardholderName
      },
      set(value) {
        this.setCardholderName(value)
      }
    },
    expireDate: {
      get() {
        return this.paymentMethod.creditCard.expireDate
      },
      set(value) {
        this.setExpireDate(value)
      }
    },
    cvc: {
      get() {
        return this.paymentMethod.creditCard.cvc
      },
      set(value) {
        this.setCVC(value)
      }
    },
    address: {
      get() {
        return this.paymentMethod.creditCard.address
      },
      set(value) {
        this.setAddress(value)
      }
    },
    country: {
      get() {
        return this.paymentMethod.creditCard.country
      },
      set(value) {
        this.setCountry(value)
      }
    },
    city: {
      get() {
        return this.paymentMethod.creditCard.city
      },
      set(value) {
        this.setCity(value)
      }
    },
    suite: {
      get() {
        return this.paymentMethod.creditCard.suite
      },
      set(value) {
        this.setSuit(value)
      }
    },
    zip: {
      get() {
        return this.paymentMethod.creditCard.zip
      },
      set(value) {
        this.setZip(value)
      }
    },
    state: {
      get() {
        return this.paymentMethod.creditCard.state
      },
      set(value) {
        this.setState(value)
      }
    },
    existingAddress: {
      get() {
        return this.paymentMethod.creditCard.existingAddress
      },
      set(value) {
        this.setExistingAddress(value)
      }
    },
    inUse: {
      get() {
        return this.paymentMethod.creditCard.inUse
      },
      set(value) {
        this.setInUse(value)
      }
    },
    default: {
      get() {
        return this.paymentMethod.creditCard.default
      },
      set(value) {
        this.setIsDefault(value)
      }
    },
    phone: {
      get() {
        return this.paymentMethod.creditCard.phone
      },
      set(value) {
        this.setPhone(value)
      }
    },
  },
  
  mounted() {
    this.isMainCheck = this.getCurrentData()
  },

  methods: {
    ...mapActions({
      updateCreditCard: 'paymentMethods/updateCreditCard',
      getPaymentDataList: 'paymentMethods/getPaymentDataList',
      addPaymentMethod: 'paymentMethods/addPaymentMethod',
      manageCompanyAddress: 'companyAddresses/manageCompanyAddress',
    }),

    ...mapMutations({
      setCardNumber: 'paymentMethods/setCardNumber',
      setCardholderName: 'paymentMethods/setCardholderName',
      setExpireDate: 'paymentMethods/setExpireDate',
      setCVC: 'paymentMethods/setCVC',
      setAddress: 'paymentMethods/setAddress',
      setCountry: 'paymentMethods/setCountry',
      setCity: 'paymentMethods/setCity',
      setSuit: 'paymentMethods/setSuit',
      setZip: 'paymentMethods/setZip',
      setState: 'paymentMethods/setState',
      setExistingAddress: 'paymentMethods/setExistingAddress',
      setInUse: 'paymentMethods/setInUse',
      setIsDefault: 'paymentMethods/setIsDefault',
      setPhone: 'paymentMethods/setPhone',
      toSaveData: 'paymentMethods/toSaveData',
      closeAddPayment: 'paymentMethods/closeAddPayment',
      setYear: 'paymentMethods/setYear',
      setMonth: 'paymentMethods/setMonth',
      closeAddEditPaymentData: 'paymentMethods/closeAddEditPaymentData',
      addNewAddress: 'companyAddresses/addNewAddress',
    }),

    handlePasteCardNumber(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCreditCard = this.isValidCreditCardFormat(pastedText);

      // If the pasted text is not a valid credit card, prevent the default paste behavior
      if (!isValidCreditCard) {
        event.preventDefault();
      }
    },

    handlePasteCardHolder(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCardHolder = this.isValidCardHolder(pastedText);

      // If the pasted text is not a validcard holder, prevent the default paste behavior
      if (!isValidCardHolder) {
        event.preventDefault();
      }
    },

    handlePasteExpiryDate(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidExpiryDate = this.isValidExpiryDate(pastedText);
      // If the pasted text is not a valid expire date, prevent the default paste behavior
      if (!isValidExpiryDate) {
        event.preventDefault();
      }
    },

    handlePasteCvc(event) {
      // Get the pasted text
      const pastedText = (event.clipboardData || window.clipboardData).getData('text');
      const isValidCvc = this.isValidCvc(pastedText);
      // If the pasted text is not a valid card, prevent the default paste behavior
      if (!isValidCvc) {
        event.preventDefault();
      }
    },

    isValidCreditCardFormat(cardNumber) {
      let regex = /^(?:\d{4} ){3}\d{4}$|^\d{16}$/;
      return regex.test(cardNumber);
    },

    isValidCardHolder(cardHolder) {
      let regex = /^[a-zA-Z]+ [a-zA-Z]+$/;
      return regex.test(cardHolder);
    },

    isValidExpiryDate(expiry) {
      let regex = /^(0[1-9]|1[0-2])\s*\/\s*\d{2}$/;
      return regex.test(expiry);
    },

    isValidCvc(cvc) {
      let regex = /^\d{1,4}$/;
      return regex.test(cvc);
    },

    /**
     * Add or edit credit Card
     * **/
    async manageCreditCard() {
      this.$v.creditCard.$touch()
      this.checkIsDefault()

      if (!this.$v.creditCard.$error && this.validateExpiryDate) {
        this.error = false;
        this.errorMessage = '';
        this.toSaveData({ providerId : this.addProviderModal.providerId, cardBrand: this.cardBrand })
        if (this.paymentMethod.editMode) {
          try {
            await this.updateCreditCard().then(async data => {
              if (data) {
                await this.changeSelectedAddress()
  
                await this.getPaymentDataList(this.addProviderModal.providerId).then(data => {
                  this.closeAddPayment()
  
                })
              }
            })
          } catch(e) {
            if (e.response.status >= 300) {
              this.error = true;
              this.errorMessage = e.response.data.message
            }
          }
        
        } else {
          try {
            await this.addPaymentMethod().then(async data => {
              if (data) {
                await this.changeSelectedAddress()
  
                await this.getPaymentDataList(this.addProviderModal.providerId).then(data => {
                  this.closeAddPayment()  
                })
              }
            })
          } catch(e) {
            if (e.response.status >= 300) {
              this.error = true;
              this.errorMessage = e.response.data.message
            }
            console.log(e.response.data.code, 'Credit Card')
          }
        }        
      }
    },

    /**
     * Update Address autocomplete
     * **/

    saveAddress() {
      const [address] = arguments;
      this.setAddress(address)
    },
    updateAddress() {
      const [addressData] = arguments;
      this.setAddress(addressData.fullAddress)
      this.setCity(addressData.city)
      this.setState(addressData.province)
      this.setSuit(addressData.suite)
      this.setZip(addressData.zipCode)
      let countryValue = allCountry.filter(x => x.name === addressData.country)[0].value
      this.setCountry(countryValue)
    },

    /**
     * Validate Expiry Date
     * @Year
     * @month
     * **/

    expiryDateValidation() {
      let month = new Date().getMonth();
      let year = new Date()
        .getFullYear()
        .toString()
        .split("")
        .splice(2, 2)
        .join("");
      if (this.creditCard.expireDate) {
        let x = this.creditCard.expireDate.split("/");
        if (+x[0] > 12) {
          x[0] = "12";
        }
        x[1] && this.setYear(x[1].trim())
        x[0] && this.setMonth(x[0].trim())
        if(x.length===2){
          this.setExpireDate(x[0] + "/" + x[1])
          this.validateExpiryDate =
            (+x[0] >= month + 1 && +x[1] === +year) || (+x[1] > +year && +x[0] > 0);
        }
      }

    },

    /**
     * Check is Default payment method
     * when added in first time
     * **/

    checkIsDefault() {
      if (!this.paymentMethod.paymentList.length) {
        this.setIsDefault(true)
      }
    },

    resetData() {
      this.closeAddEditPaymentData()
      if (this.$refs["usAddress"]) {
        this.$refs["usAddress"].resetAddress();
      }
    },

    /**
     * get current  data for check inMain
     * **/
    getCurrentData() {
      let currentIndex = false
      if (!this.paymentMethod.addMode && this.paymentMethod.paymentList) {
        this.paymentMethod.paymentList.forEach((item, index) => {
          if (item.id === this.paymentMethod.creditCard.id) {
            currentIndex = item.default
          }
        })
      }
      return currentIndex
    },
    async changeSelectedAddress() {
      if (!this.addressesList.filter(x => x.isBilling).length) {
        this.addressesList.forEach(async item => {
          if (item.id === this.existingAddress) {
            item.isBilling = true
            const body = {
              data: item,
              providerId: this.addProviderModal.providerId
            }
            this.addNewAddress(body)
              await this.manageCompanyAddress(item.id).then(data => {
            })   
          }
        })
      }
    },

    /**
     * Only letters and spaces - regex
     * **/
    onlyLetters(event) {
      let char = String.fromCharCode(event.keyCode);
      if (/^[a-zA-Z\s]*$/.test(char)) return true;
      else event.preventDefault();
    }
  },
}
