var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.addProviderModal.modal.isOpen)?_c('ott-dialog',{attrs:{"is-open":_vm.addProviderModal.modal.isOpen,"show-footer":false,"width":1120,"modal-title":_vm.addProviderModal.isAddReseller
      ? 'Add Reseller'
      : _vm.isEditProvider
      ? 'Edit Provider'
      : 'Add Provider',"close-value":"addProvider"},on:{"outside":_vm.closeModal,"resetDataModal":_vm.resetDataModal},scopedSlots:_vm._u([{key:"modalBody",fn:function(){return [_c('div',{staticClass:"parent-add-provider-modal"},[_c('div',{staticClass:"left P-background-neutral7 G-flex-column G-justify-between"},[_c('div',[_c('div',{staticClass:"status"},[_c('span',{staticClass:"P-status-title"},[_vm._v("Parent Provider")]),_c('div',{staticClass:"status-second"},[_c('div',{staticClass:"text"},[_c('span',[_vm._v(_vm._s(_vm.getProviderName))])])])]),_c('ul',{staticClass:"list"},[(_vm.addProviderModal.providerId && _vm.isEditProvider)?_c('li',{class:{
                listActive: -1 === _vm.addProviderModal.activeTab,
              },on:{"click":function($event){return _vm.clickList(-1)}}},[_vm._v(" General info ")]):_vm._e(),_vm._l((_vm.addProviderModal.showList),function(i,index){return _c('li',{key:index,class:{
                listActive: index === _vm.addProviderModal.activeTab,
                'P-disable-tab': index > _vm.addProviderModal.disableTabs,
              },on:{"click":function($event){return _vm.clickList(index)}}},[_vm._v(" "+_vm._s(i.name)+" ")])})],2)]),(!_vm.addProviderModal.providerId || !_vm.isEditProvider)?_c('ott-button',{staticStyle:{"width":"179px","height":"33px"},attrs:{"disabled":_vm.disableButton,"click":_vm.saveAndClose,"custom-class":"primary-md-btn","text":"Save And Close"}}):_vm._e()],1),_c('div',{staticClass:"right"},[_c('div',{staticClass:"main"},[(
              _vm.activeTab === -1 &&
              _vm.addProviderModal.providerId &&
              _vm.isEditProvider &&
              _vm.permissionIs('getOttProvider')
            )?_c('general-info'):_vm._e(),(_vm.activeTab === 0)?_c('company-info',{ref:"companyInfo",attrs:{"updateProvider":_vm.updateProviderData}}):_vm._e(),(
              _vm.activeTab === 1 &&
              (_vm.permissionIs('getOttProviderEmails') ||
                _vm.permissionIs('getOttProviderPhones'))
            )?_c('contact-section',{ref:"contactSection",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 2)?_c('admin-personal-info',{ref:"companyPersonalInfo",attrs:{"updateProvider":_vm.updateProviderData}}):_vm._e(),(_vm.activeTab === 3 && _vm.permissionIs('getOttProviderAddresses'))?_c('company-addresses',{ref:"companyAddresses",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 4 && _vm.permissionIs('getOttProviderInfo'))?_c('contact-info-for-clients-apps',{ref:"contactInfoForClientsApps"}):_vm._e(),(_vm.activeTab === 5 && _vm.permissionIs('getOttProviderPermission'))?_c('permissions'):_vm._e(),(
              _vm.activeTab === 6 && _vm.permissionIs('getOttProviderBalanceCredit')
            )?_c('balance-credit',{ref:"balanceCredit",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 7 && _vm.permissionIs('getOttProviderPaymentMethods'))?_c('payment-methods',{ref:"paymentMethods",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 8 && _vm.permissionIs('getOttProviderInvoice'))?_c('invoice-generation',{ref:"invoiceGeneration",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 9 && _vm.permissionIs('getOttProviderPrinters'))?_c('label-printers',{ref:"labelPrinters",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 10 && _vm.permissionIs('getOttProviderSalesTax'))?_c('sale-taxes',{ref:"saleTexes",on:{"showSaveBtnFunc":_vm.showSaveBtnFunc}}):_vm._e(),(_vm.activeTab === 11 && _vm.permissionIs('getOttProviderPaymentGateway'))?_c('merchants-list',{ref:"paymentGateway"}):_vm._e(),(_vm.activeTab === 12 &&
              _vm.permissionIs('getOttProviderShippingProvider'))?_c('shipping-providers',{ref:"shippingProviders"}):_vm._e(),(_vm.activeTab === 13 && _vm.permissionIs('getOttProviderOtherApi'))?_c('other-api-integration'):_vm._e(),(_vm.activeTab === 14)?_c('conversation-api',{attrs:{"id":_vm.addProviderModal.providerId}}):_vm._e(),(_vm.activeTab === 15 && _vm.permissionIs('getOttProviderUi'))?_c('ui-and-access',{ref:"uiAndAccess"}):_vm._e(),_c('Transition',{attrs:{"name":"fade"}},[(_vm.isSaved)?_c('v-alert',{staticClass:"mt-5",staticStyle:{"width":"max-content"},attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" The request has been saved successfully ")]):_vm._e()],1)],1),(
            (!_vm.addProviderModal.providerId || !_vm.isEditProvider) &&
            _vm.showSaveButton
          )?_c('div',{staticClass:"footer"},[_c('ott-button',{attrs:{"click":_vm.nextFunc,"text":_vm.getButtonText,"icon-after":_vm.activeTab !== 15,"custom-class":"primary-sm-btn"}})],1):_vm._e(),(_vm.isEditProvider)?_c('div',{staticClass:"footer"},[_c('ott-button',{attrs:{"custom-class":"neutral-sm-btn mr-0","text":"Exit","click":_vm.closeModal}})],1):_vm._e()]),(_vm.addProviderModal.createProviderModal.isOpen)?_c('create-provider-modal',{attrs:{"errorMessage":_vm.errorMessage,"isLoading":_vm.isLoading},on:{"closeModal":_vm.closeModal,"createProvider":_vm.createProvider}}):_vm._e()],1)]},proxy:true}],null,false,19497601)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }