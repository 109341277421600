<template>
  <div>
    <div class="P-modal-components P-general-info mb-6" v-if="usersData && usersData.user">
      <div class="P-modal-component-header G-justify-between">
        <h3 class="secondary--text">GeoIP Info</h3>
      </div>
      <div class="P-modal-component-body">
        <div class="P-info-block">
          <ul>
            <li>
              <p class="neutral--text">Country code</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.countryCode : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Location</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined && usersData.user.geoInfo.location.timezone ? usersData.user.geoInfo.location.timezone : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Ip</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.realIp : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Network</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.network : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Postal code</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.postalCode : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">ISP</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.isp : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Organization</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.organization : '' }}</p>
            </li>
            <li>
              <p class="neutral--text">Domain</p>
              <p class="secondary--text">{{ usersData.user.geoInfo !== undefined ? usersData.user.geoInfo.domain : '' }}</p>
            </li>
          </ul>
        </div>
        <div class="P-modal-component-header G-justify-between">
          <h3 class="secondary--text">Registration time</h3>
        </div>
        <div class="P-info-block">
          <ul>
            <li>
              <p class="neutral--text">Date/Time of Send</p>
              <p class="secondary--text">{{ usersData.user.createTime }}</p>
            </li>
          </ul>
        </div>
        <div class="P-modal-component-header G-justify-between">
          <h3 class="secondary--text">Login attempts after registration</h3>
        </div>
        <div class="P-info-block">
          <ul>
            <li>
              <p class="neutral--text">Total attempts count</p>
              <p class="secondary--text">{{ usersData.user.loginAttempt }}</p>
            </li>
          </ul>
        </div>
        <div class="P-modal-component-header G-justify-between">
          <h3 class="secondary--text">Common Info</h3>
        </div>
        <div class="P-info-block">
          <ul>
            <li>
              <p class="neutral--text">Parent</p>
              <p class="secondary--text">{{ usersData.parent ? usersData.parent.name[0].name : "-" }}</p>
            </li>
            <li>
              <p class="neutral--text">Added</p>
              <p class="secondary--text">
                {{ usersData.user.createdAt }}
              </p>
            </li>
            <li>
              <p class="neutral--text">Added By</p>
              <p class="secondary--text">
                {{
                    usersData.registerBy
                    ? usersData.registerBy.firstname + " " + usersData.registerBy.lastname
                    : "-"
                }}
              </p>
            </li>
            <li>
              <p class="neutral--text">Edited</p>
              <p class="secondary--text">{{ usersData.user.updatedAt }}</p>
            </li>
            <li>
              <p class="neutral--text">Edited By</p>
              <p class="secondary--text">
                {{
                    usersData.editBy
                    ? usersData.editBy.firstname + " " + usersData.editBy.lastname
                    : "-"
                }}
              </p>
            </li>
            <li>
              <p class="neutral--text">Balance</p>
              <p class="secondary--text">{{ usersData.balance }}</p>
            </li>
            <li>
              <p class="neutral--text">Credit</p>
              <p class="secondary--text">
                {{ usersData.credits ? usersData.credits.amount : 0 }}
              </p>
            </li>
            <li>
              <p class="neutral--text">Credit Remain</p>
              <p class="secondary--text">{{ usersData.credits ? Math.floor(usersData.credits.remainDays) : 0 }}</p>
            </li>
            <li>
              <p class="neutral--text">To Block Remain</p>
              <p class="secondary--text">{{ usersData.credits ? Math.floor(usersData.credits.remainDaysBlock) : 0 }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <v-skeleton-loader
        v-else
        type="article, article, article, article, article"
        max-width="400"
    />
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "GeneralInfo",
  computed: {
    ...mapState({
      usersData: (state) => state.addProviderModal.providerData,
    }),
  },
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";

.P-modal-component-header {
  margin-bottom: 20px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-modal-component-body {
  margin-top: 20px;
}

.P-info-block {
  margin-bottom: 20px;

  ul {
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      p:first-child {
        min-width: 150px;
        padding-right: 10px;
        font-size: $txt12;
        font-weight: $semiBold;
        text-transform: capitalize;
      }

      p:last-child {
        font-size: $txt14;
      }

      & + li {
        margin-top: 12px;
      }
    }
  }
}

.P-general-info {
  max-height: 715px;
  overflow-y: auto;
}
</style>
