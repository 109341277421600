<template>
  <ott-dialog
    :is-open="addProviderModal.createProviderModal.isOpen"
    :width="600"
    :show-footer="false"
    :show-header="false"
    @outside="()=>{}"
  >
    <template v-slot:modalBody>
      <div>
        <div class="approve-cont">
          <div class="header">
            <h6 class="secondary--text">Create a provider ?</h6>
          </div>
          <div>
            <p class="secondary--text">
              The provider will be created, it will be possible to delete it only from the table.
            </p>
            <p class="secondary--text">
              Do you agree ?
            </p>

            <error-message-container
              v-if="errorMessage"
              :message="errorMessage"
            />
          </div>
        </div>
        <div class="footer">
          <SaveResetButtons
            v-if="!errorMessage"
            :right-click-func="createProvider"
            :left-click-func="closeCreateModal"
            :is-loading="isLoading"
            right-label="Create"
          />

          <ott-button-loader
            v-else
            button-text="Close"
            @onClick="closeCreateModal"
          />
        </div>
      </div>
    </template>
  </ott-dialog>
</template>

<script>
  import {mapMutations, mapState} from "vuex";
  // components
  import OttDialog from "@/components/vuetifyComponents/OttDialog";
  import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";
  import SaveResetButtons from "@/components/SaveResetButtons";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";

  export default {
    name: "createProviderModal",
    props: {
      errorMessage: {
        type: String,
        default: ''
      }
    },
    components: {SaveResetButtons, OttButtonLoader, OttDialog, ErrorMessageContainer},
    computed: {
      ...mapState({
        addProviderModal: state => state.addProviderModal,
        isLoading: state => state.appModule.isLoading
      })
    },
    methods: {
      ...mapMutations({
        closeCreateModal: 'addProviderModal/closeCreateModal'
      }),
      createProvider() {
        this.$emit('createProvider')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .header {
    display       : flex;
    align-items   : center;
    margin-bottom : 20px;

    h6 {
      font-size   : $txt18;
      font-weight : $semiBold;
    }
  }

  .approve-cont {
    padding : 24px;

    .mdi-check-circle-outline {
      font-size : 20px;
    }

    p {
      margin-bottom : 20px;
      font-size     : $txt14;
      font-weight   : $regular;
    }

    .primary-sm-btn::v-deep .theme--light.v-btn--has-bg {
      background-color : green;
    }
  }

  .green-btn::v-deep {
    .theme--light.v-btn.v-btn--has-bg,
    .theme--dark.v-btn.v-btn--has-bg {
      background-color : $green;
      color            : $white;
      box-shadow       : none;
      text-transform   : inherit;
      font-size        : $txt16;
      height           : 38px;
      padding          : 0 16px;
      letter-spacing   : 0.0492857143em !important;
    }
  }

  .footer {
    display         : flex;
    padding         : 0 24px 24px 24px;
    justify-content : flex-end;

    .cancel {
      font-size   : $txt15;
      font-weight : $semiBold;
      padding     : 0 16px;
      display     : flex;
      align-items : center;
      cursor      : pointer;
      margin-left : 15px;

      &:hover {
        background    : #f0f1f5;
        border-radius : $border-radius;
      }
    }
  }

  .theme--dark {
    .cancel:hover {
      background    : #54575a !important;
      border-radius : $border-radius;
    }
  }
</style>
