<template>
  <div class="P-payment-list-block">
    <div class="P-padding-payment" v-for="(payment, index) of paymentList" :key="index">
      <span class="P-disabled-payment-tooltip" v-if="!payment.inUse">Deactivate</span>

      <div class="P-payment-box G-flex G-align-center" :class="{'P-disabled': !payment.inUse}">
        <v-radio-group v-model="selectedCreditCard" @change="selectDefault(payment.id)">
          <ott-radio :value="payment.id" />
        </v-radio-group>
        <div class="P-payment-info-block G-flex G-align-center G-justify-between">
          <div class="P-payment-info G-flex G-align-center">
            <div class="P-credit-card-image"
                 v-if="payment.cardNumber"
                 :style="{backgroundImage:`url('${getCardBrand(payment.brand)}')`}"/>
            <svg class="-icon" v-else>
              <use xlink:href="/sprite.svg#bank-icon"></use>
            </svg>


            <div class="P-card-number G-flex G-align-center"
                 v-if="payment.cardNumber">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <p> {{payment.cardNumber.substr(payment.cardNumber.length - 4)}}</p>
            </div>
            <div v-else class="P-card-number G-flex G-align-center">
              <span>****</span>
              <span>****</span>
              <span>****</span>
              <p> {{payment.accountNumber.toString().substr(payment.accountNumber.toString().length - 4)}}</p>
            </div>
          </div>
          <div class="P-actions">
            <v-menu left content-class="P-actions-list">
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="P-list" @click="openDeleteModal(payment)">
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </div>
    </div>

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false" 
      @btnFunction="deletePayment"       
      btnText="Delete"
    />
  </div>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import OttRadio from "@/components/vuetifyComponents/OttRadio";
  import WarningModal from "@/components/WarningModal";
  import CreditCardsMixin from "@/mixins/CreditCardsMixin";

  export default {
    name: 'PaymentList',
    components: {OttRadio, WarningModal},
    mixins: [ CreditCardsMixin ],
    data() {
      return {
        selectedCreditCard: null,
        showWarningModal: false,
        deletedPayment: null,
        warningModalIsDangerButton: true,
        warningModalIsLoading: false,
      }
    },

    computed: {
      ...mapState({
        paymentList: state => state.paymentMethods.paymentList,
        paymentType: state => state.paymentMethods.paymentType,
        addProviderModal: state => state.addProviderModal,
      }),
      warningModalHeading() {
        return `Delete provider's payment method?`
      },

      warningModalText() {
        return `You're about to <ins>delete</ins> provider's payment method. If you're not sure, you can cancel this operation.`
      },
    },

    async created() {
      await this.getPaymentData();          
    },

    methods: {
      ...mapActions({
        updatePaymentMethod : 'paymentMethods/updateCreditCardBeforeDelete',
        getPaymentDataList: 'paymentMethods/getPaymentDataList',
        deletePaymentData: 'paymentMethods/deletePaymentData',
        getPaymentMethod: 'paymentMethods/getPaymentMethod',
      }),

      openDeleteModal(payment) {
        this.deletedPayment = payment
        this.showWarningModal = true;
      },
      
      async deletePayment() {
        this.warningModalIsLoading = true
        let body = {}

        if (this.deletedPayment) {
          await this.deletePaymentData(this.deletedPayment.id).then(async () => {
            await this.getPaymentDataList(this.addProviderModal.providerId).then(async () => {
              if (this.paymentList.length) {
                if (this.deletedPayment.default) {
                  let firstPaymentData = this.paymentList[0];
                  body = {
                    id: firstPaymentData.id,
                    data: {
                      default: true,
                      inUse: true,
                    }
                  }
                  await this.updatePaymentMethod(body).then(async () => {
                    await this.getPaymentDataList(this.addProviderModal.providerId)
                  })
                }
              }
            })
          })
        }

        this.warningModalIsLoading = false
        this.showWarningModal = false
      },
 
      async selectDefault(paymentId) {
        const body = {
          id: paymentId,
          data: {
            default: true,
            inUse: true,
          }
        }

        await this.updatePaymentMethod(body);
        await this.getPaymentData();
      },

      async getPaymentData() {
        await this.getPaymentDataList(this.addProviderModal.providerId)
        if (this.paymentList.length) {
          this.paymentList.map(item => item.default ? this.selectedCreditCard = item.id : '')
        }
        
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-padding-payment {
    position : relative;

    &:hover {
      .P-disabled-payment-tooltip {
        opacity    : 1;
        visibility : visible;
      }
    }
  }

  .P-payment-list-block {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 22px;
    grid-row-gap: 24px;
    max-height: 665px;
    overflow-y: auto;
    padding-left: 3px;
    padding-right: 20px;
  }

  .P-payment-box {
    position      : relative;
    box-shadow    : 0px 0px 5px #284E6626;
    border-radius : 4px;
    padding       : 10px 15px;

    &:hover {
      .P-actions {
        opacity    : 1;
        visibility : visible;
      }
    }
  }

  .P-credit-card-image {
    width               : 50px;
    height              : 30px;
    background-position : center;
    background-repeat   : no-repeat;
    background-size     : contain;
    margin-right        : 15px;
  }

  .P-card-number {
    span {
      display        : inline-block;
      margin-top     : 6px;
      margin-right   : 10px;
      color          : $secondary-color;
      font-size      : 16px;
      font-weight    : 600;
      letter-spacing : 1px;

    }

    p {
      margin-bottom  : 0;
      color          : $secondary-color;
      font-size      : 18px;
      font-weight    : 600;
      letter-spacing : 1px;
    }
  }

  .P-selected-payment span {
    width         : 16px;
    height        : 16px;
    border-radius : 50%;
    border        : 1px solid $neutral-color;
    display       : block;
    position      : relative;
    cursor        : pointer;

    &:before {
      position         : absolute;
      width            : 100%;
      height           : 100%;
      background-color : $primary-color;
      opacity          : 0;
      visibility       : hidden;
      border-radius    : 50%;
      transform        : scale(0.7);
      content          : '';
    }

    &.P-active-payment {
      border-color : $primary-color;

      &:before {
        opacity    : 1;
        visibility : visible;
      }
    }
  }

  .P-payment-info {
    margin-left : 15px;
  }

  .P-payment-info-block {
    width : 100%;
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }

  .theme--dark {
    .P-payment-box {
      background-color : rgba(255, 255, 255, 0.05);
    }

    .P-card-number {
      span, p {
        color : $white;
      }

    }

    .P-credit-card-image {
      fill : white;
    }
  }

  .P-disabled {
    opacity : 0.5;
  }

  .P-disabled-payment-tooltip {
    position         : absolute;
    padding          : 5px 5px;
    border-radius    : 6px;
    background-color : rgba(0, 0, 0, 0.54);
    left             : 25px;
    top              : -25px;
    font-size        : 12px;
    color            : $white;
    transition       : $transition;
    opacity          : 0;
    visibility       : hidden;
  }

  ::v-deep {
    .v-btn--icon.v-size--default {
      height: inherit;
      width: inherit;
    }
    .v-input {
        width: inherit;
    }
    .v-input--selection-controls {
        margin-top: 0;
        padding-top: 0;
    }
    .v-input__slot {
        margin-bottom: 0;
    }
    .v-messages {
        display: none;
    }
    .v-input--selection-controls__input {
        width: inherit;
    }
  }

</style>
