<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">
        Permissions & settings
      </h3>
    </div>
    <div class="P-modal-component-body P-permission-block">
      <div class="P-label-list">
        <div class="P-table-header">
          <ul class="G-flex ">
            <li class="secondary--text">Permissions</li>
            <li class="secondary--text G-justify-center">Off/On</li>
            <li class="secondary--text G-justify-center">Off/On child</li>
            <li class="secondary--text G-justify-center">
              Current state from parent
            </li>
          </ul>
        </div>
        <div class="P-table-body">
          <ul
            v-for="(item, index) of permissionsListForProviders"
            :key="index"
            class="G-flex"
          >
            <li class="secondary--text">{{ permissionName(item.name) }}</li>
            <li class="secondary--text G-justify-center">
               <div class="Ott-switch-cont">
                <v-switch
                  v-model="item.onOff"
                  :disable="item.state === 0"
                  :ripple="false"
                  inset
                  @change="changeSwitch(item)"
                />
              </div>
            </li>
            <li class="secondary--text G-justify-center Ott-switch-cont">
              <v-switch
                v-model="item.onOffChild"
                :disable="item.state === 0"
                :ripple="false"
                inset
                @change="changeSwitch(item)"
              />
            </li>
            <li class="secondary--text G-justify-center">
              {{ item.state ? 'On' : 'Off' }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapActions, mapState} from "vuex";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";

export default {
  name: "Permissions",
  components: { OttSwitch, OttCheckbox },
  computed: {
    ...mapState({
      permissionsListForProviders: state =>
        state.permissions.permissionsListForProviders,
      providerId: state => state.addProviderModal.providerId
    })
  },
  async created() {
    if (this.providerId) {
      await this.getPermissionsList({providerId: this.providerId})
    }
  },
  methods: {
    ...mapActions({
      getPermissionsList: 'permissions/getPermissionsList',
      editPermission: 'permissions/editPermission'
    }),
    permissionName(name) {
      return name[0].name
    },
    async changeSwitch(item) {
      let data = {
        permissions: {
          permission: item.permission,
          onOff: item.onOff,
          onOffChild: item.onOffChild
        }
      }
      await this.editPermission({providerId: this.providerId, data: data})
    }
  }
};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "./src/assets/scss/mixins";
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}

.P-permission-block {
  margin-top: 15px;
}

.P-table-header {
  ul {
    li {
      font-size: $txt12;
      font-weight: bold;
      padding: 10px 17px;
      display: flex;
      align-items: center;
      text-transform: capitalize;
    }
  }
}
.P-table-body {
  ul {
    li {
      font-size: $txt14;
      word-break: break-all;
      padding: 12px 17px;
      transition: $transition;
      display: flex;
      align-items: center;
      text-transform: capitalize;
      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
    border-top: 1px solid $neutral-color45;
  }
}
.P-label-list {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $neutral-color45;

  ul {
    padding: 0;
    li {
      max-width: 100%;
      width: 100%;
      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
  }

  li {
    &:first-child {
      min-width: 280px;
    }
  }
}
.P-table-body::v-deep {
  .v-input--switch {
    margin-top: 0;
  }
  .v-input__slot {
    margin-bottom: 0;
  }
  .v-messages {
    display: none;
  }
}
::v-deep {
  .Ott-switch-cont[data-v-1c752990] .v-input--selection-controls__input {
    margin-right: 0;
  }
}
.Ott-switch-cont::v-deep {
  .v-input--switch--inset .v-input--switch__track {
    height: 18px;
    width: 32px;
    margin-top: 5px;
    left: -2px;
  }
  .v-input--switch--inset .v-input--switch__track,
  .v-input--switch--inset .v-input--selection-controls__input {
    width: 32px;
  }
  .v-input--switch__thumb {
    width: 14px;
    height: 14px;
    background-color: white;
    top: calc(50% - 7px);
  }
  .v-input--switch--inset .v-input--switch__track {
    opacity: 1;
  }
  .theme--light.v-input--switch .v-input--switch__track {
    color: $neutral-color45;
  }
  .theme--dark.v-input--switch .v-input--switch__track {
    color: $neutralBtnMD;
  }
  .v-input--selection-controls__input {
    margin-right: 15px;
    height: 18px;
  }
  .v-input__slot {
    height: 18px;
  }
  .v-input input,
  .v-input textarea {
    height: 18px;
  }
  .v-label {
    font-size: $txt14;
    text-transform: capitalize !important;
  }
  .theme--light {
    &.v-label {
      color: $secondary-color;
    }
    &.v-icon {
      color: $neutral-color45;
    }
  }
  .v-input--selection-controls .v-input__slot > .v-label p,
  .v-input--selection-controls .v-radio > .v-label p {
    margin-bottom: 0 !important;
  }
  .v-input--switch--inset.v-input--is-dirty
  .v-input--selection-controls__ripple,
  .v-input--switch--inset.v-input--is-dirty .v-input--switch__thumb {
    transform: translate(14px, 0) !important;
  }
  .v-input--is-label-active .v-input--switch__track {
    background: $primary-color !important;
  }
  .theme--dark {
    &.v-label {
      color: $white;
    }
    &.v-input--is-label-active .v-input--switch__track {
      background: $primary-colorDM !important;
    }
    .P-text {
      color: $white;
    }
  }
  .P-text {
    text-transform: capitalize;
  }
}
</style>
