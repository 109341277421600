<template>
  <div class="P-manage-country-tax">
    <div class="P-full-line G-flex-wrap">
      <div class="P-padding-50">
        <ott-select-autocomplete
          v-model="country"
          outlined
          :items="countryList"
          :height="38"
          :error="$v.country.$error"
          :disabled="this.saleTaxes.isEditMode && country === 'US'"
          selected-text="name"
          label="Country*"
        />
      </div>
      <div class="P-padding-50" v-if="country === 'US'">
        <ott-select-autocomplete
          v-model="state"
          outlined
          :items="usStates"
          :height="38"
          :error="$v.state.$error"
          selected-text="name"
          label="State*"
        />

      </div>
      <div class="P-padding-25">
        <ott-input
          v-model="servicePercent"
          :error="errorServiceTax"
          :maxLength="5"
          :customInputClass="'P-percent-service'"
          :is-percent="true"
          :focus="focusServiceTax"
          :blur="blurServiceTax"
          label="Service percent"
        />
      </div>
      <div class="P-padding-25">
        <ott-input
          v-model="productPercent"
          :maxLength="5"
          :customInputClass="'P-percent-prod'"
          :error="errorProductTax || $v.productPercent.$error"
          :is-percent="true"
          :focus="focusProductTax"
          :blur="blurProductTax"
          label="Product percent*"
          ref="productPercent"
        />
      </div>
    </div>
    <div class="P-full-line P-buttons G-flex ">
      <div class="P-checkbox">
        <save-reset-buttons
          v-if="saleTaxes.countryTaxList.length"
          :right-label="saleTaxes.isEditMode ? 'Save' : 'Add'"
          :is-loading="isLoading"
          :right-click-func="() => addTaxData()"
          :left-click-func="() => cancelOrReset()"
        />
      </div>

    </div>
  </div>
</template>
<script>
  import { mapActions, mapMutations, mapState } from "vuex";
  import { required } from "vuelidate/lib/validators";
  import OttInput from "@/components/vuetifyComponents/OttInput";
  import allCountry from "@/constants/all-countries";
  import usStates from "@/constants/us-states";
  import OttSelect from "@/components/vuetifyComponents/OttSelect";
  import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
  import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
  import SaveResetButtons from "@/components/SaveResetButtons";

  export default {
    name: "ManageCountryTax",
    components: {
      OttSelectAutocomplete,
      ErrorMessageContainer,
      OttSelect,
      OttInput,
      SaveResetButtons
    },
    
    validations: {
      country: {required},
      productPercent: {required},
      state: {required}
    },

    data() {
      return {
        countryList: JSON.parse(JSON.stringify(allCountry)),
        usStates: JSON.parse(JSON.stringify(usStates)),
        isFocusProductTax: false,
        isFocusServiceTax: false,
        errorProductTax: false,
        errorServiceTax: false,
      };
    },

    computed: {
      ...mapState({
        saleTaxes: state => state.saleTaxes,
        addProviderModal: state => state.addProviderModal,
        isLoading: state => state.appModule.isLoading
      }),
      country: {
        get() {
          return this.saleTaxes.formData.country;
        },
        set(value) {
          this.setCountry(value);
        }
      },
      state: {
        get() {
          return this.saleTaxes.formData.state;
        },
        set(value) {
          this.setState(value);
        }
      },
      productPercent: {
        get() {
          return this.saleTaxes.formData.productPercent;
        },
        set(value) {
          this.errorProductTax = +value < 0;
             let x = value
          if (value > 100) {
            x = 100
          }
          this.setProductPercent(x);
        }
      },
      servicePercent: {
        get() {
          return this.saleTaxes.formData.servicePercent;
        },
        set(value) {
          this.errorServiceTax = +value < 0;
          let x = value
          if (value > 100) {
            x = 100
          }
          this.setServicePercent(x);
        }
      }
    },

    mounted() {
      /**
       * Remove selected Countries
       * */
      this.countryList = this.countryList.filter(x => {
        if (this.saleTaxes.isEditMode) {
          return !this.saleTaxes.countryTaxList.some(
            y =>
              y.country === x.value &&
              y.country !== "US" &&
              y.country !== this.country
          );
        } else {
          return !this.saleTaxes.countryTaxList.some(
            y => y.country === x.value && y.country !== "US"
          );
        }
      });

      /**
       * Remove selected state list
       * **/
      this.saleTaxes.countryTaxList.forEach(item => {
        if (item.country === "US") {
          this.usStates = this.usStates.filter(x => {
            if (this.saleTaxes.isEditMode) {
              return !item.subList.some(
                y => x.value === y.state && y.state !== this.state
              );
            } else {
              return !item.subList.some(y => x.value === y.state);
            }
          });
        }
      });
    },

    methods: {
      ...mapActions({
        updateSaleTaxData: 'saleTaxes/updateSaleTaxData'
      }),

      ...mapMutations({
        setProductPercent: 'saleTaxes/setProductPercent',
        setServicePercent: 'saleTaxes/setServicePercent',
        saveEditData: 'saleTaxes/saveEditData',
        closeEdit: 'saleTaxes/closeEdit',
        addCountryTax: 'saleTaxes/addCountryTax',
        toSaveData: 'saleTaxes/toSaveData',
        resetData: 'saleTaxes/resetData',
        setCountry: 'saleTaxes/setCountry',
        setState: 'saleTaxes/setState',
      }),

      focusProductTax() {
        if (this.productPercent || this.productPercent === 0) {
          let x = this.productPercent.toString().split("%")[0];
          this.setProductPercent(+x);
        }
      },
      blurProductTax() {
        if (this.productPercent || this.productPercent === 0) {
          let x = this.productPercent + "%";
          this.setProductPercent(x);
        }
      },
      focusServiceTax() {
        if (this.servicePercent || this.servicePercent === 0) {
          let x = this.servicePercent.toString().split("%")[0];
          this.setServicePercent(+x);
        }
      },
      blurServiceTax() {
        if (this.servicePercent || this.servicePercent === 0) {
          let x = this.servicePercent + "%";
          this.setServicePercent(x);
        }
      },
      async addTaxData() {
        let validate = false;
        this.$v.state.$touch();
        this.focusServiceTax();
        this.focusProductTax()
        validate = this.validateFormData();
        if (this.country === "US") {
          if (!this.$v.state.$error && validate) {
            if (this.saleTaxes.isEditMode) {
              this.saveEditData(this.saleTaxes.formData);
              this.closeEdit();
            } else {
              this.addCountryTax(this.saleTaxes.formData);
            }
          }
        } else {
          if (validate) {
            if (this.saleTaxes.isEditMode) {
              this.saveEditData(this.saleTaxes.formData);
              this.closeEdit();
            } else {
              this.addCountryTax(this.saleTaxes.formData);
            }
          }
        }

        this.toSaveData();
        await this.updateSaleTaxData(this.addProviderModal.providerId)     
      },

      validateFormData() {
        this.$v.country.$touch();
        this.$v.productPercent.$touch();
        return (
          !this.$v.country.$error &&
          !this.$v.productPercent.$error &&
          !this.errorProductTax &&
          !this.errorServiceTax
        );
      },
      cancelOrReset() {
        if (this.saleTaxes.isEditMode) {
          this.resetData();
        } else {
          this.closeEdit();
        }
      }
    },
  };
</script>
<style lang="scss" scoped>
  .P-full-line {
    margin : 0 -10px;
  }

  .P-padding-50 {
    width   : 50%;
    padding : 10px;
  }

  .P-padding-100 {
    width   : 100%;
    padding : 10px;
  }

  .P-padding-25 {
    width   : 25%;
    padding : 10px;
  }

  .P-checkbox {
    padding : 10px;
    display : inline-block;
  }

  .P-buttons {
    margin-top : 10px;
  }
</style>
