import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import timeZoneList from "@/constants/time-zone-list";

export default {
  data() {
    return {
      timeZoneList: timeZoneList,
      invoiceAddressesList: [],
      emailList: [],
      phoneList: [],
      postalMethods: [
        {
          name: 'Manual',
          value: 'manual'
        },
        {
          name: 'Postal Method Color',
          value: 'color'
        },
        {
          name: 'Postal Method BW',
          value: 'bw'
        },
      ],
      autosends: [
        {
          name: 0,
          value: 0
        },
        {
          name: 1,
          value: 1
        },
        {
          name: 2,
          value: 2
        },
        {
          name: 3,
          value: 3
        },
        {
          name: 4,
          value: 4
        }
      ]
    }
  },
  
  validations: {
    settingsData: {
      dateFormat: { required },
      timezone: { required },
      currency: { required },
      language: { required },
      paperFormat: { required },
      invoiceGenerateDay: { required },
      phone: { required },
      email: { required },
      postalMethod: { required },
      address: { required },
      website: { required }
    }
  },

  computed: {
    ...mapState({
      invoice: state => state.invoice,
      settingsData: state => state.invoice.settingsData,
      invoiceAddresses: state => state.companyAddresses.invoiceAddresses,
      contactModule: state => state.contactModule,
      companyInfo: state => state.companyInfo.companyInfo,
      addProviderModal: state => state.addProviderModal,
      isEditProvider: state => state.providers.isEditProvider,
      isLoading: state => state.appModule.isLoading
    }),
    dateFormat: {
      get() {
        return this.invoice.settingsData.dateFormat;
      },
      set(value) {
        this.setDateFormat(value);
      }
    },
    timezone: {
      get() {
        return this.invoice.settingsData.timezone;
      },
      set(value) {
        this.setTimeZone(value);
      }
    },
    currency: {
      get() {
        return this.invoice.settingsData.currency;
      },
      set(value) {
        this.setCurrency(value);
      }
    },
    language: {
      get() {
        return this.invoice.settingsData.language;
      },
      set(value) {
        this.setLanguage(value);
      }
    },
    paperFormat: {
      get() {
        return this.invoice.settingsData.paperFormat;
      },
      set(value) {
        this.setPaperFormat(value);
      }
    },
    invoiceGenerateDay: {
      get() {
        return this.invoice.settingsData.invoiceGenerateDay;
      },
      set(value) {
        this.setInvoiceGenerateDate(value);
      }
    },
    phone: {
      get() {
        return this.invoice.settingsData.phone;
      },
      set(value) {
        this.setPhoneNumber(value);
      }
    },
    email: {
      get() {
        return this.invoice.settingsData.email;
      },
      set(value) {
        this.setEmail(value);
      }
    },
    postalMethod: {
      get() {
        return this.invoice.settingsData.postalMethod;
      },
      set(value) {
        this.setPostalMethod(value);
      }
    },
    autosend: {
      get() {
        return this.invoice.settingsData.autosend ?? 0;
      },
      set(value) {
        this.setAutosend(value);
      }
    },
    returnEnvelope: {
      get() {
        return this.invoice.settingsData.returnEnvelope;
      },
      set(value) {
        this.setReturnEnvelope(value);
      }
    },
    address: {
      get() {
        return this.invoice.settingsData.address;
      },
      set(value) {
        this.setAddress(value);
      }
    },
    comment: {
      get() {
        return this.invoice.settingsData.comment;
      },
      set(value) {
        this.setComment(value);
      }
    },
    website: {
      get() {
        return this.invoice.settingsData.website;
      },
      set(value) {
        this.setWebSite(value);
      }
    }
  },

  mounted() {

    if (this.companyInfo.website && !this.invoice.flagForWebsite) {
      this.setWebSite(this.companyInfo.website);
    }

    if (this.invoiceAddresses.length) {
      this.invoiceAddressesList = _.clone(this.invoiceAddresses)
      this.invoiceAddressesList.map(item => item.name = item.name.replace('<br />', ' '))
    }
    if (this.contactModule.emails.length) {
      this.emailList = this.contactModule.emails.filter(x => x.forInvoice);
    }
    if (this.contactModule.phones.length) {
      this.phoneList = this.contactModule.phones.filter(x => x.forInvoice);
    }
    if (this.invoice.settingsData.dateFormat) {
      this.setIsEditInvoiceSettings();
    }
  },

  methods: {
    ...mapActions({
      manageInvoiceData: 'invoice/manageInvoiceData',
    }),
    
    ...mapMutations({
      setDateFormat: 'invoice/setDateFormat',
      setTimeZone: 'invoice/setTimeZone',
      setCurrency: 'invoice/setCurrency',
      setLanguage: 'invoice/setLanguage',
      setPaperFormat: 'invoice/setPaperFormat',
      setInvoiceGenerateDate: 'invoice/setInvoiceGenerateDate',
      setPhoneNumber: 'invoice/setPhoneNumber',
      setEmail: 'invoice/setEmail',
      setPostalMethod: 'invoice/setPostalMethod',
      setAddress: 'invoice/setAddress',
      setComment: 'invoice/setComment',
      setWebSite: 'invoice/setWebSite',
      setIsEditInvoiceSettings: 'invoice/setIsEditInvoiceSettings',
      setFlagWebsite: 'invoice/setFlagWebsite',
      setActiveTab: 'invoice/setActiveTab',
      generateInvoiceImage: 'invoice/generateInvoiceImage',
      resetDataSettings: 'invoice/resetDataSettings',
      setReturnEnvelope: 'invoice/setReturnEnvelope',
      setAutosend: 'invoice/setAutosend'
    }),


    updateWebsite() {
      this.setFlagWebsite();
    },

    async saveSettings() {
      if (this.validateInvoiceSettings()) {
        const body = {
          providerId: this.addProviderModal.providerId,
          data:{
            settings: this.settingsData
          }
        }
        await this.manageInvoiceData(body).then(()=>{
          this.setActiveTab(2);
          this.generateInvoiceImage(this.addProviderModal.providerId)

          this.setIsEditInvoiceSettings();
        })
      }
    },
    resetData() {
      this.resetDataSettings();
    },
    validateInvoiceSettings() {
      this.$v.settingsData.$touch();
      return !this.$v.settingsData.$error;
    }
  },
};
