<template>
  <div class="content-email-section">
    <div class="pb-2">
      <ott-input
        v-model="emailValue"
        :error="contactModule.isError || $v.emailValue.$error"
        label="Email Address*"
        @emitFunc="validate"
      />
    </div>
    <div class="content-email-checkboxes">
      <ott-checkbox v-model="inUse" :disabled="inMain || !contactModule.emails.length" label="Disable" />
      <ott-checkbox v-model="inMain" :disabled="inUse || isMainCheck" label="Is main" />
      <ott-checkbox v-model="isInfo" label="Is Info" />
      <ott-checkbox v-model="isSupport" label="Is support" />
      <ott-checkbox v-model="forInvoice" label="For Invoice"  />
    </div>
    <ott-switch
      v-model="isForSent"
      :className="'secondary-dark--text'"
      label="Use For Sent"
    />
    <div class="P-for-sent-data" v-if="isForSent">
      <div class="for-sent-cont">
        <div>
          <ott-input
            v-model="smtpServer"
            :error="$v.smtpServer.$error"
            label="SMTP Server"
          />
        </div>
        <div>
          <ott-input
            v-model="smtpPort"
            :is-percent="true"
            :error="validatePort || $v.smtpPort.$error"
            :maxLength="5"
            :customInputClass="'P-smpt-port'"
            @emitFunc="validateSMTPPort"
            label="SMTP Port"
          />
        </div>
        <div>
          <ott-input
            v-model="username"
            :error="$v.username.$error"
            label="username"
          />
        </div>
        <div>
          <password-input
            v-model="useForSentPassword"
            :error="$v.useForSentPassword.$error"
            label="Password"
          />
        </div>
      </div>
      <div class="content-email-checkboxes">
        <ott-checkbox label="use SSL" v-model="useSSL" />
      </div>
    </div>
    <ErrorMessageContainer
      v-if="contactModule.isError || contactModule.isCheckError"
      :message="contactModule.errorMessage"
      :styles="{ width: 'max-content' }"
    />
    <save-reset-buttons
      v-if="contactModule.isAddEmail || contactModule.isEditEmail"
      :right-label="!contactModule.isAddEmail ? 'Save' : 'Add'"
      :is-loading="isLoading"
      :left-click-func="() => cancel()"
      :right-click-func="() => save()"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { required, email } from "vuelidate/lib/validators";
import { ContactInfoModel } from "@/models/providers/contactInfoModel";
import { AddProviderModel } from "@/models/providers/addProviderModel";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttInput from "@/components/vuetifyComponents/OttInput";
import PasswordInput from "@/components/PasswordInput";
import OttSwitch from "@/components/vuetifyComponents/OttSwitch";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SaveResetButtons from "@/components/SaveResetButtons";

export default {
  name: "EmailContent",
  components: {
    SaveResetButtons,
    ErrorMessageContainer,
    OttSwitch,
    PasswordInput,
    OttInput,
    OttCheckbox,
  },
  validations: {
    emailValue: { required, email },
    smtpServer: { required },
    smtpPort: { required },
    username: { required },
    useForSentPassword: { required }
  },
  data() {
    return {
      validatePort: false,
      isMainCheck: false,
      currentEmailValue: ''
    };
  },
  computed: {
    ...mapState({
      contactModule: state => state.contactModule,
      addProviderModal: state => state.addProviderModal,
      companyInfo: state => state.companyInfo,
      adminPersonalInfoModule: state => state.adminPersonalInfoModule,
      isLoading: state => state.appModule.isLoading
    }),
    editableEmailDataIndex() {
      return this.contactModule.editableEmailDataIndex
    },
    emailValue: {
      get() {
        return this.contactModule.emailForm.emailValue;
      },
      set(value) {
        this.updateEmailValue(value);
      }
    },
    inUse: {
      get() {
        return this.contactModule.emailForm.inUse;
      },
      set(value) {
        this.updateInUse(value);
      }
    },
    inMain: {
      get() {
        return this.contactModule.emailForm.inMain;
      },
      set(value) {
        this.updateInMain(value);
      }
    },
    isInfo: {
      get() {
        return this.contactModule.emailForm.isInfo;
      },
      set(value) {
        this.updateIsInfo(value);
      }
    },
    isSupport: {
      get() {
        return this.contactModule.emailForm.isSupport;
      },
      set(value) {
        this.updateIsSupport(value);
      }
    },
    forInvoice: {
      get() {
        return this.contactModule.emailForm.forInvoice;
      },
      set(value) {
        this.updateForInvoice(value);
      }
    },
    isForSent: {
      get() {
        return this.contactModule.emailForm.useForSent.isForSent;
      },
      set(value) {
        this.updateUseForSentIsForSent(value);
      }
    },
    smtpServer: {
      get() {
        return this.contactModule.emailForm.useForSent.smtpServer;
      },
      set(value) {
        this.updateUseForSentSmtpServer(value);
      }
    },
    smtpPort: {
      get() {
        return this.contactModule.emailForm.useForSent.smtpPort;
      },
      set(value) {
        this.updateUseForSentSmtpPort(value);
      }
    },
    username: {
      get() {
        return this.contactModule.emailForm.useForSent.username;
      },
      set(value) {
        this.updateUseForSentUsername(value);
      }
    },
    useSSL: {
      get() {
        return this.contactModule.emailForm.useForSent.useSSL;
      },
      set(value) {
        this.updateUseForSentUseSSL(value);
      }
    },
    useForSentPassword: {
      get() {
        return this.contactModule.emailForm.useForSent.useForSentPassword;
      },
      set(value) {
        this.updateUseForSentUseForSentPassword(value);
      }
    }
  },
  mounted() {
   this.isMainCheck =  this.getCurrentData()
   this.currentEmailValue = this.emailValue
  },
  methods: {
    ...mapActions({
      addEmail: 'contactModule/addEmail',
      manageEmail: 'contactModule/manageEmail',
      editProvider: 'addProviderModal/editProvider',
      getProvider: 'addProviderModal/getProvider',
    }),

    ...mapMutations({
      updateEmailValue: 'contactModule/updateEmailValue',
      updateInUse: 'contactModule/updateInUse',
      updateInMain: 'contactModule/updateInMain',
      updateIsInfo: 'contactModule/updateIsInfo',
      updateIsSupport: 'contactModule/updateIsSupport',
      updateForInvoice: 'contactModule/updateForInvoice',
      updateUseForSentIsForSent: 'contactModule/updateUseForSentIsForSent',
      updateUseForSentSmtpServer: 'contactModule/updateUseForSentSmtpServer',
      updateUseForSentSmtpPort: 'contactModule/updateUseForSentSmtpPort',
      updateUseForSentUsername: 'contactModule/updateUseForSentUsername',
      updateUseForSentUseSSL: 'contactModule/updateUseForSentUseSSL',
      updateUseForSentUseForSentPassword: 'contactModule/updateUseForSentUseForSentPassword',
      setError: 'contactModule/setError',
      setCheckValidateEmail: 'contactModule/setCheckValidateEmail',
      checkIsMainEmail: 'contactModule/checkIsMainEmail',
      addEmailSave: 'contactModule/addEmailSave',
      saveEditEmailForm: 'contactModule/saveEditEmailForm',
      setProviderId: 'addProviderModal/setProviderId',
      updateContactData: 'contactModule/updateContactData',
      updateCompanyInfo: 'companyInfo/updateCompanyInfo',
      updatePersonalInfo: 'adminPersonalInfoModule/updatePersonalInfo',
      cancelEditEmailForm: 'contactModule/cancelEditEmailForm',
      addEmailCancel: 'contactModule/addEmailCancel',
    }),

    getCurrentData(){
      let currentIndex = false
      if(!this.contactModule.isAddEmail && this.contactModule.emails.length){
        this.contactModule.emails.forEach((item,index)=>{
          if(item.emailValue===this.emailValue){
            currentIndex = item.inMain
          }
        })
      }
      return currentIndex
    },
    validateSMTPPort() {
      if (+this.smtpPort >= 65535) {
        this.smtpPort = 65534;
      }
      this.validatePort = this.smtpPort < 1;
      this.validatePort = this.smtpPort.split("")[0] === "0";
    },
    validate() {
      this.$v.emailValue.$touch();
      this.setError({
        value: false,
        message: ""
      });
    },
    validationEmail() {
      let isValidate = false;
      this.$v.emailValue.$touch();
      this.validationCheckList();

      if (this.isForSent) {
        isValidate = this.validateForSent();
      } else {
        isValidate = !this.$v.emailValue.$error;
      }

      this.setCheckValidateEmail(isValidate);
    },

    validationCheckList() {
      if (!this.contactModule.emails.length) {
        this.updateInMain(true);
      }
    },
    validateForSent() {
      this.$v.smtpServer.$touch();
      this.$v.smtpPort.$touch();
      this.$v.username.$touch();
      this.$v.useForSentPassword.$touch();

      return (
        !this.$v.smtpServer.$error &&
        !this.$v.smtpPort.$error &&
        !this.$v.username.$error &&
        !this.$v.useForSentPassword.$error &&
        !this.validatePort &&
        !this.$v.emailValue.$error
      );
    },
    async save() {
      let isDuplicatedEmail = false
      
      if (this.contactModule.isEditEmail) {
        isDuplicatedEmail = this.contactModule.emails.some(
          (item, index) => item.emailValue === this.emailValue && index !== this.editableEmailDataIndex
        );
      } else {
        isDuplicatedEmail = this.contactModule.emails.some(
          (item) => item.emailValue === this.emailValue
        );
      }
      this.validationEmail()
      this.checkIsMainEmail(this.contactModule.emailForm);

      if (this.contactModule.validateEmail){
        if (isDuplicatedEmail){
          this.setError({
            value: true,
            message: "Duplicate Email Address"
          });

        } else {

          if (this.contactModule.isAddEmail){
            if(this.addProviderModal.providerId){
              const email = new ContactInfoModel()
              await this.addEmail(email.manageEmail(this.contactModule.emailForm, this.addProviderModal.providerId,this.contactModule.isAddEmail)).then(async data=>{
                this.addEmailSave(email.fetchEmail(data));
                await this.updateProviderData()
              })
            }else{
              this.addEmailSave();
            }

          } else {
            if (this.addProviderModal.providerId){
              const email = new ContactInfoModel()
              let x=  email.manageEmail(this.contactModule.emailForm, this.addProviderModal.providerId,this.contactModule.isAddEmail)
              const body = {
                id:this.contactModule.emailForm.id,
                data:x
              }
              await this.manageEmail(body).then(async ()=>{
                this.saveEditEmailForm();
                await this.updateProviderData()
              })
            } else{
              this.saveEditEmailForm();
            }
          }
        }
      }

      const response = await this.getProvider()
      if (response) {
        this.updateContactData(response);
      }
    },
    async updateProviderData() {
      const provider = new AddProviderModel();
      const data = provider.updateData(
        this.companyInfo.companyInfo,
        this.contactModule,
        this.adminPersonalInfoModule.companyAddressesForm,
        this.addProviderModal.providerId
      );
      await this.editProvider(data).then(data => {
        if (data) {
          this.updateData(data);
        }
      });
    },
    updateData(data) {
      this.setProviderId(data.id);
      this.updateContactData(data);
      this.updateCompanyInfo(data);
      this.updatePersonalInfo(data);
    },
    async cancel() {
      if (!this.contactModule.isAddEmail) {
        this.cancelEditEmailForm();
      } else {
        this.addEmailCancel();
      }

      const response = await this.getProvider()
      if (response) {
        this.updateContactData(response);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.content-email-section {
  & .for-sent-cont {
    display: flex;
    width: 68%;
    flex-wrap: wrap;

    & > div {
      width: 50%;
      padding-right: 20px;
    }
  }

  & > :nth-child(1) {
    width: 47%;
  }

  & > :nth-child(3) {
    width: 16%;
  }
}

.content-email-checkboxes {
  display: flex;
  justify-content: space-between;
  width: 63%;
}

.content-email-section::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }
}
</style>
