<template>
  <div>
    <title-contact
      :is-add-icon="true"
      :click="edit"
      text="Balance"
      icon-class="mdi mdi-plus neutral--text"
    />
    <div class="balance-content">
      <title-contact :text="`Total Balance: ${
        typeof balanceCredit.mainBalance === 'number'
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
            }).format(balanceCredit.mainBalance)
          : '$0.00'
      }`" />
      <div class="dept">
        <span>Dept: {{
         typeof balanceCredit.dept === 'number'
          ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(balanceCredit.dept)
          : '$0.00'
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";

export default {
  name: "Balance",
  components: { TitleContact },
  computed: {
    ...mapState({
      balanceManage: state => state.balanceCredit.balanceManage,
      balanceCredit: state => state.balanceCredit
    }),
  },
  methods: {
    ...mapMutations({
      setBalanceManageEdit: "balanceCredit/setBalanceManageEdit"
    }),
    edit() {
      this.setBalanceManageEdit();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.balance-content {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 5px #00000012;
  border-radius: 4px;
  padding: 20px;
  margin-top: -10px;
}
.dept {
  display: inline-flex;
  align-items: center;
  height: 23px;
  background: $danger-color-light 0 0 no-repeat padding-box;
  border-radius: 12px;
  color: white;
  justify-content: center;
  width: auto;
  padding: 0 10px;
  span {
    height: 16px;
    text-align: left;
    font: normal normal 600 12px Segoe UI;
    letter-spacing: 0;
    color: #ffffff;
    opacity: 1;
  }
}

.P-comment {
  font-size: $txt12;
  margin-bottom: 0;
}
.theme--dark{
  .balance-content{
    background-color: $dark_light;
  }
}
</style>
