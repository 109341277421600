var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title-contact',{attrs:{"is-add-icon":true,"click":_vm.edit,"text":"Credit","icon-class":"mdi mdi-pencil-outline neutral--text"}}),_c('div',{staticClass:"content-second"},[_c('title-contact',{attrs:{"text":`Total Credit: ${
      typeof _vm.credit.creditAmount === 'number'
        ? new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
          }).format(_vm.credit.creditAmount)
        : '$0.00'
    }`}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"P-content-box"},[_c('span',{staticClass:"P-title neutral--text"},[_vm._v("Credit Start Date ")]),_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.credit.date ? _vm.credit.date : ''))])]),_c('div',{staticClass:"P-content-box"},[_c('span',{staticClass:"P-title neutral--text"},[_vm._v("Credit Payment Remain Days")]),_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.credit.selectedCreditTerm)+" "+_vm._s(_vm.credit.radioValue==='Day' ? " Day" : " Month")+" ")])]),(_vm.credit.selectedCreditsPauseAfterDays)?_c('div',{staticClass:"P-content-box"},[_c('span',{staticClass:"P-title neutral--text"},[_vm._v("Clients Pause After Days")]),_c('span',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.credit.selectedCreditsPauseAfterDays)+" Day ")])]):_vm._e()]),_c('hr',{staticStyle:{"margin":"15px 0","opacity":"0.45"}}),_c('p',{staticClass:"secondary--text P-comment"},[_vm._v(" "+_vm._s(_vm.credit.comment)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }