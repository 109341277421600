<template>
  <div class="checkeeper">
    <title-contact :is-add-icon="false" text="Checkeeper" />
    <div class="checkeeper-content">
      <ott-input label="API Token" />
      <ott-input label="API Secret Key" />
      <ott-input label="Signature" />
    </div>
    <title-contact :is-add-icon="false" text="Website" />
    <div class="website">
      <span>Checkeeper</span>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttButton from "@/components/vuetifyComponents/OttButton";
import Test55 from "@/assets/svgs/CheckeeperSVG";
import CheckeeperSVG from "@/assets/svgs/CheckeeperSVG";
export default {
  name: "Checkeeper",
  components: { Test55, OttButton, OttInput, TitleContact },
  computed: {
    ...mapState({ checkeeper: state => state.checkeeper })
  }
};
</script>

<style lang="scss" scoped>
.website {
  display: flex;
  background-color: #f6f6f9;
  width: 25%;
  margin-left: 20px;
  & :hover {
  }
}
.checkeeper {
  display: flex;
  flex-direction: column;
  & > .checkeeper-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > :nth-child(-n + 2) {
      width: 48%;
    }
  }
}
</style>
