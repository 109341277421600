<template>
  <div class="P-bank-account">
    <div class="P-full-line G-flex">
      <div class="P-padding-33">
        <OttInput
          v-model="bankName"
          :error="$v.bankTransfer.bankName.$error"
          label="Bank name*"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          v-model="routingNumber"
          :error="$v.bankTransfer.routingNumber.$error"
          label="Routing number*"
          type="number"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          v-model="accountNumber"
          :error="$v.bankTransfer.accountNumber.$error"
          label="Account number*"
        />
      </div>
    </div>
    <v-radio-group v-model="bankTransfer.type">
      <div class="P-full-line G-flex P-radio-btns">
        <div class="P-radio-box ">
          <OttRadio
            v-model="paymentMethod.bankAccountType.personal"
            label="Personal account"
          />
        </div>
        <div class="P-radio-box">
          <OttRadio
            v-model="paymentMethod.bankAccountType.business"
            label="Business account"
          />
        </div>
      </div>
    </v-radio-group>
    <div
      class="P-full-line G-flex"
      v-if="bankTransfer.type === paymentMethod.bankAccountType.personal"
    >
      <div class="P-padding-33">
        <OttInput
          v-model="firstname"
          :error="$v.bankTransfer.firstname.$error"
          label="First name*"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          v-model="lastname"
          :error="$v.bankTransfer.lastname.$error"
          label="Last name*"
        />
      </div>
      <div class="P-padding-33">
        <OttInput
          v-model="nickname"
          :error="$v.bankTransfer.nickname.$error"
          label="Nickname*"
        />
      </div>
    </div>
    <div class="P-full-line G-flex" v-else>
      <div class="P-padding-33">
        <OttInput
          v-model="companyName"
          :error="$v.bankTransfer.companyName.$error"
          label="Company name*"
        />
      </div>
    </div>
    <div class="P-full-line G-flex">
      <div class="P-checkbox">
        <ott-checkbox
          v-model="bankTransfer.default"
          label="Default"
        />
<!--        :disabled="paymentMethod.bankTransfer.inUse ||isMainCheck"-->

      </div>
      <div class="P-checkbox">
        <ott-checkbox
          v-model="bankTransfer.inUse"
          label="in Use"
        />
        <!-- :disabled="paymentMethod.bankTransfer.default || !paymentMethod.paymentList.length" -->
      </div>
    </div>

    <save-reset-buttons
      v-if="paymentList.length"
      :right-label="paymentMethod.editMode ? 'Save' : 'Add'"
      :is-loading="isLoading"
      :right-click-func="() => manageCreditCard()"
      :left-click-func="() => resetData()"
    />

    <div class="G-flex mt-6">
      <ott-button-loader
        v-if="isEditProvider && !paymentList.length"
        @onClick="manageCreditCard()"
        :is-loading="isLoading"
        button-text="Add"
        class-name="primary-sm-btn"
      />
    </div>

    <div class="mt-5">
      <ErrorMessageContainer :message="errorMessage" v-if="error"/>
    </div>
  </div>  
</template>
<script>
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import BankAccountMixin from "./BankAccountMixin";
import ErrorMessageContainer from "@/components/customComponents/ErrorMessageContainer";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "BankAccount",
  components: {ErrorMessageContainer, OttCheckbox, OttRadio, OttInput, SaveResetButtons, OttButtonLoader},
  mixins:[BankAccountMixin]

};
</script>
<style scoped lang="scss">
@import "src/assets/scss/variables";
@import "src/assets/scss/formStyles";

.P-bank-account::v-deep {
  .v-text-field__details {
    margin-bottom: 0;
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 0;
  }

  .P-checkbox {
    .v-input__slot {
      margin-bottom: 0;
    }
  }
  .P-full-line {
    margin: 0 -10px
  }

  .P-radio-btns {
    padding: 0 0 8px;
    margin-left: -4px;
    .P-radio-box {
      margin-right: 10px;
    }
  }
}

.P-bank-account {
  margin-top: 15px;
}
</style>
