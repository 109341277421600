<template>
  <div>
    <title-contact text="Other Api Integrations" />
    <div class="P-merchant-api">
      <div class="P-list-block">
        <v-expansion-panels accordion class="G-accordion" v-model="accordion">
          <v-expansion-panel 
            v-for="(api, index) of apiIntegrationsData" 
            :key="index"
            :disabled="api.disabled"
            :readonly="api.readonly"
          >
            <v-expansion-panel-header
              :disable-icon-rotate="true"
            >
              <div class="G-accordion-header G-flex G-align-center G-justify-between">
                <div class="P-image-block G-flex G-align-center">
                  <div
                    class="P-image"
                    :style="{
                      backgroundImage:
                        `url(${require('@/assets/images/api/' + api.logo)})`,
                    }"
                  ></div>
                  <p class="secondary--text ms-8">{{ api.title }}</p>
                </div>
                
                <template v-if="
                  showError && index !== 2 && 
                  (
                    (!api.disabled && index === 0 && ivrToken) ||
                    (!api.disabled && index === 1 && ringToken) ||
                    (!api.disabled && index === 3 && smartStreetKey) ||
                    (!api.disabled && index === 4 && postalMethodsKey) ||
                    (!api.disabled && index === 5 && apiToken)
                  )
                ">
                  <div v-if="api.isValid == true && !api.disabled" class="G-flex G-align-center me-6">
                    <span class="status me-2 text-uppercase secondary--text">Active</span>
                    <v-icon class="success--text" medium>mdi-checkbox-marked-circle-outline</v-icon>
                  </div>
                  <div v-if="api.isValid == false && !api.disabled" class="G-flex G-align-center me-6">
                    <span class="secondary--text status me-2 text-uppercase">Failed</span>
                    <v-icon class="error--text" medium>mdi-cancel</v-icon>
                  </div>
                </template>
              </div>
              <template v-slot:actions>
                <span class="mdi mdi-plus neutral--text P-accordion-plus" />
                <span class="mdi mdi-minus neutral--text P-accordion-minus" />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-alert
                v-if="
                  showError && index !== 2 &&
                  (
                    (api.isValid == false && !api.disabled && index === 0 && ivrToken) ||
                    (api.isValid == false && !api.disabled && index === 1 && ringToken) ||
                    (api.isValid == false && !api.disabled && index === 3 && smartStreetKey) ||
                    (api.isValid == false && !api.disabled && index === 4 && postalMethodsKey) ||
                    (api.isValid == false && !api.disabled && index === 5 && apiToken)
                  )
                "
                type="error"
              >
                The provided token is invalid
              </v-alert>

              <template v-if="api.title !== 'Checkeeper'">                
                <div v-if="api.flexInputs.length" class="P-full-line G-flex">
                  <div 
                    v-for="(flexInp, flexIndex) of api.flexInputs"
                    :key="flexIndex" 
                    :class="flexInp.class">

                    <component 
                      :is="flexInp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      v-bind="{
                        value: flexInp.value,
                        label: flexInp.label,
                        error: validationSpaces[flexInp.name] || ($v[flexInp.name] !== undefined ? $v[flexInp.name].$error : false)
                      }"
                      @emitFunc="validateSpace(flexInp.name)"
                      @input="changeInputValue($event, flexInp.name)"
                    />
                  </div>
                </div>
                
                <div v-if="api.inputs.length" class="P-full-line">
                  <div 
                    v-for="(inp, inpIndex) of api.inputs" 
                    :key="inpIndex" 
                    :class="inp.class"
                    class="G-flex G-align-center P-input-block"
                  >
                    <div v-if="inp.title" class="P-title">
                      <h4>{{ inp.title }}</h4>
                    </div>
                    <component 
                      :is="inp.type === 'text' ? 'OttInput' : 'PasswordInput'"
                      v-bind="{
                        value: inp.value,
                        label: inp.label,
                        error: validationSpaces[inp.name] || ($v[inp.name] !== undefined ? $v[inp.name].$error : false)
                      }"
                      @emitFunc="validateSpace(inp.name)"
                      @input="changeInputValue($event, inp.name)"
                    />
                  </div>
                </div>
              </template>

              <div v-if="api.title === 'Checkeeper'" class="P-full-line G-flex-wrap">
                <div class="P-padding-50">
                  <ott-input v-model="checkToken" label="Token" />
                </div>
                <div class="P-padding-50">
                  <ott-input v-model="checkSecretKey" label="Secret Key" />
                </div>
                <div class="P-padding-50">
                  <ott-input v-model="checkBankAccount" label="Bank Account" />
                </div>
                <div class="P-padding-50">
                  <ott-input v-model="checkTemplateName" label="Template Name" />
                </div>
                <div class="P-padding-50">
                  <ott-input v-model="checkCompanyName" label="Company Name" />
                </div>
                <div class="P-padding-50">
                  <ott-input 
                    v-model="checkRoutingNumber" 
                    :min="0"
                    label="Routing Number" 
                    type="number"
                  />
                </div>
                <div class="P-padding-50">
                  <ott-select
                    v-model="checkCompanyAddress"
                    :items="companyAddressList"
                    :clear="true"
                    label="Company Address"
                    selected-text="name"
                  />

                </div>
                   <div class="P-padding-50 d-flex flex-column">
                  <VueSignaturePad
                    class="P-signature"
                    height="80px"
                    ref="signaturePad"
                  />

                  <div class="G-align-center mt-3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" @click="clearCheckSignature()" class="cursor-pointer me-2"> mdi-close</v-icon>
                      </template>
                      <span>Clear</span>
                    </v-tooltip>

                    <div
                      v-if="hasValidTwilio"
                      @click="showPhoneError = true"
                      :class="isValidateNumber || validPhoneNumber ? 'G-col_5' : 'G-col_4'"
                      class="position-relative"
                    >
                      <ott-phone-number
                        v-model="checkeeperPhone"
                        :error="(isValidateNumber || validPhoneNumber) && showPhoneError"
                        @onInput="onInputCheckPhoneNumber"
                        label="Phone Number For SMS"
                        placeholder="Phone Number For SMS"
                      />
                      
                      <transition name="fade">
                        <ott-button-loader
                          v-if="!isValidateNumber && !validPhoneNumber"
                          :is-loading="isLoading"        
                          @onClick="signViaMobileFunc"
                          button-text="Send"
                          class-name="P-checkeeper-send"
                        />
                      </transition>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="P-full-line P-buttons G-justify-between G-align-center"
              >
                <div class="P-button-boxes G-align-center">
                  <div class="P-checkbox">
                    <save-reset-buttons
                      :is-loading="isLoading"
                      :right-label="api.rightButtonLabel"
                      :right-click-func="() => api.rightButtonFunc()"
                      :left-click-func="() => api.leftButtonFunc()"
                    />       
                  </div>        
                </div>
                <v-tooltip top color="tooltipBgColor">
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="G-link-api">
                      <a
                        :href="api.link"
                        class="G-align-center G-justify-center"
                        target="_blank"
                      >
                        <img
                          :src="require('@/assets/images/api/' + api.icon)"
                          :titile="api.title + ' icon'"
                          alt="svg"
                        />
                      </a>
                    </div>
                  </template>
                  <span>{{ api.linkTitle }}</span>
                </v-tooltip>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import OtherApiIntegrationMixin from "@/mixins/providers/OtherApiIntegrationMixin";
import TitleContact from "@/components/addProviderModal/contact/components/TitleContact";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttPhoneNumber from "@/components/vuetifyComponents/OttPhoneNumber";
import SaveResetButtons from "@/components/SaveResetButtons";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

export default {
  name: "OtherApiIntegration",
  components: { SaveResetButtons, OttInput, OttSelect, OttPhoneNumber, OttButtonLoader, TitleContact },
  mixins: [ OtherApiIntegrationMixin ],
  computed: {
    ...mapState({
      otherApiIntegration: state => state.otherApiIntegration,
      addProviderModal: state => state.addProviderModal,
      isLoading: state => state.appModule.isLoading,
      authProviderId: state => state.auth.user.provider.id,
      hasValidTwilio: state => state.addProviderModal.hasValidTwilio
    }),
  },
  methods: {
    ...mapActions({
      getOtherApiIntegration: "otherApiIntegration/getOtherApiIntegration",
      updateOtherApiIntegration:
        "otherApiIntegration/updateOtherApiIntegration",
      signViaMobile: 'otherApiIntegration/signViaMobile'
    }),

    ...mapMutations({
      setData: "otherApiIntegration/setData",
      setIvrToken: "otherApiIntegration/setIvrToken",
      setRingToken: "otherApiIntegration/setRingToken",
      setCheckData: "otherApiIntegration/setCheckData",
      setSmartStreet: "otherApiIntegration/setSmartStreet",
      setSmartyStreetAuthId: "otherApiIntegration/setSmartyStreetAuthId",
      setSmartyStreetAuthToken: "otherApiIntegration/setSmartyStreetAuthToken",
      setPostalMethod: "otherApiIntegration/setPostalMethod",
      setTaxJarApiToken: "otherApiIntegration/setTaxJarApiToken",
      toSaveData: "otherApiIntegration/toSaveData",
      saveIvrDataMutation: "otherApiIntegration/saveIvrData",
      saveRingData: "otherApiIntegration/saveRingData",
      saveCheckData: "otherApiIntegration/saveCheckData",
      saveSmartStreetData: "otherApiIntegration/saveSmartStreetData",
      savePostalMethodMutation: "otherApiIntegration/savePostalMethod",
      saveTaxJarData: "otherApiIntegration/saveTaxJarData",
      cancelIvrDataMutation: "otherApiIntegration/cancelIvrData",
      cancelRingData: "otherApiIntegration/cancelRingData",
      cancelCheckData: "otherApiIntegration/cancelCheckData",
      cancelSmartStreetData: "otherApiIntegration/cancelSmartStreetData",
      cancelPostalMethodMutation: "otherApiIntegration/cancelPostalMethod",
      cancelTaxJarMutation: "otherApiIntegration/cancelTaxJar",
      setIsEditIvr: "otherApiIntegration/setIsEditIvr",
      setIsEditRing: "otherApiIntegration/setIsEditRing",
      setIsEditCheck: "otherApiIntegration/setIsEditCheck",
      seIisEditSmartStreet: "otherApiIntegration/seIisEditSmartStreet",
      setIsEditPostalMethods: "otherApiIntegration/setIsEditPostalMethods",
      setIsEditTaxJar: "otherApiIntegration/setIsEditTaxJar",
      fetchData: "otherApiIntegration/fetchData",
      adminSedData: "manageAdminModule/setData",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.other-api-cont {
  .v-expansion-panel {
    margin-bottom: 22px;
  }
}

.P-full-line {
  margin: 0 -10px;
}

.P-padding-50 {
  width: 50%;
  padding: 10px;
}

.P-padding-100 {
  width: 100%;
  padding: 10px;
}

.P-merchant-api::v-deep {
    .P-checkeeper-send {
      position: absolute;
      top: 5.5px;
      right: 5px;
      
    & button {
      background-color: lightblue;
      color: white;
      text-transform: capitalize;
      box-shadow: unset;
      font-size: $txt16;
      height: 29px;
    }
  }

  .v-input--dense>.v-input__control>.v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field__details {
  }

  .v-messages,
  .v-text-field__details {
    display: none;
  }

  .v-input--radio-group {
    margin-top: 0;
  }

  .v-input--switch {
    margin-top: 10px;
  }
}
.P-checkbox {
  padding: 10px;
  display: inline-block;
}

.P-signature {
  border: 1px solid $neutral-color;
  border-radius: 4px;
}

::v-deep {
  .P-save-and-reset-block {
    margin-top: 0;
  }
}

.P-input-block {
  .P-title {
    width: 16.5%;
  }

  .ott-input-cont {
    width: 50%;
  }
}
</style>
