import { mapState, mapActions, mapMutations } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import allCountry from "@/constants/all-countries";

export default {
  data() {
    return {
      validationPhoneNumber: false,
      isValidateNumber: false,
      isMainCheck: false,
      countryList: allCountry
    };
  },

  validations: {
    formData: {
      phoneNumber: {required},
      officeName: {required},
      address: {required},
      country: {required},
      city: {required},
      zip: {required},
      companyName: {
        required: requiredIf(function () {
          return (
            this.companyAddresses.addressType ===
            this.companyAddresses.addressTypeList.company
          );
        })
      },
      firstname: {
        required: requiredIf(function () {
          return (
            this.companyAddresses.addressType ===
            this.companyAddresses.addressTypeList.personal
          );
        })
      },
      lastname: {
        required: requiredIf(function () {
          return (
            this.companyAddresses.addressType ===
            this.companyAddresses.addressTypeList.personal
          );
        })
      }
    }
  },
  
  computed: {
    ...mapState({
      companyAddresses: state => state.companyAddresses,
      cloneFormData: state => state.companyAddresses.cloneFormData,
      formData: state => state.companyAddresses.formData,
      addressesList: state => state.companyAddresses.addressesList,
      isEditMode: state => state.companyAddresses.isEditMode,
      userData: state => state.auth.user,
      companyInfo: state => state.companyInfo,
      contactModule: state => state.contactModule,
      adminPersonalInfoModule: state => state.adminPersonalInfoModule,
      addProviderModal: state => state.addProviderModal,
      isEditProvider: state => state.providers.isEditProvider,
      isLoading: state => state.appModule.isLoading
    }),

    isExistShippingAddress() {
      return this.addressesList.some((item) => item.isForShipping)
    },

    isExistBillingAddress() {
      return this.addressesList.some((item) => item.isBilling)
    },

    companyName: {
      get() {
        return this.companyAddresses.formData.companyName;
      },
      set(value) {
        this.setCompanyName(value);
      }
    },
    firstname: {
      get() {
        return this.companyAddresses.formData.firstname;
      },
      set(value) {
        this.setFirstName(value);
      }
    },
    lastname: {
      get() {
        return this.companyAddresses.formData.lastname;
      },
      set(value) {
        this.setLastName(value);
      }
    },
    addressType: {
      get() {
        return this.companyAddresses.addressType;
      },
      set(value) {
        this.setAddressType(value);
      }
    },
    phoneNumber: {
      get() {
        return this.companyAddresses.formData.phoneNumber;
      },
      set(value) {
        this.setPhoneNumber(value);
      }
    },
    officeName: {
      get() {
        return this.companyAddresses.formData.officeName;
      },
      set(value) {
        this.setOfficeName(value);
      }
    },
    address: {
      get() {
        return this.companyAddresses.formData.address;
      },
      set(value) {
        this.setAddressName(value);
      }
    },
    country: {
      get() {
        return this.companyAddresses.formData.country;
      },
      set(value) {
        this.setCountry(value);
      }
    },
    city: {
      get() {
        return this.companyAddresses.formData.city;
      },
      set(value) {
        this.setCity(value);
      }
    },
    state: {
      get() {
        return this.companyAddresses.formData.state;
      },
      set(value) {
        this.setState(value);
      }
    },
    unit: {
      get() {
        return this.companyAddresses.formData.unit;
      },
      set(value) {
        this.setUnit(value);
      }
    },
    zip: {
      get() {
        return this.companyAddresses.formData.zip;
      },
      set(value) {
        this.setZip(value);
      }
    },
    isForShipping: {
      get() {
        return this.companyAddresses.formData.isForShipping;
      },
      set(value) {
        this.setIsForShipping(value);
      }
    },
    isBilling: {
      get() {
        return this.companyAddresses.formData.isBilling;
      },
      set(value) {
        this.setIsBilling(value);
      }
    },
    inUse: {
      get() {
        return this.companyAddresses.formData.inUse;
      },
      set(value) {
        this.setInUse(value);
      }
    },
    isMain: {
      get() {
        return this.companyAddresses.formData.inUse;
      },
      set(value) {
        this.setIsMain(value);
      }
    },
    forInvoice: {
      get() {
        return this.companyAddresses.formData.forInvoice;
      },
      set(value) {
        this.setForInvoice(value);
      }
    },
    isWarehouse: {
      get() {
        return this.companyAddresses.formData.isWarehouse;
      },
      set(value) {
        this.setIsWarehouse(value);
      }
    },
    acceptSelfPickup: {
      get() {
        return this.companyAddresses.formData.acceptSelfPickup;
      },
      set(value) {
        this.setAcceptSelfPickup(value);
      }
    },
    acceptCurrierPickup: {
      get() {
        return this.companyAddresses.formData.acceptCurrierPickup;
      },
      set(value) {
        this.setAcceptCourierPickup(value);
      }
    }
  },

  mounted() {
    this.getCountryCode();
    this.getSomeData();
    this.isMainCheck = this.getCurrentData()
  },

  methods: {
    ...mapActions({
      manageCompanyAddress: 'companyAddresses/manageCompanyAddress',
      getCompanyAddresses: 'companyAddresses/getCompanyAddresses',
      addCompanyAddress: 'companyAddresses/addCompanyAddress',
    }),

    ...mapMutations({
      setCompanyName: 'companyAddresses/setCompanyName',
      setFirstName: 'companyAddresses/setFirstName',
      setLastName: 'companyAddresses/setLastName',
      setAddressType: 'companyAddresses/setAddressType',
      setPhoneNumber: 'companyAddresses/setPhoneNumber',
      setOfficeName: 'companyAddresses/setOfficeName',
      setAddressName: 'companyAddresses/setAddressName',
      setCountry: 'companyAddresses/setCountry',
      setCity: 'companyAddresses/setCity',
      setState: 'companyAddresses/setState',
      setUnit: 'companyAddresses/setUnit',
      setZip: 'companyAddresses/setZip',
      setIsForShipping: 'companyAddresses/setIsForShipping',
      setIsBilling: 'companyAddresses/setIsBilling',
      setInUse: 'companyAddresses/setInUse',
      setIsMain: 'companyAddresses/setIsMain',
      setForInvoice: 'companyAddresses/setForInvoice',
      setIsWarehouse: 'companyAddresses/setIsWarehouse',
      setAcceptSelfPickup: 'companyAddresses/setAcceptSelfPickup',
      setAcceptCourierPickup: 'companyAddresses/setAcceptCourierPickup',
      setCountryCode: 'companyAddresses/setCountryCode',
      setError: 'companyAddresses/setError',
      addNewAddress: 'companyAddresses/addNewAddress',
      updateAddressList: 'companyAddresses/updateAddressList',
      resetEditedAddress: 'companyAddresses/resetEditedAddress',
      closeAddAddress: 'companyAddresses/closeAddAddress',
      setAddressFullName: 'companyAddresses/setAddressFullName',
    }),
    /**
     * get current  data for check inMain
     * **/
    getCurrentData() {
      let currentIndex = false
      if (!this.isAddMode && this.companyAddresses.addressesList) {
        this.companyAddresses.addressesList.forEach((item, index) => {
          if (item.id === this.formData.id) {
            currentIndex = item.inMain
          }
        })
      }
      return currentIndex
    },


    updateCountryData() {
    },

    /**
     * Validate Phone number
     * **/

    validatePhoneNumber() {
      const [phone, obj] = arguments;
      this.setCountryCode(obj.regionCode);
      this.validationPhoneNumber = !obj.isValid;
      this.$v.formData["phoneNumber"].$touch();
      let str = obj.number.input.replace(/[^A-Z0-9]/ig, '')
      this.isValidateNumber = !Number(str)
    },

    /**
     * Update address autocomplete SmartStreet
     * **/

    updateAddress() {
      const [addressData] = arguments;
      this.setCity(addressData.city);
      this.setState(addressData.province);
      this.setUnit(addressData.suite);
      this.setZip(addressData.zipCode);

      let countryValue = allCountry.filter(x => x.name === addressData.country)[0].value
      this.setCountry(countryValue)
      this.setAddressName(addressData.fullAddress);
    },

    saveAddress() {
      const [address] = arguments;
      this.setAddressName(address);

    },

    /**
     * Add new Address
     * **/

    async addAddress() {
      this.$v.formData.$touch();
      if (!this.$v.formData.$error && !this.validationPhoneNumber && !this.isValidateNumber) {
        this.isMainChecked();

        const body = {
          data: this.companyAddresses.formData,
          providerId: this.addProviderModal.providerId
        }

        // console.log(body.data, 'body')
        if (this.duplicateCompanyAddresses()) {
          this.setError({error: true, errorMessage: 'Duplicate Company Address'})
        } else {
          this.setError({error: false, errorMessage: ''})

          if (this.companyAddresses.isEditMode) {
            // this.$store.commit("companyAddresses/saveEditData", this.formData);
            // this.closeAddAddress();
            body.isEditMode = true
            this.addNewAddress(body)
            await this.manageCompanyAddress(this.companyAddresses.formData.id).then(async data => {
              await this.getCompanyAddresses(this.addProviderModal.providerId).then(data => {
                this.updateAddressList(data)
              })
            })

          } else {
            body.isEditMode = false
            this.addNewAddress(body)
            await this.addCompanyAddress().then(async data => {
              await this.getCompanyAddresses(this.addProviderModal.providerId).then(data => {
                this.updateAddressList(data)
              })
            })
          }
        }
      }
    },

    /**
     * Reset Data  and Cancel edit or add new address
     * **/

    resetAddress() {
      this.setError({error: false, errorMessage: ''})

      if (this.companyAddresses.isEditMode) {
        this.resetEditedAddress();
      } else {
        this.closeAddAddress();
      }
      this.$refs['usAddress'].resetAddress()
    },

    cancel() {
      this.setError({error: false, errorMessage: ''})
      this.closeAddAddress();
    },

    /**
     * Get country code for phone number
     * **/

    getCountryCode() {
      if (!this.formData.phoneNumber) {
        allCountry.forEach(item => {
          if (item.iso2 === this.userData?.geoInfo?.countryCode?.toUpperCase()) {
            this.setPhoneNumber("+" + item.dialCode);
          }
        });
      }
    },

    /**
     * Get company name, first name anf last  name data
     * **/

    getSomeData() {
      if (
        this.companyInfo.companyInfo.companyName &&
        !this.companyAddresses.isEditMode
      ) {
        this.setCompanyName(this.companyInfo.companyInfo.companyName);
      }
      if (this.contactModule.phones.length &&
        !this.companyAddresses.isEditMode
      ) {
        this.setPhoneNumber(this.contactModule.phones[0].phone);
      }
      if (this.adminPersonalInfoModule.companyAddressesForm.firstName &&
        !this.companyAddresses.isEditMode
      ) {
        this.setFirstName(this.adminPersonalInfoModule.companyAddressesForm.firstName);
      }
      if (this.adminPersonalInfoModule.companyAddressesForm.lastName &&
        !this.companyAddresses.isEditMode
      ) {
        this.setLastName(this.adminPersonalInfoModule.companyAddressesForm.lastName);
      }
    },

    /**
     * Check is main for address
     * **/

    isMainChecked() {
      if (!this.addressesList.length) {
        this.setIsMain(true);
      }
      if (this.cloneFormData && this.cloneFormData.isMain) {
        this.setIsMain(true);
      }
    },

    /**
     * duplicate company addresses
     * **/

    duplicateCompanyAddresses() {
      let isDuplicate = false
      if (this.addressesList.length) {
        return this.addressesList.some((x, index) => {
          let sum = 0
          if (this.companyAddresses.addressType === this.companyAddresses.addressTypeList.company) {
            if (x.companyName === this.companyName) {
              sum++
            }
          } else {
            if (x.firstname === this.firstname && x.lastname === this.lastname) {
              sum++
            }
          }
          if (x.officeName === this.officeName) {
            sum++
          }

          if (x.phoneNumber === this.phoneNumber) {
            sum++
          }
          if (x.address === this.address) {
            sum++
          }
          if (x.country === this.country) {
            sum++
          }
          if (x.city === this.city) {
            sum++
          }
          if (x.state === this.state) {
            sum++
          }
          if (x.unit === this.unit) {
            sum++
          }
          if (x.zip === this.zip) {
            sum++
          }

          if (sum === 9) {
            if (this.companyAddresses.editedIndex || this.companyAddresses.editedIndex === 0) {
              if (index !== this.companyAddresses.editedIndex) {
                return isDuplicate = true
              }
            } else {
              return isDuplicate = true
            }
          } else {
            return isDuplicate = false
          }
        })
      }
    },

    getAddressData(addressData, placeResultData, id) {
      this.address = addressData;
    }
  },
};
