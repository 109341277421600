  <template>
  <div class="P-label-printers-list">
    <div class="P-label-list">
      <div class="P-table-header">
        <ul class="G-flex ">
          <li class="secondary--text text-center">Office/Address</li>
          <li class="secondary--text text-center justify-center">Ip Address</li>
          <li class="secondary--text text-center justify-center">Port</li>
          <li class="secondary--text text-center justify-center">Printer model</li>
          <li class="secondary--text text-center justify-center">Pages per sheet</li>
          <li class="secondary--text text-center justify-center">Label printer</li>
          <li class="secondary--text text-center justify-center">Receipt printer</li>
          <li></li>
        </ul>
      </div>
      <div class="P-table-body">
        <ul v-for="(items, index) of labelPrintersList" :key="index" class="G-flex ">
          <li class="secondary--text justify-start">
            <p v-if="items.address" class="secondary--text" v-html="getAddressName(items.address)" />
            <p v-else>-</p>
          </li>
          <li class="secondary--text justify-center">{{ items.ipAddress }}</li>
          <li class="secondary--text justify-center">{{ items.port }}</li>
          <li class="secondary--text justify-center">{{ items.printerModel }}</li>
          <li class="secondary--text justify-center">{{ items?.pagesPerSheet }}</li>
          <li class="justify-center">
            <span
              v-if="items.isReceiptPrinter === printerType.isShippingLabel"
              class="mdi mdi-check checkTable"
            />
          </li>
          <li class="justify-center">
            <span
              v-if="items.isReceiptPrinter === printerType.isReceiptPrinter"
              class="mdi mdi-check checkTable"
            />
          </li>
          <li class="G-align-center">
            <div class="P-actions">
              <v-menu left content-class="P-actions-list">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon :class="{'white--text': $vuetify.theme.dark, 'neutral--text': !$vuetify.theme.dark}">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item class="P-list" @click="editLabel(items, index)">
                    <v-list-item-title>Edit</v-list-item-title>
                  </v-list-item>
                  <v-list-item class="P-list" @click="openWarningModal(items.id)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <WarningModal 
      v-if="showWarningModal"
      :isModalOpen="showWarningModal"
      :modalHeading="warningModalHeading" 
      :modalText="warningModalText"  
      :isDangerButton="warningModalIsDangerButton"
      :isLoading="warningModalIsLoading"
      @closeModal="showWarningModal = false"  
      @btnFunction="deleteLabelFunc()" 
      btnText="Delete"
    />
  </div>
</template>
<script>
  import { mapState, mapActions, mapMutations } from "vuex";
  import WarningModal from "@/components/WarningModal";

  export default {
    name: "LabelPrintersList",
    components: {
      WarningModal
    },
    data() {
      return {
        showWarningModal: false,
        warningModalIsDangerButton: true,
        deletedLabelId: null,
        warningModalIsLoading: false,
      }
    },
    created() {
      this.getLabelPrinterList(this.addProviderModal.providerId)
    },
    computed: {
      ...mapState({
        isEditMode: state => state.labelPrinters.isEditMode,
        labelPrintersList: state => state.labelPrinters.labelPrintersList,
        printerType: state => state.labelPrinters.printerType,
        companyAddresses: state => state.companyAddresses,
        addProviderModal: state => state.addProviderModal,
      }),

      warningModalHeading() {
        return `Delete provider's label?`
      },

      warningModalText() {
        return `You're about to <ins>delete</ins> provider's label. If you're not sure, you can cancel this operation.`
      },
    },
    methods: {
      ...mapActions({
        deleteLabelPrinterList : 'labelPrinters/deleteLabelPrinterList',
        getLabelPrinterList : 'labelPrinters/getLabelPrinterList'
      }),
      ...mapMutations({
        editLabelData: 'labelPrinters/editLabelData'
      }),
      openWarningModal(deletedLabelId) {
        this.showWarningModal = true;
        this.warningModalIsLoading = false;   
        this.deletedLabelId = deletedLabelId;
      },

      async deleteLabelFunc() {
        this.warningModalIsLoading = true;
        await this.deleteLabel().finally(() => {
          this.warningModalIsLoading = false;
        });
        this.showWarningModal = false;
      },

      async deleteLabel() {
        if (this.deletedLabelId) {
          await this.deleteLabelPrinterList(this.deletedLabelId).then(async () => {
            await this.getLabelPrinterList(this.addProviderModal.providerId)
          })
        }
      },
      editLabel(data, index) {
        const body = {
          data,
          index
        };
        this.editLabelData(body);
      },
      /**
       * get address name
       * **/
    getAddressName(addressId) {
      let address = " - ";
      if (this.companyAddresses.fullAddressList.length) {
        this.companyAddresses.fullAddressList.forEach(item => {
          if (item.value.id === addressId) {
            address = this.FormatProviderAddress(item.value);
          }
        });
      }
      return address;
    }
    },

  };
</script>

<style lang="scss" scoped>
  @import "src/assets/scss/variables";

  .P-table-header {
    ul {
      li {
        font-size      : $txt12;
        font-weight    : bold;
        padding        : 3px 17px;
        display        : flex;
        align-items    : center;
        text-transform : capitalize;
      }
    }
  }

  .P-table-body {
    ul {
      border-top : 1px solid $neutral-color45;

      li {
        font-size       : $txt14;
        word-break      : break-all;
        padding         : 10px 17px;
        transition      : $transition;
        display         : flex;
        align-items     : center;
        justify-content : center;

        & + li {
          border-left : 1px solid $neutral-color45;
        }

        p {
          font-size  : $txt14;
          word-break : break-all;
        }
      }

      // &:hover {
      //   li {
      //     background-color : $neutral-color7;
      //   }

      //   .P-actions {
      //     opacity    : 1;
      //     visibility : visible;
      //   }
      // }
    }
  } 

.P-label-list {
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid $neutral-color45;

  ul {
    padding: 0;
    li {
      max-width: 100%;
      width: 100%;
      & + li {
        border-left: 1px solid $neutral-color45;
      }
    }
  }

  li {
    &:nth-child(8) {
      max-width: 30px;
      min-width: 30px;
      padding: 3px 0;
      border-left: 1px solid transparent;
    }
    &:nth-child(5), &:nth-child(6), &:nth-child(7) {
      max-width: 60px;
    }
    &:nth-child(4) {
      max-width: 150px;
      min-width: 150px;
    }
    &:nth-child(3) {
      max-width: 80px;
      min-width: 80px;
    }
    &:nth-child(2) {
      max-width: 135px;
      min-width: 135px;
    }
  }

  .checkTable {
    color     : $green;
    font-size : $txt18;
  }

  .P-actions {
    opacity    : 0;
    visibility : hidden;
    transition : $transition;
  }
}
</style>
