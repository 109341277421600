<template>
  <ott-dialog
    v-if="addProviderModal.modal.isOpen"
    :is-open="addProviderModal.modal.isOpen"
    :show-footer="false"
    :width="1120"
    :modal-title="
      addProviderModal.isAddReseller
        ? 'Add Reseller'
        : isEditProvider
        ? 'Edit Provider'
        : 'Add Provider'
    "
    @outside="closeModal"
    @resetDataModal="resetDataModal"
    close-value="addProvider"
  >
    <template v-slot:modalBody>
      <div class="parent-add-provider-modal">
        <div class="left P-background-neutral7 G-flex-column G-justify-between">
          <div>
            <div class="status">
              <span class="P-status-title">Parent Provider</span>
              <div class="status-second">
                <div class="text">
                  <span>{{ getProviderName }}</span>
                  <!--                  <span @click="click">dark</span>-->
                </div>
              </div>
            </div>
            <ul class="list">
              <li
                v-if="addProviderModal.providerId && isEditProvider"
                @click="clickList(-1)"
                :class="{
                  listActive: -1 === addProviderModal.activeTab,
                }"
              >
                General info
              </li>
              <li
                v-for="(i, index) in addProviderModal.showList"
                @click="clickList(index)"
                :key="index"
                :class="{
                  listActive: index === addProviderModal.activeTab,
                  'P-disable-tab': index > addProviderModal.disableTabs,
                }"
              >
                {{ i.name }}
              </li>
            </ul>
          </div>
          <ott-button
            v-if="!addProviderModal.providerId || !isEditProvider"
            :disabled="disableButton"
            :click="saveAndClose"
            style="width: 179px; height: 33px"
            custom-class="primary-md-btn"
            text="Save And Close"
          />
        </div>
        <div class="right">
          <div class="main">
            <general-info
              v-if="
                activeTab === -1 &&
                addProviderModal.providerId &&
                isEditProvider &&
                permissionIs('getOttProvider')
              "
            />
            <company-info
              ref="companyInfo"
              :updateProvider="updateProviderData"
              v-if="activeTab === 0"
            />
            <contact-section
              ref="contactSection"
              v-if="
                activeTab === 1 &&
                (permissionIs('getOttProviderEmails') ||
                  permissionIs('getOttProviderPhones'))
              "
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <admin-personal-info
              ref="companyPersonalInfo"
              :updateProvider="updateProviderData"
              v-if="activeTab === 2"
            />
            <company-addresses
              ref="companyAddresses"
              v-if="activeTab === 3 && permissionIs('getOttProviderAddresses')"
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <contact-info-for-clients-apps
              ref="contactInfoForClientsApps"
              v-if="activeTab === 4 && permissionIs('getOttProviderInfo')"
            />
            <permissions
              v-if="activeTab === 5 && permissionIs('getOttProviderPermission')"
            />
            <balance-credit
              ref="balanceCredit"
              v-if="
                activeTab === 6 && permissionIs('getOttProviderBalanceCredit')
              "
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <payment-methods
              ref="paymentMethods"
              v-if="activeTab === 7 && permissionIs('getOttProviderPaymentMethods')"
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <invoice-generation
              ref="invoiceGeneration"
              v-if="activeTab === 8 && permissionIs('getOttProviderInvoice')"
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <label-printers
              ref="labelPrinters"
              v-if="activeTab === 9 && permissionIs('getOttProviderPrinters')"
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <sale-taxes
              ref="saleTexes"
              v-if="activeTab === 10 && permissionIs('getOttProviderSalesTax')"
              @showSaveBtnFunc="showSaveBtnFunc"
            />
            <merchants-list
              ref="paymentGateway"
              v-if="activeTab === 11 && permissionIs('getOttProviderPaymentGateway')"
            />
            <shipping-providers
              ref="shippingProviders"
              v-if="activeTab === 12 &&
                permissionIs('getOttProviderShippingProvider')"
            />
            <other-api-integration
              v-if="activeTab === 13 && permissionIs('getOttProviderOtherApi')"
            />
            <conversation-api v-if="activeTab === 14" :id="addProviderModal.providerId" />

            <ui-and-access
              ref="uiAndAccess"
              v-if="activeTab === 15 && permissionIs('getOttProviderUi')"
            />

            <Transition name="fade">
              <v-alert
                v-if="isSaved"
                dense
                text
                class="mt-5"
                type="success"
                style="width: max-content"
              >
                The request has been saved successfully
              </v-alert>
            </Transition>
          </div>
          <div
            class="footer"
            v-if="
              (!addProviderModal.providerId || !isEditProvider) &&
              showSaveButton
            "
          >
            <ott-button
              :click="nextFunc"
              :text="getButtonText"
              :icon-after="activeTab !== 15"
              custom-class="primary-sm-btn"
            />
          </div>

          <div v-if="isEditProvider" class="footer">
            <ott-button
              custom-class="neutral-sm-btn mr-0"
              text="Exit"
              :click="closeModal"
            />
          </div>
        </div>
        <create-provider-modal
          v-if="addProviderModal.createProviderModal.isOpen"
          :errorMessage="errorMessage"
          :isLoading="isLoading"
          @closeModal="closeModal"
          @createProvider="createProvider"
        />
      </div>
    </template>
  </ott-dialog>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { AddProviderModel } from "@/models/providers/addProviderModel";
import PermissionsMixin from "@/mixins/PermissionsMixin";
import OttDialog from "@/components/vuetifyComponents/OttDialog";
import OttButton from "@/components/vuetifyComponents/OttButton";
import CompanyInfo from "@/components/addProviderModal/contact/CompanyInfo";
import ContactSection from "@/components/addProviderModal/contact/ContactSection";
import BalanceCredit from "@/components/addProviderModal/balanceCredit/BalanceCredit";
import UiAndAccess from "@/components/addProviderModal/uiAndAccess/UiAndAccess";
import ContactInfoForClientsApps from "@/components/addProviderModal/contactInfoForClientsApps/ContactInfoForClientsApps";
import Checkeeper from "@/components/addProviderModal/checkeeper/Checkeeper";
import AdminPersonalInfo from "./adminPersonalInfo/AdminPersonalInfo";
import CompanyAddresses from "./companyAddresses/CompanyAddreses";
import PaymentMethods from "./paymentMethods/PymentMethods";
import MerchantsList from "./merchantsList/MerchantsList";
import ShippingProviders from "./shippingProviders/ShippingProviders";
import SaleTaxes from "@/components/addProviderModal/saleTaxes/SaleTaxes";
import OtherApiIntegration from "@/components/addProviderModal/otherApiIntegration/OtherApiIntegration";
import LabelPrinters from "./label-printers/label-printers";
import Permissions from "./permissions/Permissions";
import InvoiceGeneration from "./invoiceGeneration/InvoiceGeneration";
import GeneralInfo from "./generalInfo/GeneralInfo";
import CreateProviderModal from "@/views/providers/createProviderModal";
import ConversationApi from "../sidebar/components/manageAdmin/conversationApi/ConversationApi.vue";

  
export default {
  name: "AddProviderModal",
  mixins: [ PermissionsMixin ],
  components: {
    CreateProviderModal,
    GeneralInfo,
    InvoiceGeneration,
    Permissions,
    LabelPrinters,
    SaleTaxes,
    ShippingProviders,
    OtherApiIntegration,
    MerchantsList,
    PaymentMethods,
    Checkeeper,
    ContactInfoForClientsApps,
    UiAndAccess,
    CompanyAddresses,
    BalanceCredit,
    ContactSection,
    AdminPersonalInfo,
    CompanyInfo,
    OttButton,
    OttDialog,
    ConversationApi
  },

  data() {
    return {
      isLoading: false,
      showSaveButton: true,
      onlyContinue: false,
      errorMessage: ''
    };
  },

  watch: {
    activeTab(data) {
      if (data >= 3) {
        this.disableSaveCloseButton();
      }
    },
  },
  
  computed: {
    ...mapState({
      activeTab: (state) => state.addProviderModal.activeTab,
      isEditProvider: (state) => state.providers.isEditProvider,
      addProviderModal: (state) => state.addProviderModal,
      companyInfo: (state) => state.companyInfo,
      contactModule: (state) => state.contactModule,
      adminPersonalInfoModule: (state) => state.adminPersonalInfoModule,
      isValidatePersonalInfo: (state) => state.adminPersonalInfoModule.isValidatePersonalInfo,
      emailsList: (state) => state.contactModule.emails,
      phoneList: (state) => state.contactModule.phones,
      disableButton: (state) => state.addProviderModal.disableButton,
      auth: (state) => state.auth,
      addressesList: (state) => state.companyAddresses.addressesList,
      isSaved: (state) => state.appModule.isSaved
    }),

    getButtonText() {
      let textButton = "Continue";
      if (this.activeTab === 2 || this.addProviderModal.providerId) {
        textButton = "Save and Continue";
      }
      if (this.activeTab === 15) {
        textButton = "Done";
      }
      return textButton;
    },

    getProviderName() {
      if (
        !this.addProviderModal.providerId &&
        !this.addProviderModal.currentProviderId
      ) {
        return this.auth.user.firstname + " " + this.auth.user.lastname;
      }
      if (
        this.addProviderModal.selectedProvider &&
        !this.addProviderModal.currentProviderId
      ) {
        return this.addProviderModal.selectedProvider.registerBy && this.addProviderModal.selectedProvider.registerBy.length ? (
          this.addProviderModal.selectedProvider.registerBy[0].firstname +
          " " +
          this.addProviderModal.selectedProvider.registerBy[0].lastname
        ) : '-';
      }
      if (
        this.addProviderModal.currentProviderId &&
        this.addProviderModal.selectedProvider
      ) {
        return (
          this.addProviderModal.selectedProvider.user.firstname +
          " " +
          this.addProviderModal.selectedProvider.user.lastname
        );
      }
    },
  },

  async mounted() {
    if (this.addProviderModal.providerId) {
      await this.getProvider().then(async (data) => {
        if (data) {
          this.updateData(data);
          await this.getCompanyAddresses(this.addProviderModal.providerId)
            .then((addressData) => {
              this.updateAddressList(addressData);
            });
          this.disableSaveCloseButton();
          this.clickActiveTab(-1);
        }
      });
    } else {
      this.clickActiveTab(0);
    }
  },

  beforeDestroy() {
    this.getProvidersList(this.addProviderModal.filter);
  },

  destroyed() {
    this.$emit('resetSelectedList')
  },

  methods: {
    ...mapActions({
      getProvider: 'addProviderModal/getProvider',
      getCompanyAddresses: 'companyAddresses/getCompanyAddresses',
      createProviderAction: 'addProviderModal/createProvider',
      getProvidersList: 'addProviderModal/getProvidersList',
      editProvider: 'addProviderModal/editProvider',
    }),

    ...mapMutations({
      setData: 'addProviderModal/setData',
      companyInfoResetState: 'companyInfo/resetState',
      disableSaveCloseButton: 'addProviderModal/disableSaveCloseButton',
      nextTab: 'addProviderModal/nextTab',
      closeCreateModal: 'addProviderModal/closeCreateModal',
      setFilterPage: 'addProviderModal/setFilterPage',
      setProviderId: 'addProviderModal/setProviderId',
      setProviderData: 'addProviderModal/setProviderData',
      clickActiveTab: 'addProviderModal/clickActiveTab',
      closeProviderModal: 'addProviderModal/closeModal',
      openCreateModal: 'addProviderModal/openCreateModal',
      resetActiveTabs: 'addProviderModal/resetActiveTabs',
      updateContactData: 'contactModule/updateContactData',
      resetContactForm: 'contactModule/resetContactForm',
      updateCompanyInfo: 'companyInfo/updateCompanyInfo',
      resetDataCompanyInfo: 'companyInfo/resetDataCompanyInfo',
      updatePersonalInfo: 'adminPersonalInfoModule/updatePersonalInfo',
      adminPersonalInfoReset: 'adminPersonalInfoModule/resetData',
      setPassword: 'adminPersonalInfoModule/setPassword',
      setConfirmPassword:  'adminPersonalInfoModule/setConfirmPassword',
      companyAddressesReset: 'companyAddresses/resetAllData',
      updateAddressList: 'companyAddresses/updateAddressList',
      paymentMethodsReset: 'paymentMethods/resetData',
      balanceCreditReset: 'balanceCredit/resetData',
      merchantReset: 'merchant/resetData',
      shipmentProvidersReset: 'shipmentProviders/resetData',
      uiAndAccessReset: 'uiAndAccess/resetData',
      contactInfoForClientsReset: 'contactInfoForClientsApps/resetData',
      saleTaxesResetData: 'saleTaxes/resetData',
      saleTaxesResetDataClose: 'saleTaxes/resetDataClose',
      otherApiIntegrationReset: 'otherApiIntegration/resetData',
      labelPrintersReset: 'labelPrinters/resetDataClose',
      permissionsReset: 'permissions/resetPermissions',
      invoiceReset: 'invoice/resetAllData',
      isEditEmail: 'contactModule/isEditEmail',
      isEditPhone: 'contactModule/isEditPhone',
      setCompanyAddressIsEdit: 'companyAddresses/setCompanyAddressIsEdit',
      setCompanyAddressIsAdd: 'companyAddresses/setCompanyAddressIsAdd',
      setPaymentMethodIsAdd: 'paymentMethods/setPaymentMethodIsAdd',
      setPaymentMethodIsEdit: 'paymentMethods/setPaymentMethodIsEdit',
      setIsAddReseller: 'addProviderModal/setIsAddReseller',
    }),

    showSaveBtnFunc(e) {
      if (!e.showSaveButton) {
        this.showSaveButton = e.showSaveButton
      }else {
        this.showSaveButton = true
      }

      if (e.onlyContinue !== undefined) {
        this.onlyContinue = e.onlyContinue
      }else {
        this.onlyContinue = false
      }
    },

    click() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    async createProvider() {
      const provider = new AddProviderModel();
      const data = provider.saveData(
        this.companyInfo.companyInfo,
        this.contactModule,
        this.adminPersonalInfoModule.companyAddressesForm
      );

      data.parentId = this.addProviderModal.currentProviderId
        ? this.addProviderModal.currentProviderId
        : this.auth.user.provider.id;

      if (!this.addProviderModal.providerId) {
        try {
          await this.createProviderAction(data).then(
            async (data) => {
              if (data) {
                this.updateData(data);
                this.nextTab();
                this.closeCreateModal();
                this.setFilterPage(1);
                await this.getProvidersList(this.addProviderModal.filter);
                this.$emit('resetSelectedList')
              }
          });
        }  catch(e) {
          this.errorMessage = e.response.data.message
        }

        // after created provider, remove typed passwords
        this.setPassword(null)
        this.setConfirmPassword(null)
      } else {
        this.nextTab();
      }
    },
    async updateProviderData() {
      const provider = new AddProviderModel();
      const data = provider.updateData(
        this.companyInfo.companyInfo,
        this.contactModule,
        this.adminPersonalInfoModule.companyAddressesForm,
        this.addProviderModal.providerId
      );
      await this.editProvider(data).then((data) => {
        data ? this.updateData(data) : '';
      })
    },

    updateData(data) {
      this.setProviderId(data.id);
      this.setProviderData(data);
      this.updateContactData(data);
      this.updateCompanyInfo(data);
      this.updatePersonalInfo(data);
    },

    async nextFunc() {
      switch (this.activeTab) {
        case 0: 
          if (
            this.$refs["companyInfo"] &&
            !this.$refs["companyInfo"].validationForm()
          ) {        
            this.nextTab();
          }
        break;
        
        case 1: 
          if (!this.addProviderModal.providerId) {
            if (!this.onlyContinue) {
              this.$refs["contactSection"].$refs["contactForm"].save(false)
              this.$refs["contactSection"].$refs["contactForm"].$refs["phoneNumberForm"].showPhoneError = true
            }else {
              this.nextTab();
            }
          }else {
            this.nextTab();
          }
        break;
        
        case 2:
          this.$refs["companyPersonalInfo"] &&
            await this.$refs["companyPersonalInfo"].validationForm().then(async (data) => {
              if (this.isValidatePersonalInfo) {
                if (!this.addProviderModal.providerId && data) {
                  this.openCreateModal();
                } else {
                  if (this.addProviderModal.providerId) {
                    await this.updateProviderData();
                    this.nextTab();
                  } else {
                    this.nextTab();
                  }
                }
              }
            });
        break;

        case 3:
          if (this.$refs['companyAddresses'].$refs['manageAddress'] !== undefined) {
            await this.$refs['companyAddresses'].$refs['manageAddress'].addAddress()
          }else {
            this.nextTab();
          }
        break; 

        case 4:
          await this.$refs['contactInfoForClientsApps'].updateContactData();
          this.nextTab();
        break; 

        case 6:     
          if (!this.onlyContinue) {
            if(this.$refs['balanceCredit'].$refs['balanceManage'] !== undefined) {
              await this.$refs['balanceCredit'].$refs['balanceManage'].save()
            }
          }else {
            this.nextTab();
          }    
        break;

        case 7:
          if (this.$refs['paymentMethods'].$refs['managePayments'] !== undefined) {
            this.$refs['paymentMethods'].$refs['managePayments'].$refs['CreditCard'] !== undefined ?
              await this.$refs['paymentMethods'].$refs['managePayments'].$refs['CreditCard'].manageCreditCard()
              : ''

            this.$refs['paymentMethods'].$refs['managePayments'].$refs['BankAccount'] !== undefined ?
              await this.$refs['paymentMethods'].$refs['managePayments'].$refs['BankAccount'].manageCreditCard()
              : ''
          } else {
            this.nextTab();
          }
        break;

        case 8:
          if (this.$refs['invoiceGeneration'].$refs['invoiceSettings'] !== undefined) {
            await this.$refs['invoiceGeneration'].$refs['invoiceSettings'].saveSettings();
          } else {
            await this.$refs['invoiceGeneration'].$refs['invoiceDesign'].$refs['invoiceDesignManage'].saveSettings().then(() => {
              if (!this.$refs['invoiceGeneration'].$refs['invoiceDesign'].$refs['invoiceDesignManage'].$v.designData.$error) {
                this.nextTab();
              }

            });
          }
        break;

        case 9:
          if (this.$refs['labelPrinters'].$refs['manageLabelPrinters'] !== undefined) {
            await this.$refs['labelPrinters'].$refs['manageLabelPrinters'].addLabelPrinter()
          }else {
            this.nextTab();
          }
        break;

        case 10:
          await this.$refs['saleTexes'].saveServieTaxes()
          if (this.$refs['saleTexes'].$refs['manageCountryTax'] !== undefined) {
            await this.$refs['saleTexes'].$refs['manageCountryTax'].addTaxData();
          } else {
            this.nextTab();
          }
          break; 

        case 11:
          await this.$refs['paymentGateway'].updateData();
          this.nextTab();
          break

        case 12:
          this.$refs['shippingProviders'].updateData();
          this.nextTab();
        break; 
        
        case 15:
          await this.$refs['uiAndAccess'].updateData();
          this.nextTab();
        break;

        default: {
          this.nextTab();
        }
      }
      if (this.activeTab > 15) {
        this.closeProviderModal();
      }
    },

    closeModal(e) {
      this.resetDataModal();
    },

    resetDataModal() {
      this.companyInfoResetState();
      this.resetDataCompanyInfo();
      this.resetActiveTabs();
      this.resetContactForm();
      this.adminPersonalInfoReset();
      this.companyAddressesReset();
      this.paymentMethodsReset();
      this.balanceCreditReset();
      this.merchantReset();
      this.shipmentProvidersReset();
      this.uiAndAccessReset();
      this.contactInfoForClientsReset();
      this.saleTaxesResetData();
      this.otherApiIntegrationReset();
      this.labelPrintersReset();
      this.permissionsReset();
      this.saleTaxesResetDataClose();
      this.invoiceReset();
      this.closeProviderModal();
      this.closeCreateModal();
      this.setData({ parent: null })
      this.setIsAddReseller(false)
    },

    changeTab(value) {
      if (
        this.addProviderModal.disableTabs ||
        this.addProviderModal.disableTabs === 0
      ) {
        if (value <= this.addProviderModal.disableTabs) {
          this.clickActiveTab(value);
        }
      } else {
        this.clickActiveTab(value);
      }
    },

    async clickList(index) {
      // If is edit mode, before channg tab getting all selected provider data then change 
      if (this.addProviderModal.providerId) {
        await this.getProvider().then(async (data) => {
          if (data) {
            this.updateData(data);
            if (index === 3 || index === 4 || index === 8 || index === 10 || index === 13) {
              await this.getCompanyAddresses(this.addProviderModal.providerId)
                .then((addressData) => {
                  this.updateAddressList(addressData);
                });
            }
          }

          if (data?.parent) {
            this.setData({ parentId: data?.parent?._id })
          }
        })
        this.changeTab(index);

      } else {
        switch (this.activeTab) {
          case 0: {
            if (
              index <= this.addProviderModal.disableTabs && 
              this.$refs["companyInfo"] &&
              !this.$refs["companyInfo"].validationForm()
            ) {
              this.changeTab(index);
            }
            break;
          }
          case 1: {
            if (!this.onlyContinue) {
              this.$refs["contactSection"].$refs["contactForm"].save(false)
              this.$refs["contactSection"].$refs["contactForm"].$refs["phoneNumberForm"].showPhoneError = true
            } else {
              if (this.emailsList.length && this.phoneList.length) {
                this.changeTab(index);
              }
            }
            break;
          }
          case 2: {
            if (index <= this.addProviderModal.disableTabs) {
              this.$refs["companyPersonalInfo"] &&
              await this.$refs["companyPersonalInfo"].validationForm().then((data) => {
                if (this.isValidatePersonalInfo) {            
                  this.changeTab(index);
                }
              })
            }
  
            break;
          }
          default: {
            if (index <= this.addProviderModal.disableTabs) {
              this.changeTab(index);
            }
          }
        }
      }

      this.isEditEmail(false)
      this.isEditPhone(false)

      if (this.addressesList && this.addressesList.length) {
        this.setCompanyAddressIsEdit(false)
        this.setCompanyAddressIsAdd(false)
      }

      this.setPaymentMethodIsAdd(false)
      this.setPaymentMethodIsEdit(false)
    },

    async saveAndClose() {
      await this.updateProviderData();
      this.closeModal();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

.P-status-title {
  color: $neutral-color;
  font-size: $txt12;
  font-weight: $semiBold;
  margin-bottom: 10px;
  display: block !important;
}

.status {
  padding: 15px;
  border-radius: $border-radius;
  background-color: $white;

  .text {
    display: flex;
    flex-direction: column;

    span {
      position: relative;
      padding-left: 20px;
      font-size: $txt12;
      font-weight: $semiBold;
      color: $secondary-color;
      display: block;

      &:before {
        position: absolute;
        content: "";
        width: 9px;
        height: 9px;
        background-color: $green;
        border-radius: 50%;
        left: 0;
        top: 50%;
        border: 1px solid $green;
        transform: translateY(-50%);
      }

      &:last-child {
        &:before {
          background-color: $white;
        }
      }
    }
  }
}

.parent-add-provider-modal {
  display: flex;

  .left {
    min-width: 226px;
    padding: 13px 13px 26px 13px;

    ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    & .list {
      padding: 5px 10px 10px 0;
      margin: 10px -15px 20px 0;
      overflow-y: scroll;
      height: 672px;
      text-transform: capitalize;

      li + li {
        margin-top: 3px;
      }

      & > li {
        padding: 7px 0 7px 11px;
        cursor: pointer;
        border-radius: 4px;
        font: normal normal 600 14px Segoe UI;
        white-space: nowrap;
        height: 33px;
        transition: $transition;

        &.P-disable-tab {
          color: $neutral-color;
          cursor: default;
        }

        &:hover:not(.P-disable-tab) {
          background-color: $neutral-color25;
          color: $secondary-color;
        }
      }
    }
  }

  .right {
    padding: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & .footer {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #a5aac6;
      margin: 0 -24px;
      padding: 20px 24px 0;

      & > :nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}

.theme--dark {
  .status {
    background-color: #555759;
  }

  .P-status-title,
  span {
    color: $white !important;
  }

  .status .text span:last-child:before {
    background-color: $darkLink;
  }
}

.P-cancel::v-deep {
  button {
    width: 104px;
  }
}
</style>
