<template>
  <div class="parent-info">
    <h3 class="secondary--text">Company Information</h3>
    <div class="P-input-block">
      <div class="P-input-sections G-flex">
        <div class="P-padding-50">
          <ott-input
            v-model="companyName"
            :error="$v.companyInfo.companyName.$error"
            label="Company Name*"
          />
        </div>
        <div class="P-padding-50">
          <ott-input
            v-model="website"
            label="Website"
          />
        </div>
      </div>
      <div class="P-input-sections G-flex-wrap">
        <div class="P-padding-33">
          <ott-select-autocomplete
            v-model="country"
            :error="$v.companyInfo.country.$error"
            :items="countryList"
            :height="38"
            :clear="true"
            outlined
            selected-text="name"
            label="Country*"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="numberOfCustomers"
            :items="numberOfCustomersList"
            :error="$v.companyInfo.numberOfCustomers.$error"
            selected-text="name"
            selected-value="value"
            label="Amount Of Clients*"
          />
        </div>
        <div class="P-padding-33">
          <ott-input
            v-model="numberOfTvChannel"
            :error="
              channelsValidation ||
              $v.companyInfo.numberOfTvChannel.$error
            "
            @emitFunc="validationNumber"
            type="number"
            label="Amount Of TV Channels*"
          />
        </div>
        <div class="P-padding-33">
          <ott-select
            v-model="priceGroup"
            :items="priceGroupAdmin.providersPriceGroups"
            :isTranslate="true"
            :clear="true"
            label="Price Group"
            selected-text="name"
          />
          <!-- :error="$v.companyInfo.priceGroup.$error" -->
        </div>
        <div class="P-padding-33">
          <ott-select-autocomplete
            v-if="!providerId || authProvider.type === 0"
            v-model="timezone"
            :error="$v.companyInfo.timezone.$error"
            :items="timeZoneList"
            :clear="true"
            :multiple="false"
            :persistentHint="true"
            label="Timezone*"
            selected-text="name"
          />

          <ott-input
            v-else
            :value="timezone"
            :disabled="true"
            label="Timezone*"
          />
        </div>
      </div>
      <ott-textarea
        v-model="brieflyDescribeProject"
        :rows="3"
        label="Briefly Describe Project"
        class="describe-textarea"
      />
      
      <quill-editor 
        v-model="comment" 
        :options="{ placeholder: 'Comment' }"
        :class="$vuetify.theme.dark ? 'dark-ql-editor' : 'light-ql-editor'"
        class="mb-6 editor"
      />
    </div>
    <div class="G-flex">
      <ott-button-loader
        v-if="isEditProvider"
        button-text="Save"
        class-name="primary-sm-btn"
        @onClick="updateProviderFunc"
        :is-loading="isLoading"
      />
    </div>
  </div>
</template>

<script>
import { helpers, required } from "vuelidate/lib/validators";
import { mapActions, mapMutations, mapState } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttTextarea from "@/components/vuetifyComponents/OttTextarea";
import OttButton from "@/components/vuetifyComponents/OttButton";
import allCountry from "@/constants/all-countries";
import timeZoneList from "@/constants/time-zone-list";
import OttSelectAutocomplete from "@/components/vuetifyComponents/OttSelectAutocomplete";
import OttButtonLoader from "@/components/vuetifyComponents/OttButtonLoader";

const website = helpers.regex("website", /^(http|https):\/\//);
export default {
  name: "CompanyInfo",
  components: {
    OttSelectAutocomplete,
    OttButton,
    OttTextarea,
    OttSelect,
    OttInput,
    OttButtonLoader,
  },
  props: {
    updateProvider: {
      type: Function,
      default: () => null,
    },
  },
  validations: {
    companyInfo: {
      companyName: { required },
      numberOfCustomers: { required },
      country: { required },
      numberOfTvChannel: { required },
      timezone: { required },
    },
  },
  data() {
    return {
      // priceGroup: [
      //   {
      //     name: "Price Group 1",
      //     value: 1,
      //   },
      //   {
      //     name: "Price Group 2",
      //     value: 2,
      //   },
      //   {
      //     name: "Price Group 3",
      //     value: 3,
      //   },
      // ],
      numberOfCustomersList: [
        { name: "0 - Startup", value: 0 },
        { name: "1 - 10K", value: 1 },
        { name: "10K - 20K", value: 2 },
        { name: "20K - 50K", value: 3 },
        { name: "50K - 100K", value: 4 },
        { name: "100K+", value: 5 },
      ],
      countryList: allCountry,
      timeZoneList: timeZoneList,
      channelsValidation: false,
    };
  },
  computed: {
    ...mapState({
      companyInfo: (state) => state.companyInfo.companyInfo,
      isLoading: (state) => state.appModule.isLoading,
      priceGroupAdmin: (state) => state.priceGroupAdmin,
      isEditProvider: (state) => state.providers.isEditProvider,
      providerId: (state) => state.addProviderModal.providerId,
      authProvider: (state) => state.auth.user.provider,
      parentId: (state) => state.addProviderModal.parentId
    }),

    companyName: {
      set(companyName) {
        this.setCompanyInfoKey({ companyName })
      },
      get() {
        return this.companyInfo.companyName
      }
    },

    website: {
      set(website) {
        this.setCompanyInfoKey({ website })
      },
      get() {
        return this.companyInfo.website
      }
    },

    country: {
      set(country) {
        this.setCompanyInfoKey({ country })
      },
      get() {
        return this.companyInfo.country
      }
    },

    numberOfCustomers: {
      set(numberOfCustomers) {
        this.setCompanyInfoKey({ numberOfCustomers })
      },
      get() {
        return this.companyInfo.numberOfCustomers
      }
    },

    numberOfTvChannel: {
      set(numberOfTvChannel) {
        this.setCompanyInfoKey({ numberOfTvChannel })
      },
      get() {
        return this.companyInfo.numberOfTvChannel
      }
    },

    priceGroup: {
      set(priceGroup) {
        this.setCompanyInfoKey({ priceGroup })
      },
      get() {
        return this.companyInfo.priceGroup
      }
    },

    timezone: {
      set(timezone) {
        this.setCompanyInfoKey({ timezone })
      },
      get() {
        return this.companyInfo.timezone
      }
    },

    brieflyDescribeProject: {
      set(brieflyDescribeProject) {
        this.setCompanyInfoKey({ brieflyDescribeProject })
      },
      get() {
        return this.companyInfo.brieflyDescribeProject
      }
    },

    comment: {
      set(comment) {
        this.setCompanyInfoKey({ comment })
      },
      get() {
        return this.companyInfo.comment
      }
    },

  },
  async mounted() {
    await this.getPriceGroupList();
  },
  methods: {
    ...mapActions({
      getPriceGroupByTypeList: "priceGroupAdmin/getPriceGroupByTypeList",
    }),
    ...mapMutations({
      generateAllPriceGroups: "priceGroupAdmin/generateAllPriceGroups",
      setDataCompanyInfo: "companyInfo/setDataCompanyInfo",
      setProvidersPriceGroups: 'priceGroupAdmin/setProvidersPriceGroups',
      setCompanyInfoKey: "companyInfo/setCompanyInfoKey",
    }),

    async updateProviderFunc() {
      if (!this.validationForm()) {
        await this.updateProvider();
      }
    },

    /**
     * Get price group list
     * **/

    async getPriceGroupList() {
      await this.getPriceGroupByTypeList({ type: 2 }).then(data => {
        this.setProvidersPriceGroups(data)
      })
    },
    validationForm() {
      this.$v.companyInfo.$touch();
      return this.$v.companyInfo.$error;
    },
    validationNumber() {
      this.channelsValidation =
        +this.numberOfTvChannel < 0 ||
        !this.numberOfTvChannel;
      // this.updateData();
    },
    // updateData() {
    //   this.setDataCompanyInfo(this.companyInfo);
    // },
  },
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
@import "./src/assets/scss/variables";

/*.country-field {*/
/*  width: 200px;*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/
.parent-info {
  display: flex;
  flex-direction: column;

  & > h3 {
    color: $secondary-color;
    font-size: $txt16;
    font-weight: $semiBold;
    margin-bottom: 20px;
  }

  .P-input-sections {
    margin: 0 -10px;
  }
  .P-input-sections::v-deep {
    .v-text-field__details {
      margin-bottom: 0;
    }
  }

  .describe-textarea {
    padding: 10px 0;
  }
  .editor {
    padding-top: 10px;
  }
}
</style>
