<template>
  <div class="P-modal-components">
    <div class="P-modal-component-header G-justify-between">
      <h3 class="secondary--text">Company addresses</h3>
      <div
        class="icon-cont"
        @click="addNewAddress"
        v-if="!isAddMode && addressesList.length"
      >
        <span class="mdi mdi-plus neutral--text" />
      </div>
    </div>

    <div class="P-modal-component-body">
      <ManageAddress ref="manageAddress" v-if="isAddMode || !addressesList.length" />
      <AddressList v-else />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import ManageAddress from "./ManageAddress";
import AddressList from "./AddressList";

export default {
  name: "CompanyAddresses",
  components: { AddressList, ManageAddress },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      addressesList: state => state.companyAddresses.addressesList,
      isAddMode: state => state.companyAddresses.isAddMode,
      isEditMode: state => state.companyAddresses.isEditMode,
      addProviderModal: state => state.addProviderModal

    })
  },

  async mounted() {
    if( this.addProviderModal.providerId){
      await this.getCompanyAddresses(this.addProviderModal.providerId).then(data => {
        this.updateAddressList(data)
      })
    }  
    
    this.showSaveBtnFunc()
  },

  updated() {    
    this.showSaveBtnFunc()
  },

  methods: {
    ...mapActions({
      getCompanyAddresses: 'companyAddresses/getCompanyAddresses'
    }),

    ...mapMutations({
      updateAddressList: 'companyAddresses/updateAddressList',
      openAddNewAddress: 'companyAddresses/openAddNewAddress'
    }),

    addNewAddress() {
      this.openAddNewAddress();
    },

    showSaveBtnFunc() {
      if (this.addressesList.length && !this.isAddMode ) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true, onlyContinue: true })    
      }else if(!this.addressesList.length) {
        this.$emit('showSaveBtnFunc', { showSaveButton: true })    
      }else {
        this.$emit('showSaveBtnFunc', { showSaveButton: false })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables";
.P-modal-component-header {
  margin-bottom: 10px;

  h3 {
    font-size: $txt16;
    font-weight: $semiBold;
    text-transform: capitalize;
  }
}
</style>
